import { ThrowStmt } from '@angular/compiler';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { filter } from 'rxjs/operators';
import { CbtserviceService } from '../services/cbtservice.service';
import { ExamService } from '../services/exam.service';
import { ProctoringService } from '../services/proctoring.service';
import { QuestionserviceService } from '../services/questionservice.service';

@Component({
  selector: 'app-questionlist',
  templateUrl: './questionlist.component.html',
  styleUrls: ['./questionlist.component.css']
})
export class QuestionlistComponent implements OnInit {
  scheduleid: any;
  questions: any;
  previousurl
  loading: boolean = false;
  onblurfunction;
  onfocusfunction;
  countdown: any;
  lossactionmsg: string = "";
  losscount: number = 0;
  focuslosswarningmessage: string = ""
  lostfocus: boolean = false
  examination: any;
  constructor(public toastr: ToastrService, public activaterouter: ActivatedRoute, public router: Router, public examservice: ExamService, public cbtservice: CbtserviceService, private proctorservice: ProctoringService,public questionservice:QuestionserviceService) { }

  async ngOnInit() {
    this.loading = true;
    this.scheduleid = this.activaterouter.snapshot.queryParams.scheduleid;
    this.previousurl = this.cbtservice.getPreviousUrl();
    this.questions = await this.examservice.getexamquestionlist(this.scheduleid).catch(err => { this.cbtservice.showHttpError(err); this.loading = false });
    // console.log(JSON.stringify(this.questions));
    this.examination = await this.examservice.getexamwithscheduleid(this.scheduleid).catch(err => { this.cbtservice.showHttpError(err); this.loading = false });

    if (this.examination.checkfocusloss && !this.onblurfunction/* && admintestlogin != "True"*/) {
      this.onblurfunction = this.onBlur.bind(this);//have to do it this way because if i just set the event listener to this.setTileSize, the "this" becomes window
      this.onfocusfunction = this.onFocus.bind(this);
      window.addEventListener('focus', this.onfocusfunction);
      window.addEventListener('blur', this.onblurfunction);
    }
    this.loading = false
  }
  ngOnDestroy(): void {
    if (this.onblurfunction) {
      window.removeEventListener('focus', this.onfocusfunction);
      window.removeEventListener('blur', this.onblurfunction);
    }
  }
  goBack() {
    this.router.navigateByUrl(this.cbtservice.getPreviousUrl());
  }
  onFocus() {
    clearTimeout(this.countdown);
    console.log('OnPageVisibilityChange => visible');
    if (this.lostfocus && this.focuslosswarningmessage != '') {
      this.toastr.warning(this.focuslosswarningmessage);
    }
  };
  onBlur() {
    if (this.examination.checkfocusloss && !this.proctorservice.chatopened) {
      let audio = new Audio();
      audio.src = "/assets/beep.mp3";
      audio.loop = false;
      audio.load();
      audio.play();
      console.log("audio played");
      const mythis = this;
      this.countdown = setTimeout(() => {
        //debugger;
        if (!mythis.proctorservice.chatopened) {
          this.setres();
        }
        // if ((this.examination.nooffocusloss - this.losscount) === 1) {
        //   audio.play();
        //   console.log("audio played")
        // }
      }, 1000 * this.examination.durationofloss);
    }
  } 
  
  async setres() {
    var data: any = await this.examservice.setfocuslosscounter(this.scheduleid).catch(err => this.cbtservice.showHttpError(err));
    this.losscount = data.flcount;
    clearTimeout(this.countdown);
    if (this.losscount >= this.examination.nooffocusloss) {
      var data2: any = await this.examservice.performfocuslossaction(this.scheduleid).catch(err => this.cbtservice.showHttpError(err));

      if (data2.msg == "ReduceTIme" && this.examination.focuspenalty == "ReduceTIme") {
        this.toastr.warning(`You have lost ${this.examination.reducetimelossduration} minutes as the penalty for leaving the exam page`);
      }
      if (/*data2.msg &&*/ data2.msg != "ReduceTIme") {
        // window.location.href = `${this.thisurl}exam/submit?scheduleid=${this.scheduleid}`;
        this.router.navigate(['/exam/submit'], { queryParams: { scheduleid: this.scheduleid } });
      }
    }
    else {
      this.focuslosswarningmessage = "You are not allowed to leave the exam page. Doing so results in being penalized"
      this.lostfocus = true
      this.toastr.warning(this.focuslosswarningmessage);

    }
  }
  gotoquestion(scheduleid,questionno)
  {
    this.questionservice.previousquestiondata = null;
    this.questionservice.nextquestiondata = null;
    this.router.navigate(["/exam/question"], { queryParams: { scheduleid: scheduleid, questionno: questionno } });
  }
}

