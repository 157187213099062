<div>
  <div *ngIf="!fromexternalauth" class="row justify-content-center login-container">
    <div class="col-sm-12 col-md-12 col-lg-6 login-form-left">
      <h3><a routerLink="/access/login">Web Test</a></h3>
      <div class="login-pic-wrap">
        <!-- <img src="/assets/images/login-pic.png" width="300" alt="login pic" /> -->
        <img *ngIf="this.util.getSubdomain() && logourl" [src]="logourl" width="300" alt="login pic" />
      </div>
    </div>
    <div class="col-sm-12 col-md-12 col-lg-6 login-form-right">
      <!-- <p class="about-btn">
      <a href="https://webtest.ng" target="_blank">about us</a>
    </p> -->
      <div class="card bg-secondary col-sm-10 col-sm-offset-1 mx-auto">
        <div class="card-body px-lg-3 py-lg-3">
          <div class="alert center" style="padding: 0.75em 0; text-align: center;">
            <p class="font-weight-bold" *ngIf="isLoading" style="color:white; background-color: cornflowerblue;">
              <span><img style="height:40px;" src="/assets/img/spinner.gif" /></span>
            </p>
          </div>
          <!-- <div class="login-mobile-logo">
          <h3>Web Test</h3>
        </div> -->
          <div class="welcome-users" style="max-height: 300px !important;">
            <div class="unique-company-logo">
              <img *ngIf="this.util.getSubdomain() && logourl" [src]="logourl" width="300" style="max-width: 100%;max-height: 90% !important;"
                alt="login pic" />
            </div>
            <p *ngIf="!externaltoken">Login</p>
          </div>
          <form #login="ngForm" name="login" (ngSubmit)="loginclicked()" style="border: 0px !important;"
            class="login-form">
            <!-- <div class="form-group mb-3">
          <div class="input-group input-group-merge input-group-alternative">
            <label for="example-text-input"
              class="col-md-3 col-form-label form-control-label">Text</label>
            <div class="input-group-prepend">
              <span class="input-group-text"><i class="ni ni-single-02"></i></span>
            </div>
            <input class="form-control" placeholder="Username" type="text" name="user"
              [(ngModel)]="username">
          </div>
        </div>
        <div class="form-group row">
          <label for="example-text-input"
            class="col-md-3 col-form-label form-control-label">Username</label>
          <div class="col-md-9">
            <input class="form-control" placeholder="Username" type="text" name="user"
              [(ngModel)]="username">
          </div>
        </div> -->
            <div *ngIf="!externaltoken">
              <div class="form-group">
                <label class="form-control-label" for="user">Username</label>
                <input class="form-control" placeholder="Username" required type="text" name="user"
                  [(ngModel)]="username">
              </div>
              <div class="form-group">
                <label class="form-control-label" for="password">Password</label>
                <input class="form-control" placeholder="**********" type="password" required name="password"
                  [(ngModel)]="password">
              </div>
              <!-- <div class="form-group">
            <div class="input-group input-group-merge input-group-alternative">
              <div class="input-group-prepend">
                <span class="input-group-text"><i class="ni ni-lock-circle-open"></i></span>
              </div>
              <input class="form-control" placeholder="Password" type="password" name="password"
                [(ngModel)]="password">
            </div>
          </div> -->
              <div class="text-center">
                <button type="submit" class="btn btn-primary my-4 form-control"
                  [disabled]="login.invalid || isLoading">Login</button>
                <!-- <button class="btn btn-primary my-4" routerLink="/exam/question">Login</button>
            <a routerLink="/exam/question"><input type="submit" value="Login"></a> -->

              </div>
            </div>
            <!-- <div *ngIf="externaltoken">
              <button type="submit" class="btn btn-primary my-4 form-control" [disabled]="isLoading">Enter</button>
            </div> -->
          </form>
        </div>
      </div>

      <!-- <p class="web-test-btn">
      <a href="https://webtest.ng" class="nav-link" target="_blank">web test</a>
    </p> -->
    </div>
  </div>
  <div *ngIf="fromexternalauth" class="logged-out-message">
    You are logged out
  </div>
</div>
<!-- <div class="row">
  <div class="col-sm-12">
    <div class="copyright-text">
      <p>© 2021 webTest All rights reserved.</p>
    </div>
  </div>
</div> -->