import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProctoringService } from '../services/proctoring.service';
import { QuestionserviceService } from '../services/questionservice.service';
import { UserauthenticationserviceService } from '../services/userauthenticationservice.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.css']
})
export class LogoutComponent implements OnInit {

  constructor(public userauth:UserauthenticationserviceService, public router:Router, private proctorservice: ProctoringService, public activatedroute:ActivatedRoute, public questionservice:QuestionserviceService) { }

  async ngOnInit() {
    const seb = this.userauth.getSEBKey();
    this.questionservice.previousquestiondata = null;
    this.questionservice.nextquestiondata = null;
    this.proctorservice.stopProctor('User Examination Page Init');
    var unauthorized =this.activatedroute.snapshot.queryParams.unauthorized;
    const isexternalauth = this.userauth.getIsExternalAuth();
    let user = null;
    try{
      user = await this.userauth.getCurrentUser();
    }
    catch(e: any){
      console.error(e);
    }
    //debugger;
    const isexternalauthbool = isexternalauth == 'true' ? true : false;
    if(seb){
      await this.proctorservice.quitSEB(user?.username).finally(()=>{
        window.location.href = environment.quitseburl;
      });
      
    }
    this.userauth.logout(unauthorized, isexternalauthbool);
  }

}
