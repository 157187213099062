import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { Util } from './util';

@Injectable()
export class SubdomainInterceptor implements HttpInterceptor {

  constructor(public util:Util) {}
  intercept(request : HttpRequest<any>,next:HttpHandler) : Observable<HttpEvent<any>>{   
    const subdomain = this.util.getSubdomain();
    request = request.clone({
        setHeaders : {
            subdomain: subdomain
        }            
      });      
  return next.handle(request);
 
}
}
