import { DatePipe } from '@angular/common';
import { Component, Input, OnInit, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';

import { ToastrService } from 'ngx-toastr';
import { throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { TimeDisplay } from '../model';
import { Exam, Instructions } from '../models/Exam';
import { CbtserviceService } from '../services/cbtservice.service';
import { ExamService } from '../services/exam.service';
import { ProctoringService } from '../services/proctoring.service';
import { QuestionserviceService } from '../services/questionservice.service';
import { UserauthenticationserviceService } from '../services/userauthenticationservice.service';
import { MeetingService } from '../meeting/services/meeting.service';
import { CurrentUser } from '../models/CurrentUser';

declare var $: any

@Component({
  selector: 'app-userexamination',
  templateUrl: './userexamination.component.html',
  styleUrls: ['./userexamination.component.css']
})
export class UserexaminationComponent implements OnInit {
  @Input() exam: Exam
  readonly pending = "Pending";
  readonly inProgress = "InProgress";
  readonly finished = "Finished";
  readonly timeElapsed = "TimeElapsed";
  readonly stopped = "Stopped";
  readonly disqualified = "Disqualified";
  user: CurrentUser = new CurrentUser();
  isloading: boolean = false;
  instructions: Instructions = new Instructions();
  webtesthtml: SafeHtml = '';
  companyhtml: SafeHtml = '';
  examhtml: SafeHtml = '';
  confirmed: boolean;
  showerror: boolean;
  minDate = '';
  maxDate = '';
  isDate = false;
  isTime = false;
  availabeTimes = Array<TimeDisplay>();
  CavailabeTimes = [];
  availabeDates = [];
  scheduledDate = '';
  scheduledPosition: number;
  startList = Array<Date>();
  endList = Array<Date>();
  showproctorerror: boolean;
  usedropdown: boolean;
  @Output() destroyInterval = new EventEmitter<boolean>();
  @Output() resumeInterval = new EventEmitter<any>();
  runningInSEB = window.navigator.userAgent.includes('SEB');
  //userPromise: any;
  constructor(public questionservice: QuestionserviceService, public toastr: ToastrService, public router: Router, public examservice: ExamService,
    private userauth: UserauthenticationserviceService, private proctorservice: ProctoringService, private cbtservice: CbtserviceService, public sanitizer: DomSanitizer,
    private datePipe: DatePipe, private meetingservice: MeetingService, private activatedroute: ActivatedRoute) { }

  async ngOnInit() {
    //debugger

    this.user = await this.userauth.getCurrentUser();
    if (!this.user) {
      this.toastr.error('User session not found');
      this.router.navigate(['/access/login']);
      return;
    }
    // console.log(this.user);


  }

  get localexamdate() {
    const ret = new Date(this.exam.examdate);
    ret.setMinutes(ret.getMinutes() - new Date().getTimezoneOffset());
    return ret;
  }

  resumeinterval() {
    this.resumeInterval.emit(true);
  }

  async getInstructions() {
    this.destroyInterval.emit(true);

    this.isloading = true;

    try {
      this.instructions = await this.examservice.getInstructions(this.exam.examid);
      this.isloading = false;
      if (!this.instructions.webtestHTML && !this.instructions.companyHTML && !this.instructions.examHTML) {
        this.gotoexam(true);
      }
      else {
        $(`#imodal${this.exam.examid}`).modal('show');
        if (this.instructions.webtestHTML) {
          this.webtesthtml = this.sanitizer.bypassSecurityTrustHtml(this.instructions.webtestHTML);
        }
        if (this.instructions.companyHTML) {
          this.companyhtml = this.sanitizer.bypassSecurityTrustHtml(this.instructions.companyHTML);
        }
        if (this.instructions.examHTML) {
          this.examhtml = this.sanitizer.bypassSecurityTrustHtml(this.instructions.examHTML);
        }
      }
    }
    catch (error) {
      console.log('error', error);

      this.cbtservice.showHttpError(error);
      this.isloading = false;
      throw error;
    }
  }
  confirmationchecked(e) {
    if (e.target.checked) {
      this.confirmed = true;
    }
    else {
      this.confirmed = false;
    }
  }
  getHTMLIDRef(name: string, id: any) {
    return `#collapse${name}${id}`;
  }
  getHTMLIDParent(id: any) {
    return `#instructionaccordion${id}`;
  }
  getHTMLIDAria(name: string, id: any) {
    return `#${name}${id}`;
  }
  async gotoexam(start: boolean) {

    if (this.exam.canstart) {
      try {
        //debugger;

        this.destroyInterval.emit(true);
        this.isloading = true;
        //this.proctorservice.stopProctor();
        if (this.exam.examtype == "Interview") {
          var meetingHandler: any = await this.examservice.getInterviewInfo(this.user.username, this.user.fullname, this.exam.interviewaccesscode);
          let test = this.activatedroute.snapshot.queryParams.test == 'true';
          debugger;
          this.router.navigate([`/exam/join/${meetingHandler.meetingId}`], { queryParams: { auth: meetingHandler.meetingJwt, test: test } });

        }
        else {
          if (this.exam.useproctor) {

            await this.proctorservice.startProctor(this.user.username, this.user.fullname, this.exam.examid, this.exam.name, this.exam.id, this.exam.attempt, this.user.imageversion, this.user.imagetype, this.exam.skipAIPassportCheck);


            //var count = await this.questionservice.getquestioncount(this.exam.id).toPromise().catch(err => this.toastr.error(err)).finally(()=>this.isloading=false);
          }
          if ((this.exam.requireadminstart && this.exam.examstartedbyadmin) || !this.exam.requireadminstart) {

            await this.questionservice.generatequestions(this.exam.id);//.catch(err => { console.error(err); this.toastr.error(err); this.isloading = false });
            //this might timeout (gateway timeout) and there is currently no way to know due to cors header not being set by api gateway if timeout.


            //if the first gateway timed out (error 504), the request should still complete on the server, as such, the following request should still work as the endpoing locks the schedule record, which will force the request to wait until the generate questions request has completed
            await this.questionservice.startexam(this.exam.id);
            //if (this.exam.liteMode) {
            var questions: any[] = await this.questionservice.getallquestionandoptions(this.exam.id, this.exam.liteMode);
            this.questionservice.saveQuestionsToStorage(questions);
            this.questionservice.saveTimeForLiteMode();
            // } else {
            //     this.questionservice.clearQuestionStorage();
            // }
            if (start) {
              //console.log("question count is: " + count);
              this.router.navigate(['/exam/question'], { queryParams: { scheduleid: this.exam.id, questionno: 1 } });
            } else {
              var request = await this.examservice.getlastansweredqno(this.exam.id, this.exam.examtype == "Essay").catch(err => { this.cbtservice.showHttpError(err); this.isloading = false });
              var no = request.qno;
              this.router.navigate(['/exam/question'], { queryParams: { scheduleid: this.exam.id, questionno: no } });
            }
          }
          else if (this.exam.requireadminstart && !this.exam.examstartedbyadmin) {
            this.router.navigate(['/exam/question'], { queryParams: { scheduleid: this.exam.id, questionno: 1 } });
          }
        }
      }
      catch (error) {
        if (this.exam.useproctor) {
          try {
            await this.proctorservice.stopProctor('Go to exam error');
          } catch (error2) {
            console.error(error2);
          }
        }
        this.cbtservice.showHttpError(error);
        throw error;
      }
      finally {
        this.isloading = false;
      }
    }

  }

  toggleerror() {
    this.showerror = !this.showerror;
  }

  async showExamScheduleModal() {
    this.destroyInterval.emit(true);
    this.isloading = true;
    try {
      let response = await this.examservice.getuserscheduleDates(this.exam.examid);
      console.log(response);
      this.isloading = false;
      this.minDate = response.minDate;
      this.maxDate = response.maxDate;
      this.availabeDates = response.dateList;
      this.usedropdown = response.useDropDown;
      if (this.availabeDates.length != 0) {
        this.isDate = true;
      }
      $('#scheduleModal').modal('show');

    } catch (error) {
      console.log('error', error);

      this.cbtservice.showHttpError(error);
      this.isloading = false;
      throw error;
    }
  }

  async onDateChange(event: any) {
    this.availabeTimes = [];

    let d = event.target.value
    //console.log(d);
    if (this.availabeDates.find(x => x == d)) {
      this.isloading = true;

      this.scheduledDate = d;
      try {
        let response = await this.examservice.getuserscheduleTimes(this.exam.examid, this.scheduledDate);

        // this.availabeTimes = response.stimeList;
        this.startList = response.stimeList;
        this.endList = response.etimeList
        this.startList.forEach((value, index) => {

          //geting local time for start time
          const sDate = this.GetLocalTime(value);
          //geting local time for end time
          const eDate = this.GetLocalTime(this.endList[index]);
          console.log(sDate.getHours() + ":" + sDate.getMinutes() + ":" + sDate.getSeconds());


          let formatedDate: TimeDisplay = {
            position: index,
            displaytime: this.datePipe.transform(sDate, "mediumTime") + " - " + this.datePipe.transform(eDate, "mediumTime")
          };
          // console.log("start time "+);
          this.availabeTimes.push(formatedDate);
        });
        if (this.availabeTimes.length != 0) {
          this.isTime = true;
        }
      } catch (error) {
        let errorResponse = error;
        console.log(errorResponse);
        this.isloading = false;
        this.minDate = errorResponse.minDate;
        this.maxDate = errorResponse.maxDate;
        this.availabeDates = errorResponse.dateList;
        throw error;
      } finally {
        this.isloading = false;
      }

    } else {
      this.toastr.warning('Unavailable Date Selected');
      event.target.value = '';
      this.availabeTimes = [];

      return;
    }


  }

  onTimeChange(event: any) {

    let t = event.target.value
    console.log(t);
    this.scheduledPosition = t;
    console.log("Start: " + this.GetLocalTime(this.startList[this.scheduledPosition]));
    console.log("End: " + this.GetLocalTime(this.endList[this.scheduledPosition]));
  }

  async onScheduleClicked() {
    try {
      this.isloading = true;

      let data = {
        candiateno: this.user.username,
        date: this.datePipe.transform(this.GetLocalTime(this.startList[this.scheduledPosition]), "MM/dd/yyyy"),
        stime: "" + this.datePipe.transform(this.GetLocalTime(this.startList[this.scheduledPosition]), "mediumTime"),
        etime: "" + this.datePipe.transform(this.GetLocalTime(this.endList[this.scheduledPosition]), "mediumTime"),
        examid: this.exam.examid,
        examname: this.exam.name,
        fullname: this.user.fullname,
      }
      this.exam = await this.examservice.schedulecandidate(data);
      this.toastr.success("Exam Scheduled. Please Check your mail");
      this.availabeTimes = [];

    } catch (error) {
      console.log('error', error);

      this.cbtservice.showHttpError(error);
      let errorResponse = error.error;
      console.log(errorResponse);
      this.isloading = false;
      this.minDate = errorResponse.minDate;
      this.maxDate = errorResponse.maxDate;
      this.availabeDates = errorResponse.dateList;
      throw error;
    } finally {
      this.isloading = false;
    }
  }

  GetLocalTime(date: Date) {
    const localDate = new Date(date);
    localDate.setMinutes(localDate.getMinutes() - new Date().getTimezoneOffset());
    return localDate;
  }

  // @OnPageVisibilityChange()
  // logWhenPageVisibilityChange(visibilityState: AngularPageVisibilityStateEnum): void {
  //   if (AngularPageVisibilityStateEnum[visibilityState]
  //     === AngularPageVisibilityStateEnum[AngularPageVisibilityStateEnum.HIDDEN] && this.router.url.includes("question")) {
  //     console.log('OnPageVisibilityChange => hidden');
  //   }
  // }

  async goToSEBInstructionsPage() {
    const url = await this.examservice.getSEBInstructionsURL(this.exam.id);
    self.top.location.href = url;
  }



}
