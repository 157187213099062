import { Component, EventEmitter, Input, NgModule, OnInit, Output } from '@angular/core';
import { OptionDetails } from '../model';

@Component({
  selector: 'app-option-check',
  templateUrl: './option-check.component.html',
  styleUrls: ['./option-check.component.scss']
})
export class OptionCheckComponent implements OnInit {
  @Input() option: any;
  @Output() checkchanged: EventEmitter<OptionDetails> = new EventEmitter();
@Input() schedulequestionid:number;
  ngOnInit(): void {
  }
  checkChanged(e, option: OptionDetails) {
    this.disableradios()
    if (e.target.checked) {
      option.selected = true;
    }
    else {
      option.selected = false;
    }

    this.checkchanged.emit(option);
  }
  disableradios() {
    var buttons = document.getElementsByClassName(this.schedulequestionid.toString());
    for (var i = 0; i < buttons.length; i++) {
      this.radiodiabbletoggle(buttons[i]);
    }
  }
  radiodiabbletoggle(button) {
    button.disabled = true;
  }
}
