import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';

import { QuillModule } from 'ngx-quill';
import { ChildModule } from './child-module/child-module';

import { AppComponent } from './app.component';
import Counter from './counter';

import { MatFormFieldModule } from '@angular/material/form-field';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';

import { ActivateRtlComponent } from './activate-rtl/activate-rtl.component';
import { EmojiComponent } from './emoji/emoji.component';
import { FormulaComponent } from './formula/formula.component';
import { ViewHTMLComponent } from './view-html/view-html.component';
import { ViewComponent } from './view/view.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { QuestionComponent } from './question/question.component';
import { QuestionoptionsComponent } from './questionoptions/questionoptions.component';
import { OptionComponent } from './option/option.component';
import { QuestionpageheaderComponent } from './questionpageheader/questionpageheader.component';
import { QuestionpagefooterComponent } from './questionpagefooter/questionpagefooter.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { LoginComponent } from './login/login.component';
import { AppRoutingModule } from './app-routing.module';
import { UserexaminationsComponent } from './userexaminations/userexaminations.component';
import { LogoutComponent } from './logout/logout.component';
import { LoginLayoutComponent } from './login-layout/login-layout.component';
import { ToastrModule } from 'ngx-toastr';
import { AlertsInterceptor } from './interceptors/alerts.interceptor';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthguardGuard } from './auth/authguard.guard';
import { JwtInterceptor } from './interceptors/jwt.interceptor';
import { DefaultComponent } from './default/default.component';
import { ExamLayoutComponent } from './exam-layout/exam-layout.component';
import { UserexaminationComponent } from './userexamination/userexamination.component';
import { QuestionlayoutComponent } from './questionlayout/questionlayout.component';
import { OptionCheckComponent } from './option-check/option-check.component';
import { SubmitComponent } from './submit/submit.component';
import { CountdownModule } from 'ngx-countdown';
import { QuestionlistComponent } from './questionlist/questionlist.component';
import { LoadingSpinnerComponent } from './shared/loading-spinner/loading-spinner.component';
import { SubdomainInterceptor } from './interceptors/subdomain.interceptor';
import { CopypastecutDirective } from './copypastecut.directive';
import { CalculatorComponent } from './calculator/calculator/calculator.component';
import { IndexComponent } from './index/index.component';
import { ScientificcalcComponent } from './calculator/scientificcalc/scientificcalc.component';
import { ExamfeedbackComponent } from './examfeedback/examfeedback.component';
import { RegisterComponent } from './register/register.component';
import { P404Component } from './p404/p404.component';
import { DatePipe } from '@angular/common';
import { WebcamComponent } from './webcampassport/webcampassport.component';
import { SebinstructionpageComponent } from './sebinstructionpage/sebinstructionpage.component';
import { SebconfigComponent } from './sebconfig/sebconfig.component';
import { VerifyEmailComponent } from './register/verify-email/verify-email.component';
import { QuitSEBComponent } from './quit-seb/quit-seb.component';
import { LoadingEllipsesComponent } from './meeting/loading-ellipses/loading-ellipses.component';
import { MeetingChatComponent } from './meeting/meeting-chat/meeting-chat.component';
import { ParticipantAudioComponent } from './meeting/participant-audio/participant-audio.component';
import { ParticipantVideoComponent } from './meeting/participant-video/participant-video.component';
import { SelectMediaComponent } from './meeting/select-media/select-media.component';
import { MeetingComponent } from './meeting/meeting/meeting.component';
import { ParticipantsListComponent } from './meeting/participants-list/participants-list.component';
import { MainGroupListParticipantComponent } from './meeting/main-group-list-participant/main-group-list-participant.component';
import { ParticipantVideoSetupViewComponent } from './meeting/participant-video-setup-view/participant-video-setup-view.component';
import { FilterParticipantsPipe } from './meeting/services/filter-participants.pipe';
import { MinorGroupListParticipantComponent } from './meeting/minor-group-list-participant/minor-group-list-participant.component';
@NgModule({
  bootstrap: [AppComponent],
  declarations: [
    AppComponent,
    ActivateRtlComponent,
    FormulaComponent,
    EmojiComponent,
    ViewComponent,
    ViewHTMLComponent,
    QuestionComponent,
    QuestionoptionsComponent,
    OptionComponent,
    QuestionpageheaderComponent,
    QuestionpagefooterComponent,
    LoginComponent,
    UserexaminationsComponent,
    LogoutComponent,
    LoginLayoutComponent,
    DefaultComponent,
    ExamLayoutComponent,
    UserexaminationComponent,
    QuestionlayoutComponent,
    OptionCheckComponent,
    SubmitComponent,
    QuestionlistComponent,
    LoadingSpinnerComponent,
    CopypastecutDirective,
    CalculatorComponent,
    IndexComponent,
    ScientificcalcComponent,
    ExamfeedbackComponent,
    RegisterComponent,
    P404Component,
    WebcamComponent,
    SebinstructionpageComponent,
    SebconfigComponent,
    VerifyEmailComponent,
    QuitSEBComponent,
    LoadingEllipsesComponent,
    MeetingChatComponent,
    ParticipantAudioComponent,
    ParticipantVideoComponent,
    SelectMediaComponent,
    MeetingComponent,
    ParticipantsListComponent,
    MainGroupListParticipantComponent,
    ParticipantVideoSetupViewComponent,
    FilterParticipantsPipe,
    MinorGroupListParticipantComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    CommonModule,
    CountdownModule,
    QuillModule.forRoot({
      modules: {
        toolbar: [
          ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
          ['blockquote', 'code-block'],
  
          //[{ 'header': 1 }, { 'header': 2 }],               // custom button values
          [{ 'list': 'ordered' }, { 'list': 'bullet' }],
          [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
          [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
          [{ 'direction': 'rtl' }],                         // text direction
  
          //[{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
          [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
  
          [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
          [{ 'font': [] }],
          [{ 'align': [] }],
  
          ['link', 'image'/*, 'video', 'embed'*/],
  
          ['clean'],                                         // remove formatting button
  
          // ['save']
        ],
      },
    }),
    ChildModule,
    NgbModule,
    NgxPaginationModule,
    ToastrModule.forRoot(),
    AppRoutingModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AlertsInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SubdomainInterceptor,
      multi: true,
    },
    AuthguardGuard,
    DatePipe,
  ],
})
export class AppModule { }
