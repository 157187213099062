import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ExamService } from 'src/app/services/exam.service';

@Component({
  selector: 'app-verify-email',
  templateUrl: './verify-email.component.html',
  styleUrls: ['./verify-email.component.scss']
})
export class VerifyEmailComponent implements OnInit {

  constructor(
    public router: Router,
    public toastr: ToastrService,
    public examservice: ExamService,
    public activatedroute: ActivatedRoute,) { }

  ngOnInit() {
    let jwt: string = this.activatedroute.snapshot.params.jwt;
    console.log(jwt);
    this.examservice.verifyEmail(jwt).then((res:any)=>{
      console.log(res);
      sessionStorage.setItem('validated', 'true');
      sessionStorage.setItem('email', res.email);
      this.router.navigate([`/register/${res.urlcode}`]);

    }).catch((e)=>{
      if (e.error.message) {
        this.toastr.error(e.error.message);
      } else {
        this.toastr.warning('An error occured please try again');
      }
      console.log(e.error);
      console.log(e);
      this.goto404();
    });

  }
  goto404() {
    this.router.navigate(['/404']);
  }
}
