import { Component, Inject, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router, ActivatedRoute, NavigationEnd, NavigationError, NavigationStart } from '@angular/router';

import Quill from 'quill';
import { LayoutFile } from './models/layoutfile.model';
import { LayoutService } from './services/layout.service';

const parchment = Quill.import('parchment');
const block = parchment.query('block');
block.tagName = 'DIV';
// or class NewBlock extends Block {} NewBlock.tagName = 'DIV'
Quill.register(block /* or NewBlock */, true);

@Component({
  selector: 'app-root',
  styleUrls: ['./app.component.css'],
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
  title = 'WEB TEST';
  name = 'Jesutomi';
  examname = 'Mathematics';
  layoutFiles: LayoutFile[] = [];
  activeLayoutFiles: LayoutFile[] = [];
  private onInitDone = false;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private layoutService: LayoutService,
    private sanitizer: DomSanitizer
  ) {
    this.hideContent();
    this.router.events.subscribe((event:any) => {
      this.routerEventHandler(event);
    });
  }

  async preloadCustomStyles() {
    this.layoutFiles = await this.layoutService.getLayoutFiles();

    for (const layoutFile of this.layoutFiles) {
      layoutFile.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
        layoutFile.filePath
      );
    }
  }

  async ngOnInit() {
    //we don't want the application's default styles to display while we are loading the customer syles
    //so to prevent that, we hide the entire application until the styles have been retreived and the css FULLY loaded
    //to know if they are fully loaded, we may have to check if onload has fired on all the link tags
    await this.preloadCustomStyles();
    this.setActiveLayoutFiles(this.router.url);
    await this.waitForAllStyleFilesToBeLoaded();
    this.showContent();
    this.onInitDone = true;
  }

  hideContent() {
    const appRoot: any = document.getElementsByTagName('app-root')[0];
    appRoot.style.visibility = 'hidden';
    document.body.classList.add('body-loading');
  }

  showContent() {
    const appRoot: any = document.getElementsByTagName('app-root')[0];
    appRoot.style.visibility = 'visible';
    document.body.classList.remove('body-loading');
  }

  customCssLoaded(index: number) {
    console.log('loaded: ' + index);
    this.layoutFiles[index].loaded = true;
  }

  customCssErrored(index: number) {
    alert(
      `Error loading custom css file '${this.layoutFiles[index].filePath}'`
    );
    document.location.reload();
  }

  checkAllStyleFilesLoaded() {
    for (let i = 0; i < this.layoutFiles.length; i++) {
      if (!this.layoutFiles[i].loaded) {
        return false;
      }
    }
    return true;
  }

  waitForAllStyleFilesToBeLoaded(): Promise<void> {
    //await this.layoutService.sleep(2000);
    return new Promise((accept, reject) => {
      const check = () => {
        if (this.checkAllStyleFilesLoaded()) {
          accept();
        } else {
          setTimeout(check, 200);
        }
      };
      check();
    });
  }

  async routerEventHandler(event: Event) {
    if (event instanceof NavigationStart) {
      console.log('router event', event);

      //hide the content of the page until the proper css has been applied
      this.hideContent();
    } else if (event instanceof NavigationEnd) {
      const navigationEnd = event as NavigationEnd;

      // Hide progress spinner or progress bar
      //this.currentRoute = event.url;
      console.log('router event', event);

      //apply the proper css, then display the page
      const initDonePromise = new Promise<void>((accept, reject) => {
        const initDone = () => {
          if (this.onInitDone) {
            accept();
          } else {
            setTimeout(initDone, 200);
          }
        };
        initDone();
      });

      await initDonePromise;

      this.setActiveLayoutFiles(navigationEnd.urlAfterRedirects);

      await this.waitForAllStyleFilesToBeLoaded();

      this.showContent();
    } else if (event instanceof NavigationError) {
      this.showContent();
    }
  }

  private setActiveLayoutFiles(url: string) {
    this.activeLayoutFiles = [];
    for (const layoutFile of this.layoutFiles) {
      const regex = new RegExp(layoutFile.urlRegex);
      if (regex.test(url)) {
        this.activeLayoutFiles.push(layoutFile);
      }
    }

    console.log('active layout files: ', this.activeLayoutFiles);
  }
}
