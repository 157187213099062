import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ExamService } from '../services/exam.service';
import { Exam } from '../models/Exam';
import { UserauthenticationserviceService } from '../services/userauthenticationservice.service';
import { ProctoringService } from '../services/proctoring.service';
import { QuestionserviceService } from '../services/questionservice.service';
import { CbtserviceService } from '../services/cbtservice.service';
import { CurrentUser } from '../models/CurrentUser';

@Component({
  selector: 'app-userexaminations',
  templateUrl: './userexaminations.component.html',
  styleUrls: ['./userexaminations.component.css'],
})
export class UserexaminationsComponent implements OnInit, AfterViewInit, OnDestroy {
  exams: Exam[];
  user: CurrentUser = new CurrentUser();
  isloading: boolean;
  interval: any;
  hasloaded:boolean=false;
  isDestroyInterval: boolean;
  constructor(
    public activatedroute: ActivatedRoute,
    public router: Router,
    public examservice: ExamService,
    public toastr: ToastrService,
    private userauth: UserauthenticationserviceService,
    private proctorservice: ProctoringService,
    private questionservice:QuestionserviceService,
    private cbtservice: CbtserviceService
  ) { 
    //debugger;
  }

  async ngOnInit() {
    //debugger;
    this.questionservice.previousquestiondata = null;
    this.questionservice.nextquestiondata = null;
    this.isloading = true;
    try{
      this.user = await this.userauth.getCurrentUser();
    }
    catch(err){
      this.isloading = false;
      throw err;
    }

    let candidateno = this.user.username;
    //await this.proctorservice.stopProctor(); //just in case
    await this.getuserexams(candidateno);
    this.isloading = false;

    this.hasloaded=true;
    this.interval =  setInterval(()=>{ this.getuserexams(candidateno);},60000);

  }
  async getuserexams(candidateno: any) {
    this.isloading = true;
    this.exams = await this.examservice
      .getuserexams(candidateno)
      .catch((err) => this.cbtservice.showHttpError(err))
      .finally(() => (this.isloading = false));
  }

  async ngAfterViewInit() {
    //debugger;

    //no need to await the below
    this.proctorservice.stopProctor('User exams after view init'); //just in case
    if(!this.activatedroute.snapshot.queryParams.skipMediaTest){
      this.proctorservice.startProcteeTestVideo();
    }
  }

  ngOnDestroy() {
    if (this.interval) {
      //debugger;
      clearInterval(this.interval);
      this.interval = undefined;
    }
  }

  destroyInterval(isDestroyInterval:boolean) {
    //debugger;
     clearInterval(this.interval);
     this.interval = undefined;
  }
  resumeInterval(e) {
    if(!this.interval){
      this.interval =  setInterval(()=>{ this.getuserexams(this.user.username);},60000);
    }
  }
}
