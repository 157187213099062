import { Injectable } from '@angular/core';
import { LayoutFile } from '../models/layoutfile.model';
import { CbtserviceService } from './cbtservice.service';
import { HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class LayoutService {
  private layoutFiles: LayoutFile[] = undefined;
  req_headers: HttpHeaders;

  readonly rootURL = environment.CBTAPIURL;
  constructor(private cbtservice: CbtserviceService) {
    this.req_headers = new HttpHeaders({
      'content-type': 'application/json',
      accept: 'application/json',
      skip_error_handler_interceptor: 'true',
    }); //all the services here should skip the interceptor
  }
  async getLayoutFiles() {
    if (this.layoutFiles === undefined) {
      this.layoutFiles = await this.loadLayoutFiles();
      console.log(this.layoutFiles);
    }
    return this.layoutFiles;
  }

  sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  private async loadLayoutFiles(): Promise<LayoutFile[]> {
    //this will get the layout files from db
    return await this.cbtservice.tryGet(
      this.rootURL + `layout/getcompanycustomcss`,
      { headers: this.req_headers }
    );
  }
}
