<!-- <div class=""> -->
<div class="toolbar" role="banner">
  <!-- remove p-2 and m-2 mb-5 class -->
  <div class="float-left">
    <h2 class="logo ml-2">
      <!-- <a style="color: white !important; cursor: pointer;" (click)="examlist()"> -->
      <a style="color: white !important; cursor: pointer;" routerLink="/exam/userexams">
        <!-- <img *ngIf="subdomain && logourl" [src]="logourl" width="190" alt="logo" /> -->
        <!-- <img src="/assets/images/unilag-logo-white.png" width="190" alt="logo" /> -->
        <img src="/assets/images/main-logo.png" width="180" alt="logo" />
      </a>
    </h2>
  </div>
  <!-- <img width="40" alt="Angular Logo" src="../assets/images/image.ico" /> -->
  <div class="spacer h5">
    <span class=" ml-5 mobilehide" style="color: white !important; ">
      <span class="welcome">Welcome,</span> <span class="user_name">{{this.user.fullname}}</span>
    </span>
    <span style="float: right !important; color: white !important; margin-right: 2vw;">
      <a style="color: white !important; cursor: pointer;" routerLink="/logout">
        <i class="fa fa-sign-out"></i>
        Logout
      </a>
    </span>
    <!-- <span style="float: right !important; color: white !important; margin-right: 2vw;"><a style="color: white !important; cursor: pointer;"(click)="logout()"><i class="fa fa-sign-out"></i> Logout</a></span> -->
    <!--   <button *ngIf="" type="button" class="btn btn-outline-secondary">QuestionList</button> -->
  </div>
</div>
<div class="container-fluid">
  <!-- <div style="position: relative; top: 10vh;" class="mb-5">
        <app-questionpageheader></app-questionpageheader>
    </div> -->
  <div class="overlay" [ngClass]="{'show': loading}">

  </div>
  <div class="spanner" [ngClass]="{'show': loading}">
    <div class="spin-wrap">
      <div class="spin-wrap-content">
        <div class="spinner-container">
          <div class="lds-ripple">
            <!-- <div></div>
                  <div></div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="inner_exam_wrap">
    <div class="row">
      <!-- remove position: relative; top: 5vh; -->
      <div class="mobile my-2">
        <div class="accordion" id="mobileaccordion">
          <div class="card-header p-2" id="passportsectionmobile" data-toggle="collapse"
            data-target="#passportsectionmobilecollapse" aria-expanded="false"
            aria-controls="#passportsectionmobilecollapse">
            <h5 class="mb-0">
              <span>PASSPORT SECTION</span>
            </h5>
          </div>
          <div id="passportsectionmobilecollapse" class="collapse " aria-labelledby="#passportsectionmobile"
            data-parent="#mobileaccordion">
            <div class="card-body">
              <div class="col-md-2 border border-right usericon">
                <!-- Card body -->
                <div class="mt-2">
                  <div class="row mb-1">
                    <div class="col-md-12">
                      <div class="user_profile_wrap">

                        <!-- <img src="../../assets/img/theme/team-1.jpg"
                              class="rounded-circle img-center img-fluid shadow shadow-lg--hover" style="width: 140px;"> -->
                        <h6 *ngIf="togglepassportui" style="text-align: center; font-weight:bold;">Max. File Upload is
                          1mb
                        </h6>
                        <img *ngIf="imageUrl" [src]="imageUrl" class="rounded img-center img-fluid"
                          style="width: 160px;"><!-- (error)="updateImageUrl($event)"-->
                        <div *ngIf="canUploadPassport">

                          <h6 *ngIf="showlimittext" style="text-align: center;" class="text-danger">{{limittext}}</h6>

                          <input *ngIf="togglepassportui" accept="image/png, image/jpeg" type="file"
                            (change)="onFileChange($event)" style="margin-top:8px" />

                          <div (click)="showwebcammodal()" class="d-flex justify-content-around align-items-center"
                            style="background-color: #74bbf7; width: 90%; padding-left: 2px; padding-right: 2px; margin: 5px auto; border-radius: 5px; cursor: pointer;">
                            <i class="fa fa-camera"></i>
                            <p style="font-weight:bold;text-overflow: ellipsis;white-space: nowrap;overflow: hidden;">
                              Take Picture</p>
                          </div>
                          <!-- <div (click)="uploadPassport()" class="d-flex justify-content-around align-items-center"
                                style="background-color: #74bbf7; width: 90%; padding-left: 2px; padding-right: 2px; margin: 5px auto; border-radius: 5px; cursor: pointer;">
                                <i class="fa fa-camera"></i>
                                <p style="font-weight:bold;text-overflow: ellipsis;white-space: nowrap;overflow: hidden;">{{uploadActionText}}</p>
                              </div> -->

                        </div>
                        <div *ngIf="isInIframe()" class="candidate-video-div">
                          <video class="candidate-video" #candidateVideo playsinline autoplay muted></video>
                          <div class="text-info text-center">
                            <ul>
                              <li>Please ensure your face is clearly visible at all times.</li>
                              <li>Please ensure there are no bright lights or windows behind you.</li>
                            </ul>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                  <div class="col text-center">
                    <button title="Requests Attention" (click)="raiseHandClicked()"
                      class="btn btn-sm btn-primary p-1 m-2" *ngIf="isProctorAssigned()">
                      Request Attention
                    </button>
                  </div>                 
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-2 border border-right desk">
        <!-- Card body -->
        <div class="mt-2">
          <div class="row mb-1">
            <div class="col-md-12">
              <div class="user_profile_wrap">

                <!-- <img src="../../assets/img/theme/team-1.jpg"
                    class="rounded-circle img-center img-fluid shadow shadow-lg--hover" style="width: 140px;"> -->
                <h6 *ngIf="togglepassportui" style="text-align: center; font-weight:bold;">Max. File Upload is 1mb
                </h6>
                <img *ngIf="imageUrl" [src]="imageUrl" class="rounded img-center img-fluid"
                  style="width: 160px;"><!-- (error)="updateImageUrl($event)"-->
                <div *ngIf="canUploadPassport">

                  <h6 *ngIf="showlimittext" style="text-align: center;" class="text-danger">{{limittext}}</h6>

                  <input *ngIf="togglepassportui" accept="image/png, image/jpeg" type="file"
                    (change)="onFileChange($event)" style="margin-top:8px" />

                  <div (click)="showwebcammodal()" class="d-flex justify-content-around align-items-center"
                    style="background-color: #74bbf7; width: 90%; padding-left: 2px; padding-right: 2px; margin: 5px auto; border-radius: 5px; cursor: pointer;">
                    <i class="fa fa-camera"></i>
                    <p style="font-weight:bold;text-overflow: ellipsis;white-space: nowrap;overflow: hidden;">Take
                      Picture</p>
                  </div>
                  <!-- <div (click)="uploadPassport()" class="d-flex justify-content-around align-items-center"
                      style="background-color: #74bbf7; width: 90%; padding-left: 2px; padding-right: 2px; margin: 5px auto; border-radius: 5px; cursor: pointer;">
                      <i class="fa fa-camera"></i>
                      <p style="font-weight:bold;text-overflow: ellipsis;white-space: nowrap;overflow: hidden;">{{uploadActionText}}</p>
                    </div> -->

                </div>
                <div *ngIf="isInIframe()" class="candidate-video-div">
                  <video class="candidate-video" #candidateVideo playsinline autoplay muted></video>
                  <div class="text-info text-center">
                    <ul>
                      <li>Please ensure your face is clearly visible at all times.</li>
                      <li>Please ensure there are no bright lights or windows behind you.</li>
                    </ul>
                  </div>
                </div>

              </div>
            </div>
          </div>
          <div class="col text-center">
            <button title="Requests Attention" (click)="raiseHandClicked()" class="btn btn-sm btn-primary p-1 m-2"
              *ngIf="isProctorAssigned()">
              Request Attention
            </button>
          </div>
          <div *ngIf="usecalc" class="row mt-1">
            <div class="col-md-12">
              <div class="container mt-1">
                <!-- <h1 class="mb-3">Calculator</h1> -->
                <app-scientificcalc *ngIf="calcmode == 'Scientific'"></app-scientificcalc>
                <app-calculator *ngIf="calcmode == 'Simple'"></app-calculator>
              </div>
              <div class="pt-4 text-center user-names">
                <h5 class="h5 title user-fullname">
                  <!-- <span class="d-block mb-1">{{userauth.currentUser.fullname}}</span> -->
                </h5>
                <!-- <h6 class="h5 title user-name">
                    <span class="d-block mb-1">{{userauth.currentUser.username}}</span>
                </h6> -->


              </div>


            </div>
          </div>
        </div>
      </div>
      <div class="col-md-10">
        <router-outlet></router-outlet>
      </div>
    </div>
    <div *ngIf="usecalc" class=" hidecalc row mt-10">
      <div class="col-md-12">
        <div class="container m-2">
          <!-- <h1 class="mb-3">Calculator</h1> -->
          <app-scientificcalc *ngIf="calcmode == 'Scientific'"></app-scientificcalc>
          <app-calculator *ngIf="calcmode == 'Simple'"></app-calculator>
        </div>
        <div class="pt-4 text-center user-names">
          <h5 class="h5 title user-fullname">
            <!-- <span class="d-block mb-1">{{userauth.currentUser.fullname}}</span> -->
          </h5>
          <!-- <h6 class="h5 title user-name">
              <span class="d-block mb-1">{{userauth.currentUser.username}}</span>
          </h6> -->


        </div>


      </div>
    </div>
    <!-- <div class="col-md-1">

      </div> -->
  </div>
</div>
<!-- <div>
      <app-questionpagefooter></app-questionpagefooter>
  </div> -->
<!-- </div> -->
<div class="modal fade camtake" id="webcammodal" tabindex="1" data-keyboard='true' aria-hidden="true" role="dialog"
  style="height: auto !important; margin: 5vw; ">

  <div class="modal-dialog" style="max-width: 100% !important; height: 100%;">
    <div class="modal-content">
      <div class="modal-header">
        <h3 class="modal-title ">Take Picture</h3>
        <button type="button" class="close h3" data-dismiss="modal" aria-label="Close">&times;</button>
      </div>
      <div class="modal-body">

        <app-webcam-passport style="height: 100% !important;" *ngIf="webcammode" [examno]="this.user.username"
          (imagefile)="imagefilereceived($event)">
        </app-webcam-passport>


      </div>
    </div>
  </div>




</div>