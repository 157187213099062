<div class="section-title">
  <div class="mt-2">
    <!-- <button style="float: right;" type="button" (click)="savebtnclicked()" class="btn cornblue">Save</button> -->
  </div>
</div>
<div class="row">
  <div class="col" style="text-align: left;">
    <span *ngIf="wordcountdisplay" [ngClass]="{'higlight': higlight}">{{wordcountdisplay}}</span>
  </div>
  <div class="col" style="text-align: right;">
    <span *ngIf="maxwordcount" style="color: red;">Limit of {{maxwordcount}} words maximum</span>
  </div>
</div><div style="width: 100% !important;" spellcheck="false">
  <!-- <quill-editor [styles]="{height: '200px'}" (onFocus)="focus($event)" (onEditorChanged)="changedEditor($event)" (onBlur)="blur($event)" (onEditorCreated)="created($event)"></quill-editor> -->
  <quill-editor spellcheck="false"  required #answer name="answer" placeholder="Type In Your Answer"></quill-editor>
</div>
<div class="row">
  <div class="col" style="text-align: left;">
    <span *ngIf="wordcountdisplay" [ngClass]="{'higlight': higlight}">{{wordcountdisplay}}</span>
  </div>
  <div class="col" style="text-align: right;">
    <span *ngIf="maxwordcount" style="color: red;">Limit of {{maxwordcount}} words maximum</span>
  </div>
</div>
<!-- <div style="display: block;"> <span>{{wordcountdisplay}}</span> <span>{{wordcountdisplay}}</span></div> -->

<!-- <h4>Material Quill</h4>

<mat-form-field appearance="fill">
  <mat-quill [styles]="{height: '200px'}" (onFocus)="focus($event)" (onEditorChanged)="changedEditor($event)" (onBlur)="blur($event)" (onEditorCreated)="created($event)"></mat-quill>
</mat-form-field> -->