import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Util } from '../interceptors/util';
import { RegisterCandidate, TimeDisplay } from '../model';
import { CbtserviceService } from '../services/cbtservice.service';
import { ExamService } from '../services/exam.service';
import { UserauthenticationserviceService } from '../services/userauthenticationservice.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css'],
})
export class RegisterComponent implements OnInit {
  logourl: any;
  isLoading: boolean = false;
  showForm: boolean = false;
  candidateData: RegisterCandidate = {
    companyId: '',
    auth: '',
    examUrlCode: '',
    firstName: '',
    lastName: '',
    otherName: '',
    phoneNo: '',
    emailAddress: '',
    examEndDateTime: undefined,
    examStartDateTime: undefined,
    gender: '',
  };
  registerSuccess: boolean;
  emailPattern = '^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$';
  // phonePattern = '([+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*){11,}$';
  phonePattern = '^[0]+[0-9]{10}$';
  passportPhoto: File;
  @ViewChild('passportDisplay') passportDisplay;
  @ViewChild('passportPix') passportPix;
  label1: string;
  label2: string;
  label3: string;
  label4: string;
  type1: string;
  type2: string;
  type3: string;
  type4: string;
  option1 = [];
  option2 = [];
  option3 = [];
  option4 = [];
  valiatedEmail = false;
  confirmedEmail: string;
  emailSent = false;
  code?: string;
  examName: string;
  examNo: string;
  showPassport: boolean = false;
  showGender: boolean = false;
  pix: boolean = false;
  examid: number;
  isDate = false;
  isTime = false;
  minDate = '';
  maxDate = '';
  availabeTimes = Array<TimeDisplay>();
  CavailabeTimes = [];
  availabeDates = [];
  scheduledDate = '';
  scheduledPosition: number;
  startList = Array<Date>();
  endList = Array<Date>();
  showproctorerror: boolean;
  selfSchedule: boolean;
  usedropdown: boolean;
  constructor(
    public util: Util,
    public userauthservice: UserauthenticationserviceService,
    public toastr: ToastrService,
    public activatedroute: ActivatedRoute,
    public router: Router,
    public examservice: ExamService,
    private cbtservice: CbtserviceService,
    private datePipe: DatePipe
  ) {}

  async ngOnInit() {
    //Use ExamId
    this.isLoading = true;
    let examURlID: string = this.activatedroute.snapshot.params.examid;
    await this.examservice
      .validateExamID(examURlID)
      .then(async (c) => {
        this.showForm = true;
        this.candidateData = c;
        this.examName = c.examName;
        this.examid = c.examid;
        this.label1 = c.label1;
        this.label2 = c.label2;
        this.label3 = c.label3;
        this.label4 = c.label4;

        this.type1 = c.type1;
        this.type2 = c.type2;
        this.type3 = c.type3;
        this.type4 = c.type4;

        this.option1 = c.options1;
        this.option2 = c.options2;
        this.option3 = c.options3;
        this.option4 = c.options4;
        console.log(c);
        this.sortOptions();

        this.showGender = c.showGender;
        this.showPassport = c.showPassport;
        this.selfSchedule = c.selfSchedule;
        let email = sessionStorage.getItem('email');
        let validated = sessionStorage.getItem('validated');

        if (validated) {
          this.valiatedEmail = true;
          this.candidateData.emailAddress = email;
        }
        if (this.selfSchedule) {
          try {
            let response = await this.examservice.getuserscheduleDates(
              this.examid
            );
            // console.log(response);
            this.minDate = response.minDate;
            this.maxDate = response.maxDate;
            this.availabeDates = response.dateList;
            this.usedropdown = response.useDropDown;
            console.log(this.availabeDates);

            //$('#scheduleModal').modal('show');
            if (this.availabeDates.length != 0) {
              this.isDate = true;
            }
          } catch (error) {
            console.log('error', error);

            this.cbtservice.showHttpError(error);
            this.isLoading = false;
            throw error;
          }
        }
        //console.log(c);
      })
      .catch((err) => {
        this.isLoading = false;
        if (err.error.Error) {
          this.toastr.error(err.error.Error);
        }

        if (err.error.message) {
          this.toastr.error(err.error.message);
        }
        this.toastr.error();
        console.log(err);

        this.goto404();
      })
      .finally(() => (this.isLoading = false));
    const subdomain = this.util.getSubdomain();
    if (subdomain) {
      this.isLoading = true;
      var data: any = await this.userauthservice
        .getsubdomaincompanyinfo(subdomain)
        .toPromise()
        .catch((err) => this.toastr.error(err))
        .finally(() => (this.isLoading = false));
      this.logourl = data.logourl;
    }

    // console.log("Testing ExamID: " + examid);
    // console.log(this.activatedroute.children);
  }
  sortOptions() {
    if (this.option1) {
      this.option1.sort();
    }
    if (this.option2) {
      this.option2.sort();
    }
    if (this.option3) {
      this.option3.sort();
    }
    if (this.option4) {
      this.option4.sort();
    }
  }

  onFileSelected(event: any) {
    if (event.target.files && event.target.files[0]) {
      if (event.target.files[0].size > 1048576) {
        this.toastr.warning('Image size is to greater than 1MB.');
        this.passportPix.nativeElement.value = '';
        this.passportDisplay.nativeElement.value = '';
        this.pix = false;
        return;
      }
      var ext = event.target.files[0].name.split('.').pop();
      //debugger;

      if (ext !== 'png' && ext !== 'jpeg' && ext !== 'jpg') {
        this.toastr.warning('Invalid image format .' + ext);
        this.passportPix.nativeElement.value = '';
        this.passportDisplay.nativeElement.value = '';
        this.pix = false;
        return;
      }
      this.passportPhoto = event.target.files[0];

      const reader = new FileReader();
      reader.onload = (e) =>
        (this.passportDisplay.nativeElement.src = reader.result);

      reader.readAsDataURL(this.passportPhoto);
      this.pix = true;
    }
  }

 async  registerclicked() {
    if (this.showGender && !this.candidateData.gender) {
      this.toastr.error('Please select gender');
      return;
    }
    if (this.selfSchedule && (!this.scheduledPosition || !this.scheduledDate)) {
      this.toastr.error('Please fill Examination Schedule details');
      return;
    }
    if (this.showPassport && !this.passportPhoto) {
      this.toastr.error('Please upload passport picture');
      return;
    }
    
    if (this.showPassport && this.passportPhoto){
        // check isface for passport photo
        // const faceResult = await this.examservice.confirmPassportHasFace(this.passportPhoto);
        // if (!faceResult.hasface) {
        //   this.toastr.error(`No Face was found in the Passport`);
        //   return;
        // }
        // if (faceResult.hasface  && faceResult.multipleFaces) {
        //   this.toastr.error(`Multiple Face was found in the Passport`);
        //   return;
        // }
    }
  
    this.candidateData.passport = this.passportPhoto;
    const formData = new FormData();
    //formData.append('Auth', this.candidateData.auth);
    formData.append('CompanyId', this.candidateData.companyId ?? '');
    formData.append('ExamUrlCode', this.candidateData.examUrlCode ?? '');
    formData.append('FirstName', this.candidateData.firstName ?? '');
    formData.append('LastName', this.candidateData.lastName ?? '');
    formData.append('OtherName', this.candidateData.otherName ?? '');
    formData.append('PhoneNo', this.candidateData.phoneNo ?? '');
    formData.append('EmailAddress', this.candidateData.emailAddress ?? '');
    formData.append('ExtraField1', this.candidateData.extraField1 ?? '');
    formData.append('ExtraField2', this.candidateData.extraField2 ?? '');
    formData.append('ExtraField3', this.candidateData.extraField3 ?? '');
    formData.append('ExtraField4', this.candidateData.extraField4 ?? '');
    formData.append('Gender', this.candidateData.gender ?? '');
    formData.append('Passport', this.candidateData.passport);
    formData.append('ExamEndDateTime', '' + this.candidateData.examEndDateTime);
    formData.append(
      'ExamStartDateTime',
      '' + this.candidateData.examStartDateTime
    );
    if (this.selfSchedule) {
      formData.append(
        'stime',
        '' +
          this.datePipe.transform(
            this.GetLocalTime(this.startList[this.scheduledPosition]),
            'mediumTime'
          )
      );
      formData.append(
        'date',
        '' +
          this.datePipe.transform(
            this.GetLocalTime(this.startList[this.scheduledPosition]),
            'MM/dd/yyyy'
          )
      );
      formData.append(
        'etime',
        '' +
          this.datePipe.transform(
            this.GetLocalTime(this.endList[this.scheduledPosition]),
            'mediumTime'
          )
      );
    }
    //console.log(this.candidateData);
    //debugger;
    try {
      this.isLoading = true;
      this.examservice
      .registerExamCandidate(formData)
      .then((d: any) => {
        this.toastr.success('Registration Complete');
        this.registerSuccess = true;
        console.log(d);

        if (d.Data) {
          this.examNo = d.Data;
        }
        sessionStorage.clear();
      }).catch(err => {
        if (err.error.Error) {
          this.toastr.error(err.error.Error);
        } else {
          this.toastr.warning(err);
          console.log(err);
        }
      })
      
    } catch (err) {
      this.toastr.warning('An error occurred while registering');
      console.log(err);
    }
    finally {
      this.isLoading = false
    }
  }

  VerifyEmail() {
    var regx = new RegExp(this.emailPattern);
    //debugger;
    if (regx.test(this.confirmedEmail) && this.examid) {
      this.isLoading = true;
      this.examservice
        .sendVerifyEmail(this.confirmedEmail, this.examid)
        .then((c) => {
          //debugger;
          this.emailSent = true;
          this.toastr.success("A Verification Email Has been sent to "+this.confirmedEmail)
        })
        .catch((e) => {
          if (e.error.message) {
            this.toastr.error(e.error.message);
          } else {
            this.toastr.warning('An error occured please try again');
          }
          console.log(e.error);
          console.log(e);
        })
        .finally(() => (this.isLoading = false));
    } else {
      this.toastr.warning('Invalid Email');
    }
  }

  VerifyCode() {
    let codeS = sessionStorage.getItem('code');
    let email = sessionStorage.getItem('email');

    if (codeS.toLowerCase() === this.code.toLowerCase()) {
      this.candidateData.emailAddress = email;
      this.toastr.info('Email Verified');
      this.valiatedEmail = true;
      sessionStorage.setItem('validated', 'true');
    } else {
      this.toastr.warning('Invalid code');
    }
  }

  goto404() {
    this.router.navigate(['/404']);
  }

  async onDateChange(event: any) {
    this.availabeTimes = [];

    let d = event.target.value;
    //console.log(d);
    if (this.availabeDates.find((x) => x == d)) {
      this.isLoading = true;

      this.scheduledDate = d;
      try {
        let response = await this.examservice.getuserscheduleTimes(
          this.examid,
          this.scheduledDate
        );

        // this.availabeTimes = response.stimeList;
        this.startList = response.stimeList;
        this.endList = response.etimeList;
        this.startList.forEach((value, index) => {
          //geting local time for start time
          const sDate = this.GetLocalTime(value);
          //geting local time for end time
          const eDate = this.GetLocalTime(this.endList[index]);
          // console.log(sDate.getHours() + ":" + sDate.getMinutes() + ":" + sDate.getSeconds());

          let formatedDate: TimeDisplay = {
            position: index,
            displaytime:
              this.datePipe.transform(sDate, 'mediumTime') +
              ' - ' +
              this.datePipe.transform(eDate, 'mediumTime'),
          };
          // console.log("start time "+);
          this.availabeTimes.push(formatedDate);
        });

        if (this.availabeTimes.length != 0) {
          this.isTime = true;
        }
      } catch (error) {
        let errorResponse = error;
        console.log(errorResponse);
        this.isLoading = false;
        this.minDate = errorResponse.minDate;
        this.maxDate = errorResponse.maxDate;
        this.availabeDates = errorResponse.dateList;
        throw error;
      } finally {
        this.isLoading = false;
      }
    } else {
      this.toastr.warning('Unavailable Date Selected');
      event.target.value = '';
      this.availabeTimes = [];

      return;
    }
  }

  onTimeChange(event: any) {
    let t = event.target.value;
    this.scheduledPosition = t;
  }

  setGender(event: any) {
    let g = event.target.value;
    this.candidateData.gender = g;
  }

  GetLocalTime(date: Date) {
    const localDate = new Date(date);
    localDate.setMinutes(
      localDate.getMinutes() - new Date().getTimezoneOffset()
    );
    return localDate;
  }
}
