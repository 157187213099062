import { stringify } from '@angular/compiler/src/util';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbModal, ModalDismissReasons, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ExamService } from '../services/exam.service';

@Component({
  selector: 'app-examfeedback',
  templateUrl: './examfeedback.component.html',
  styleUrls: ['./examfeedback.component.css']
})
export class ExamfeedbackComponent implements OnInit {
  closeResult: string;
  modalOptions: NgbModalOptions;

  emojiquestions = [];
  checkquestions = [];
  questions: any;
  question: any;

  emojianwesers = [];
  checkanswers = [];
  othersanswer: any;
  probmsg: any;
  scheduleid: any;
  pos = 0;
  @ViewChild('mymodal') feedbackModalEmoji: ElementRef;
  @ViewChild('mymodal2') feedbackModalCheckbox: ElementRef;
  isothers: boolean;

  constructor(
    private modalService: NgbModal, public examservice: ExamService, public activatedroute: ActivatedRoute
  ) {
    this.modalOptions = {
      backdrop: 'static',
      backdropClass: 'customBackdrop'
    }
  }
  async ngOnInit() {
    this.scheduleid = this.activatedroute.snapshot.queryParams.scheduleid;
    this.questions = await this.examservice.getfeedbackquestions();

    this.questions.forEach(element => {

      // console.log(element.feedbackquestiontype);
      if (element.feedbackquestiontype === 'Emoji') {
        this.emojiquestions.push(element);
      }

      if (element.feedbackquestiontype === 'CheckBox') {
        this.checkquestions.push(element);
        this.checkanswers.push({
          'QuestionID': element.questionid,
          'CheckboxFeedback': false,
          'ExamScheduleID': this.scheduleid
        });
      }
    });

    this.question = this.emojiquestions[this.pos];


  }

  open() {
    if (this.question) {  
      this.modalService.open(this.feedbackModalEmoji, this.modalOptions);
    }
  }

  onfaceclicked(mood: string, id: any) {
    this.modalService.dismissAll();

    this.emojianwesers.push({
      'QuestionID': id,
      'EmojiFeedback': mood,
      'ExamScheduleID': this.scheduleid
    });
    // console.log(Object.assign(this.emojianwesers));

    if (this.pos == (this.emojiquestions.length - 1)) {

      this.pos = 0;
      this.question = this.emojiquestions[this.pos];
      setTimeout(() => {
        this.modalService.open(this.feedbackModalCheckbox, this.modalOptions);
      }, 100);
    }
    else {
      setTimeout(() => {
        this.modalService.open(this.feedbackModalEmoji, this.modalOptions);
      }, 100);
      this.pos++;
      this.question = this.emojiquestions[this.pos];
    }

  }

  onchecked(ques: any) {

    if (this.checkanswers.find(c => c.QuestionID == ques.questionid)) {
      //this.checkanswers = this.checkanswers.filter((a) => a.QuestionID !== ques.questionid)

      this.checkanswers.forEach(element => {
        if (element.QuestionID == ques.questionid) {

          element.CheckboxFeedback = (!element.CheckboxFeedback);

        }
        return element;
      });
      // console.log(JSON.stringify(this.checkanswers));

    }
  }

  checkedothers() {
    this.isothers = !this.isothers;
  }

  async savefeedback() {

    if (this.isothers) {

      this.othersanswer = {
        'OthersResponse': this.probmsg,
        'ExamScheduleID': this.scheduleid
      }
    }
    var data = {
      "feedbackEmojiResponses": this.emojianwesers,
      "feedbackCheckboxResponses": this.checkanswers,
      "others": this.isothers ? this.othersanswer : ''
    };

    this.modalService.dismissAll();
    this.probmsg = null;
    this.isothers = false;
    await this.examservice.submitfeedback(Object.assign({}, data));
  }
}