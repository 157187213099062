import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

declare var $: any;

@Injectable({
  providedIn: 'root'
})
export class Util {
  usecalculator:boolean=false;
  calculatormode:any;
  constructor() { 

  }

  getSubdomain(){
    const location = window.location.hostname;
      const splitlocation = location.split('.');
      let subdomain;
      if(splitlocation.length >= 2){
        subdomain = splitlocation[0];
        if(subdomain == 'www' || subdomain == 'cbt'){
          subdomain = '';
        }
      }
      else{
          subdomain = '';
      }
      return subdomain;
  }


  
}
