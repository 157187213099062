import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-p404',
  templateUrl: './p404.component.html',
  styleUrls: ['./p404.component.css']
})
export class P404Component implements OnInit {
  searchText:any;
  constructor() { }

  ngOnInit(): void {
  }
  goToUrl(): void {
    window.open('https://google.com/search?q='+this.searchText,'_blank');
}
}
