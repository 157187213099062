import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { UserauthenticationserviceService } from './userauthenticationservice.service';
import { ExamService } from './exam.service';

@Injectable({
  providedIn: 'root'
})
export class ProctoringService {

  promises = new Map<number, any>();
  confirmproctoringinterval;
  chatopened = false;
  proctorAssigned = false;
  examScheduleID: number;
  notPublishingTimes = 0;
  //published = false;
  constructor(private toastr: ToastrService, private router: Router, private userauthservice: UserauthenticationserviceService, private examService: ExamService) {
    window.addEventListener('message', this.proctorMessageListener.bind(this));
  }

  proctorMessageListener(evt) {
    //console.log(evt.data);
    // if (evt.data.status === 'published') {
    //     console.log('published');
    //     console.log(evt.data);
    //     this.published = true;
    //     //window.removeEventListener('message', messagelistener);
    // }
    // else if(evt.data.status === 'notpublishing') {
    //   this.published = false;
    // }
    if (evt.data.messageid) {
      if (this.promises.has(evt.data.messageid)) {
        const fns = this.promises.get(evt.data.messageid);
        const acceptfn = fns[0];
        const rejectfn = fns[1];
        this.promises.delete(evt.data.messageid);

        if (evt.data.success) {
          acceptfn(evt.data);
        }
        else {
          rejectfn(evt.data);
        }
      }
    }
    else if (evt.data.event) {
      switch (evt.data.event) {
        case 'chatopened':
          this.chatopened = true;
          break;
        case 'chatclosed':
          this.chatopened = false;
          break;
        case 'enteredroom':
          this.proctorAssigned = true;
          break;
        case 'leftroom':
          this.proctorAssigned = false;
          break;
        case 'onNoFace':
        case 'onFaceChange':
        case 'onMultipleFaces':
        case 'onCandidateFaceMatchChange':
        case 'onLowLight':
        case 'onHighIntensityLight':
        case 'onNoise':
          console.log(evt.data.warning);
          console.log("event overide ai" + evt.data.overrideai);
          if (!evt.data.overrideai)
            this.toastr.warning(evt.data.warning, undefined, { timeOut: 10000 });//show toastr for 10 seconds
          break;
        case 'aipenalty':
          this.toastr.error(evt.data.message, undefined, { timeOut: 10000 });//show toastr for 10 seconds
          break;
        default:
          break;
      }
    }
  }



  //this should only be called when the user clicks the start button
  async startProctor(username, name, examid, examdesc, examscheduleid, attempt, passportversion, passporttype, skipAIPassportCheck) {
    //debugger;
    const mythis = this;
    this.examScheduleID = examscheduleid;
    console.log("examschedule id is " + examscheduleid)
    let preventExamStart;
    preventExamStart = await this.examService.aiPreventExamStart(this.examScheduleID);
    console.log("overrideai is " + !preventExamStart.msg);

    const startPromise = this.sendMessage({ operation: 'start', 
                                            username: username, 
                                            name: name + ` (${examdesc})`, 
                                            examid: examid, 
                                            examdesc: examdesc, 
                                            attempt: attempt, 
                                            overrideai: !preventExamStart.msg,
                                            passportversion: passportversion,
                                            passporttype: passporttype, 
                                            aiCheckPhotoBeforeStart: passportversion > 0 && !skipAIPassportCheck
                                          });

    //const timeoutPromise = new Promise((res) => setTimeout(() => res("timeout"), 2 * 60 * 1000));//2 minute timeout

    //const raceResult = await Promise.race([startPromise, timeoutPromise]);

    // if(raceResult === "timeout"){
    //   throw "Unable to start proctor. Please check your internet connection and ensure you are using a supported browser.";
    // }

    const result = await startPromise as any;

    if (result.message === 'aipreventstart') {
      if (preventExamStart.msg) {
        this.toastr.error(`You have been prevented from starting the exam because: ${result.reasons.join(',')}`);
        await this.stopProctor('AI Prevented Starting');
        throw new Error("aipreventstart");
      }
    }

    const createConfirmProctoringInterval = () => {
      this.confirmproctoringinterval = setInterval(async () => {
        //debugger;
        //ever 5 seconds, check if we're not publishing
        //whenever we find we're not publishing, wait another 25 seconds...if its still not publishing, then leave the exam page
        //that's 30 seconds total
        if (!await this.checkPublishing()) {
          this.notPublishingTimes++;
          if (this.notPublishingTimes >= 12) {//if not proctoring for a minute
            clearInterval(this.confirmproctoringinterval);
            this.notPublishingTimes = 0;
            if (!this.userauthservice.loggedOut) {
              this.toastr.error('Connection to media server lost. Please reconnect');
            }
            await this.stopProctor('Not Publishing');
            this.router.navigate(["/exam/userexams"]);
          }
          // clearInterval(this.confirmproctoringinterval);
          // //this.router.navigate(["/exam/userexams"]);
          //debugger;
          // setTimeout(async () => {
          //   if (!await this.checkPublishing()) {
          //     //await this.proctorservice.startProctor(this.user.username, this.user.fullname, this.exam.examid, this.exam.examname);
          //     if (!this.userauthservice.loggedOut) {
          //       this.toastr.error('Connection to media server lost. Please reconnect');
          //     }
          //     await this.stopProctor();
          //     this.router.navigate(["/exam/userexams"]);
          //   }
          //   else {
          //     createConfirmProctoringInterval();
          //   }
          // }, 25000);
        }
        else{
          this.notPublishingTimes = 0;
        }
      }, 5000);//check every 5 seconds
    };

    createConfirmProctoringInterval();

    // return new Promise(function (accept: any, reject) {
    //     top.postMessage({ operation: 'start', username: username, name: name + ` (${examdesc})`, examid: examid, examdesc: examdesc, messageid: new Date().getTime() }, '*');//TODO: we have to add some authentication to make sure someone can't go straight to the actual exam url
    //     if(mythis.published){
    //       accept();
    //     }
    //     else{
    //       const checkPublishedInterval = setInterval(() => {
    //         if(mythis.published){
    //           clearInterval(checkPublishedInterval);
    //debugger;
    //           accept();
    //         }
    //       }, 50);
    //     }
    // });
  }

  //this.published = false;
  async stopProctor(reason: string) {
    //top.postMessage({ operation: 'stop' }, '*');
    if (top != self) {
      clearInterval(this.confirmproctoringinterval);
      await this.sendMessage({ operation: 'stop', reason: reason });
      this.proctorAssigned = false;//if we don't  do this, the request attention button will remain displayed when the user logs in again even when the proctee is not yet connected to a streaming server
    }
  }

  async showLoading() {
    //top.postMessage({ operation: 'loading', messageid: new Date().getTime()}, '*');
    await this.sendMessage({ operation: 'loading' });
  }

  async showLoaded() {
    //top.postMessage({ operation: 'loaded', messageid: new Date().getTime() }, '*');
    await this.sendMessage({ operation: 'loaded' });
  }

  async checkPublishing() {
    const res: any = await this.sendMessage({ operation: 'checkpublishing' });
    return res.publishing;
  }

  async setProctorPauseStatus(username, proctorpaused: boolean, adminpaused: boolean, pausereason: string) {
    await this.sendMessage({ operation: 'setproctorpausestatus', username: username, proctorpaused: proctorpaused, adminpaused: adminpaused, pausereason: pausereason });
  }

  async setCountdownTimer(username, timeleft) {
    await this.sendMessage({ operation: 'setcountdowntimer', username: username, timeleft: timeleft });
  }

  async raiseHand(username) {
    await this.sendMessage({ operation: 'raisehand', username: username });
  }
  async quitSEB(username) {
    await this.sendMessage({ operation: 'quitseb', username: username });
  }
  async hideSEBPowerButton(username) {
    await this.sendMessage({ operation: 'hidesebpb', username: username });
  }
  async startProcteeTestVideo() {
    await this.sendMessage({ operation: 'procteetestvideo' });
  }
  // async startAIProctor(cadidateImage: Blob | undefined = undefined){
  //   await this.sendMessage({operation: 'startaiproctor', image: cadidateImage});
  // }

  sendMessage(message) {
    message.messageid = new Date().getTime();
    top.postMessage(message, '*');
    const mythis = this;
    return new Promise(function (accept: any, reject: any) {
      mythis.promises.set(message.messageid, [accept, reject]);
    });
  }
}
