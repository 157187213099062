<!-- <div class="row">  -->
  <div class="overlay"  [ngClass]="{'show': loading}">

  </div>
  <div class="spanner" [ngClass]="{'show': loading}">
      <div class="spin-wrap">
        <div class="spin-wrap-content">
          <div class="spinner-container">
              <div class="lds-ripple">
                <div></div>
                <div></div>
              </div>
          </div>
        </div>
      </div>
  </div>
    <div class="col-lg-12">
        <div class="card">
            <div class="card-header bg-transparent" style="text-align: center;">
                <h2 class="mb-0 que-list">Question List</h2>
                <div class="ques_status">
                  <div class="col-sm-8 col-sm-4 mx-auto center-status">
                    <div class="row">
                      <div class="col-sm-6 ques_status_item">
                        <h4><span class="mx-2">Answered Questions</span></h4>
                        <button class="badge-answered"></button>
                      </div>
                      <div class="col-sm-6 ques_status_item">
                        <h4><span class="mx-2">Questions not Answered</span></h4>
                        <button class=" badge-unanswered">
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="qlistfont"><span><a href="javascript:void(0)" (click)="goBack()" class="return-link"><strong>&lt;&lt; Go Back</strong></a></span></div>
            </div>
            <div class=" card-body">             
                <div class="col-sm-12" *ngFor="let question of questions">
                  <div class="each-que">
                    <div class="row">
                      <div class="col-sm-12 col-md-12 col-lg-2 mx-0 h3 que-box" [ngClass]="{'badge-answered': question.answered,'badge-unanswered': !question.answered }"><!-- remove border-left  border-top-->
                          <a class="colorblack box-link" (click)="gotoquestion(scheduleid,question.questionno)">
                            <!-- routerLink="/exam/question" [queryParams]="{scheduleid:scheduleid,questionno:question.questionno}" -->
                              <button class="qdivs" style="text-align: center; border-radius: 0% !important;"
                                  [ngClass]="{'badge-answered': question.answered,'badge-unanswered': !question.answered }">
                                  <!-- remove p-0 -->
                                  <!-- <i class="ni"
                                  [ngClass]="{'ni-tag': question.answered,'ni-bell-55': !question.answered }"></i> -->
                                  Question <br /> {{question.questionno}}
                              </button></a>
                      </div>
                      <div class="col-sm-12 col-md-12 col-lg-10 m-0 pt-2 h2 qlistfont question-content" style="max-width: 100% !important;"><!-- remove border-right  border-top -->
                          <a class="colorblack" (click)="gotoquestion(scheduleid,question.questionno)">
                              <div *ngIf="this.examination && this.examination.displayquestiontextonquestionlist" class="qdivs" [innerHTML]="question.questiondetails.questionhtml"></div>
                          </a>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- <div class="timeline-block">
                        <span class="timeline-step badge-danger">
                            <i class="ni ni-html5"></i>
                        </span>
                        <div class="timeline-content">
                            <small class="text-muted font-weight-bold">10:30 AM</small>
                            <h5 class=" mt-3 mb-0">Product issue</h5>
                            <p class=" text-sm mt-1 mb-0">Nullam id dolor id nibh ultricies vehicula ut id elit. Cum
                                sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.</p>
                            <div class="mt-3">
                                <span class="badge badge-pill badge-danger">design</span>
                                <span class="badge badge-pill badge-danger">system</span>
                                <span class="badge badge-pill badge-danger">creative</span>
                            </div>
                        </div>
                    </div> -->
            </div>
        </div>
    </div>
<!-- </div> -->
