import { EventEmitter, Injectable, Output } from "@angular/core";
import { MeetingDomain, MeetingLite } from "../models/Meeting";
import { Participant } from "../models/Participant";
import { MeetingService } from "./meeting.service";
import { UtilService } from "./util.service";
import { MeetingSettings } from "../models/MeetingSettings";
//import { ParticipantVideoComponent } from "../components/participant-video/participant-video.component";
//import { ParticipantAudioComponent } from "../components/participant-audio/participant-audio.component";
import { Device } from "mediasoup-client";
import { Subject } from "rxjs";
import { ChatMessage } from "../models/ChatMessage";
import { ActivatedRoute } from "@angular/router";
import { environment } from "src/environments/environment";

declare const io: any;

@Injectable({
  providedIn: 'root'
})
export class MeetingHandlerService {
  numberOfConnectionAttemptsBeforeRequestingNewServer = 7;
  clientId: string;
  meetingid: string;
  meetinginfo: MeetingLite = new MeetingLite();
  domain: MeetingDomain = null;
  producerIdSocketIdMap: Map<string, string> = new Map<string, string>();
  participantsMap: Map<string, Participant> = new Map<string, Participant>();//this is a map of socketid -> participant
  roomParticipantsMap: Map<string, Set<string>> = new Map<string, Set<string>>();
  participantsUsernameMap: Map<string, Participant> = new Map<string, Participant>();
  breakoutRooms: Map<string, Set<string>> = new Map<string, Set<string>>();//this is a map of rooms -> Set<usernames> //this is the proposed breakout...before they actually breakout
  videoConsumersMap: Map<string, any> = new Map<string, any>();//the key is producerId
  audioConsumersMap: Map<string, any> = new Map<string, any>();//the key is producerId
  enabledConsumers: Set<string> = new Set<string>();
  meParticipant = new Participant();
  socket;
  mediasoupDevice;
  mediasoupProducerTransport;
  mediasoupConsumerTransport;
  //localMediasoupProducers = [];//using a producers array because of private producers
  //mediasoupSocketIds = {};
  localAudioTrack: MediaStreamTrack;
  localVideoTrack: MediaStreamTrack;
  //localVideoTrack: MediaStreamTrack;
  screenShareVideoTrack: MediaStreamTrack;
  screenShareAudioTrack: MediaStreamTrack;
  localuserinfo: any = {};
  audioAvailable: boolean = false;
  videoAvailable: boolean = false;
  screenShareAvailable: boolean = false;
  screenShareVideoProducerId: any;
  screenShareAudioProducerId: any;
  screenShareVideoConsumer: any;
  screenShareAudioConsumer: any;
  screenShareVideoParticipantProducerId: string;
  screenShareAudioParticipantProducerId: string;
  screenShareParticipantSocketId: string;
  screenShareParticipantUsername: string;
  screenShareParticipantName: string;
  participantScreenShareAvailable: boolean = false;
  leaving: boolean = false;
  selectedMicrophone;
  selectedCamera;
  production: boolean;
  ready: boolean = false;
  test: boolean = false;
  //i.e 50 plus the focused participant
  maxPlayingAudioParticipants: number = environment.production ? 10 : 1;
  //numberOfLoudestProducerPasses: number = 0;
  //loudestProducers: any[] = [];
  disablequietconsumersinterval: any;
  loudConsumers: Set<string> = new Set<string>();
  //domainIndex = 0;
  //usersIncremented: boolean;
  connecting = false;
  currentRoom: string = null;
  defaultRoom = 'Main';
  noopinterval: any;
  
  producerRequested: any = {};
  addedCurrentProducers: boolean = false;
  reconnecting = false;
  handraised = false;
  recorder = false;
  // modifyingScreenSharePublishing = false;
  // modifyingAudioPublishing = false;
  // modifyingVideoPublishing = false;

  screenPublishingLocker: object = new Object();
  audioPublishingLocker: object = new Object();
  videoPublishLocker: object = new Object();
  
  //recording: boolean = false;
  
  //chatopen = false;
  jwt: string = '';
  // participantVideoComponentsMap: Map<string, ParticipantVideoComponent> = new Map<string, ParticipantVideoComponent>();
  // participantAudioComponentsMap: Map<string, ParticipantAudioComponent> = new Map<string, ParticipantAudioComponent>();
  chatmessages: ChatMessage[] = [];
  //don't forget to destroy these in the cleanup() method...might change it to destroy()

  audioPublishedSubject: Subject<any> = new Subject();
  audioStoppedSubject: Subject<any> = new Subject();
  videoPublishedSubject: Subject<any> = new Subject();
  videoStoppedSubject: Subject<any> = new Subject();
  participantRemovedSubject: Subject<string> = new Subject();
  participantBeingRemovedSubject: Subject<string> = new Subject();
  participantProducerRemovedSubject: Subject<any> = new Subject();
  chatAddedSubject: Subject<any> = new Subject();
  screenSharePublishedSubject: Subject<any> = new Subject();
  participantAddedSubject: Subject<Participant> = new Subject();
  screenShareVideoProducerAddedSubject: Subject<any> = new Subject();
  screenShareAudioProducerAddedSubject: Subject<any> = new Subject();
  meetingEndedSubject: Subject<any> = new Subject();
  producerTypeUnavailableSubject: Subject<any> = new Subject();
  takeAttendanceSubject: Subject<any> = new Subject();
  reconnectingSubject: Subject<any> = new Subject();
  hideReconnectingModalSubject: Subject<any> = new Subject();
  //toastrWarning: Subject<string> = new Subject();
  //toastrSuccess: Subject<string> = new Subject();
  notifySubject: Subject<any> = new Subject();
  //toastrError: Subject<string> = new Subject();
  participantHandRaisedSubject: Subject<Participant> = new Subject();
  connectedSubject: Subject<boolean> = new Subject();
  handDroppedSubject: Subject<any> = new Subject();
  //remoteProducerPausedSubject: Subject<any> = new Subject();
  //remoteProducerResumedSubject: Subject<any> = new Subject();
  videoConsumerRemovedSubject: Subject<string> = new Subject();
  audioConsumerRemovedSubject: Subject<string> = new Subject();
  screenShareVideoConsumerRemovedSubject: Subject<string> = new Subject();
  screenShareAudioConsumerRemovedSubject: Subject<string> = new Subject();
  remoteVideoProducerAddedSubject: Subject<{socketid: string, producerid: string, kind: string, private: boolean}> = new Subject();
  remoteAudioProducerAddedSubject: Subject<{socketid: string, producerid: string, kind: string, private: boolean}> = new Subject();
  enteredRoomSubject: Subject<void> = new Subject();
  customMessageSubject: Subject<{ messageId: string, socketId: string, message: any, private: boolean }> = new Subject();
  disconnectedSubject: Subject<void> = new Subject();
  peerEnteredRoomSubject: Subject<{room: string, username: string}> = new Subject();
  allParticipantsRemovedSubject: Subject<void> = new Subject();
  clientInstanceId = '';
  initialised = false;
  createProducerTransportPromise: Promise<void>;
  createConsumerTransportPromise: Promise<void>;
  stopConnecting = false;
  domainConnectionAttempts = 0;
  static previewAudioLevelId = '__preview__';
  audioProducer: any = null;
  videoProducer: any = null;
  screenShareVideoProducer: any = null;
  screenShareAudioProducer: any = null;
  //static microphonedisabledbyhost: string = 'microphonedisabledbyhost';

  constructor(private meetingservice: MeetingService, private util: UtilService, private settings: MeetingSettings, private route: ActivatedRoute) { 
    this.meParticipant.me = true;
    this.production = environment.production && !route.snapshot.queryParams.debug;
    this.breakoutRooms.set(this.defaultRoom, new Set<string>());
    this.maxPlayingAudioParticipants = this.production ? 10 : 1;
    this.generateClientInstanceId();
    //Some of these should also have a corresponding entry in destroy()
  }

  private generateClientInstanceId(){
    this.clientInstanceId = (Math.random() + 1).toString(36).substring(7);
    console.log(`generated new clientInstanceId: ${this.clientInstanceId}`);
  }

  public async getMeetingServer(){
    if(this.domain){
      return this.domain;
    }
    else{
      const domain = await this.meetingservice.requestMeetingServer(this.meetingid, this.jwt, this.clientInstanceId, false).toPromise();
      this.domain = domain;
      return domain;
    }
  }

  private async connectToMeeting(room: string = null, participantData: any = null) {
    //TODO: here, we should check if the meeting has ended
    console.log('attempting connect to meeting');
    //debugger;
    //TODO: this method should really be happening from the page where the user clicks "Enter Meeting"
    this.handraised = false;
    this.ready = false;
    //const mythis = this;
    await this.disconnect();
    if (this.socket) {
      this.reconnecting = true;
      //this.socket.close();
      this.socket = null;
    }

    try{
      //we need to refresh the list before making another attempt to pick a server and connect
      this.domain = await this.getMeetingServer();

      if(!this.domain) {
        //this.meetingservice.setLookingForServer(this.meetingid, this.jwt);//notify the system that a user is in need of a server. no real need to await it
        return false;
      }
      else{
    //}
    
        await this.subscribeToMediasoupRoom(room, participantData);
        //await this.meetingservice.decrementLookingForServer(this.meetingid, this.jwt).toPromise();//do i need this if i'm just maintaining a user count? that should be sufficient to ensure we have enough servers
        //once we are able to find a server, set it on redis that the user has found a server
        //break;

        

        return true;
      }
    }
    catch(error){
      console.error(error);
      //await this.meetingservice.incrementLookingForServer(this.meetingid, this.jwt).toPromise();
      //if we are not able to find a server, set it on redis that there's a user looking for a server
    }

    return false;
  }

  async connectToMeetingUntilSuccessful(first = false, room: string = null, participantData: any = null){
    this.stopConnecting = false;
    let connected = false;
    console.log('connect to meeting until successful called');
    if(!this.connecting){//if we're already connecting, this means that this method is being called from somewhere else...if that's the case, we don't want to run the connection loop again
      
      this.connecting = true;

      //we are going to increment the user count for this meeting on redis...
      //this will be used by the fleet manager to determine how many people are trying to connect, and hence, it will know how many servers we need...
      //if we don't have enough servers, it will need to create more
      // if(!this.usersIncremented){
      //   this.meetingservice.incrementUsers(this.meetingid, this.jwt).toPromise();//no need to await  
      //   this.usersIncremented = true;
      // }
      //let domainConnectionAttempts = 0;
      while(true)
      {
        if(this.stopConnecting){
          this.stopConnecting = false;
          break;
        }
        try{
          connected = await this.connectToMeeting(room, participantData);
          if(connected){
            this.meParticipant.data = participantData;
            this.connectedSubject.next(first);
            
            break;
          }
        }
        catch(ex){
          console.log(ex);
        }
        
        await this.util.sleep(10 * 1000);//every 10 seconds
        if(this.domain && this.domain.domain){
          this.domainConnectionAttempts++;
          if(this.domainConnectionAttempts % this.numberOfConnectionAttemptsBeforeRequestingNewServer == 0){
            //after a certain number of unsuccessful attempts to connect to the assigned server, we should ask for a new server by reseting the client instance id...this will make it seem like its a new instance
            this.domain = null;
            this.generateClientInstanceId();
          }
        }
      }
      this.connecting = false;
    }
    return connected;
  }

  async publishMedia() {
    await Promise.all([this.publishVideo(true), this.publishAudio(true)]);
  }

  async destroy(){
    
    //console.log('destroying handler service');
    this.leaving = true;
    await this.disconnect();

    // if(this.usersIncremented){
    //   this.meetingservice.decrementUsers(this.meetingid, this.jwt).toPromise();//no need to await
    // }

    

    if(this.noopinterval){
      clearInterval(this.noopinterval);
    }

    if(this.disablequietconsumersinterval){
      clearInterval(this.disablequietconsumersinterval);
    }

    [this.localAudioTrack, this.localVideoTrack, this.screenShareVideoTrack/*, this.localVideoTrack*/].forEach((track) => {
      if(track){
        track.stop();
      }
    });

    this.clientId = null;
    this.meetingid = null;
    this.meetinginfo = new MeetingLite();
    this.domain = null;
    this.producerIdSocketIdMap = new Map<string, string>();
    this.participantsMap = new Map<string, Participant>();//this is a map of socketid -> participant
    this.roomParticipantsMap = new Map<string, Set<string>>();
    this.participantsUsernameMap = new Map<string, Participant>();
    this.breakoutRooms = new Map<string, Set<string>>();//this is a map of rooms -> Set<usernames> //this is the proposed breakout...before they actually breakout
    this.breakoutRooms.set(this.defaultRoom, new Set<string>());
    this.videoConsumersMap = new Map<string, any>();//the key is producerId
    this.audioConsumersMap = new Map<string, any>();//the key is producerId
    this.enabledConsumers = new Set<string>();
    this.meParticipant = new Participant();
    this.meParticipant.me = true;
    this.socket = null;
    this.mediasoupDevice = null;
    this.mediasoupProducerTransport = null;
    this.mediasoupConsumerTransport = null;
    //this.localMediasoupProducers = [];//using a producers array because of private producers
    //this.mediasoupSocketIds = {};
    this.localAudioTrack = null;
    this.localVideoTrack = null;
    //localVideoTrack: MediaStreamTrack;
    this.screenShareVideoTrack = null;
    this.localuserinfo = {};
    this.audioAvailable = false;
    this.videoAvailable = false;
    this.screenShareAvailable = false;
    this.screenShareVideoProducerId = null;
    this.screenShareAudioProducerId = null;
    this.screenShareVideoConsumer = null;
    this.screenShareAudioConsumer = null;
    this.screenShareVideoParticipantProducerId = null;
    this.screenShareAudioParticipantProducerId = null;
    this.screenShareParticipantSocketId = null;
    this.screenShareParticipantUsername = null;
    this.screenShareParticipantName = null;
    this.participantScreenShareAvailable = false;
    this.leaving = false;
    this.selectedMicrophone = null;
    this.selectedCamera = null;
    this.production = false;
    this.ready = false;
    this.test = false;
    // this.participantsPerShowMore = 20;
    // this.maxDisplayedParticipants = 51;//i.e 50 plus the focused participant
    
    //this.numberOfLoudestProducerPasses = 0;
    //this.loudestProducers = [];
    this.disablequietconsumersinterval = null;
    this.loudConsumers = new Set<string>();
    //this.domainIndex = 0;
    //this.usersIncremented = false;
    this.connecting = false;
    this.currentRoom = null;
    this.defaultRoom = 'Main';
    this.noopinterval = null;
    //this.setorderinterval = null;
    this.producerRequested = {};
    this.addedCurrentProducers = false;
    this.reconnecting = false;
    this.handraised = false;
    this.recorder = false;
    // this.confirmModalNoCallback = null;
    // this.confirmModalYesCallback = null;
    // this.confirmModalCaption = 'Confirm';
    // this.confirmModalBody = 'Are you sure?';
    // this.confirmModalYesButtonStyle = 'btn-primary';
    // this.confirmModalNoButtonStyle = 'btn-secondary';
    //this.recording = false;
    //this.chatopen = false;
    this.jwt = '';
    // this.participantVideoComponentsMap = new Map<string, ParticipantVideoComponent>();
    // this.participantAudioComponentsMap = new Map<string, ParticipantAudioComponent>();
    this.chatmessages = [];

    this.audioPublishedSubject = new Subject();
    this.audioStoppedSubject = new Subject();
    this.videoPublishedSubject = new Subject();
    this.videoStoppedSubject = new Subject();
    this.participantRemovedSubject = new Subject();
    this.participantBeingRemovedSubject = new Subject();
    this.participantProducerRemovedSubject = new Subject();
    this.chatAddedSubject = new Subject();
    this.screenSharePublishedSubject = new Subject();
    this.participantAddedSubject = new Subject();
    this.screenShareVideoProducerAddedSubject = new Subject();
    this.screenShareAudioProducerAddedSubject = new Subject();
    this.meetingEndedSubject = new Subject();
    this.producerTypeUnavailableSubject = new Subject();
    this.takeAttendanceSubject = new Subject();
    this.reconnectingSubject = new Subject();
    this.hideReconnectingModalSubject = new Subject();
    //this.toastrWarning = new Subject();
    //this.toastrSuccess = new Subject();
    //this.toastrError = new Subject();
    this.participantHandRaisedSubject = new Subject();
    this.connectedSubject = new Subject();
    this.handDroppedSubject = new Subject();
    this.videoConsumerRemovedSubject = new Subject();
    this.audioConsumerRemovedSubject = new Subject();
    this.screenShareVideoConsumerRemovedSubject = new Subject();
    this.screenShareAudioConsumerRemovedSubject = new Subject();
    this.remoteVideoProducerAddedSubject = new Subject();
    this.remoteAudioProducerAddedSubject = new Subject();
    this.notifySubject = new Subject();
    this.enteredRoomSubject = new Subject();
    this.customMessageSubject = new Subject();
    this.disconnectedSubject = new Subject();
    this.peerEnteredRoomSubject = new Subject();
    this.allParticipantsRemovedSubject = new Subject();
    this.initialised = false;
    this.createProducerTransportPromise = null;
    this.createConsumerTransportPromise = null;
    this.stopConnecting = false;
    this.audioProducer  = null;
    this.videoProducer = null;
    this.screenShareVideoProducer = null;
    this.screenShareAudioProducer = null;
  }

  async init(){
    //debugger;
    this.meetinginfo = await this.meetingservice.getMeeting(this.meetingid).toPromise();

    this.localuserinfo = await this.meetingservice.decodeJWT(this.meetingid, this.jwt).toPromise();

    this.meParticipant.host = this.localuserinfo.host;

    if(!this.localuserinfo.host && this.meetinginfo.startParticipantsMuted){
      this.settings.enableMicrophone = false;
      //this.toastrWarning.next('Your microphone has been disabled by the host');
      this.notifySubject.next({messageid: NotificationMessages.microphonedisabledbyhost});
    }

    this.settings.host = this.localuserinfo.host;

    this.meParticipant.name = this.localuserinfo.name;// + ' (Me)';
    this.meParticipant.username = this.localuserinfo.username;
    this.meParticipant.photourl = this.localuserinfo.photourl;

    this.initialised = true;
    //await this.connectToMeeting();
  }

  async stopAudio(){
    //we want to be able to stop video while publishVideo is in flight, to allow the user stop the video instantly. 
    //publishVideo should check if video is still enabled by the time it finishes. 
    //if it isn't, it should close the producer it just created
    try{
      await Locker.lock(this.audioPublishingLocker);
      const producer = this.audioProducer
      if(producer){
        this.pauseProducer(producer, false);
      }
      
      //putting this here to ensure that the value is set so that controls work properly even if there was no producer or no track set
      this.audioAvailable = false;

      this.audioStoppedSubject.next();
    }
    finally{
      Locker.unlock(this.audioPublishingLocker);
    }
  }

  async publishAudio(auto: boolean = false){
    if(!(this.meetinginfo.mode == this.util.meetingmode || this.localuserinfo.host || this.meParticipant.unlocked)){
      if(!auto){
        this.notifySubject.next({messageid: NotificationMessages.notyetenabledtospeak});
      }
      else{
        this.notifySubject.next({messageid: NotificationMessages.microphonedisabledbyhost});
      }
      return false;
    }

    if(this.settings.enableMicrophone){

      
      try{
        await Locker.lock(this.audioPublishingLocker);

        let newpublish = false;
        if((this.selectedMicrophone && this.selectedMicrophone != this.settings.selectedMicrophone && this.localAudioTrack)){
          const producer = this.audioProducer;
          if(producer){
            this.closeProducer(producer);
          }
          newpublish = true;
        }

        //let currentAudioProducer;
        if(!this.localAudioTrack){
          newpublish = true;
        }
        else{
          if(!this.audioProducer || this.audioProducer.closed){
            newpublish = true;
          }
        }

        this.selectedMicrophone = this.settings.selectedMicrophone;
        if(newpublish){

          this.localAudioTrack = (await navigator.mediaDevices.getUserMedia({audio: this.settings.getAudioConstraints()})).getAudioTracks()[0];
          
          await this.publishToMediasoupRoom(this.localAudioTrack);
        }
        else{
          await this.resumeProducer(this.audioProducer);
        }
        
        // this.audioTrack.addEventListener('ended', () => {
        //   this.audioAvailable = false;
        //   const producer = this.findProducerByTrack(this.audioTrack);
        //   if(producer){
        //     this.closeProducer(producer);
        //   }
        // });
        
        this.audioAvailable = true;
        this.meParticipant.audioAvailable = true;

        this.audioPublishedSubject.next(this.localAudioTrack);

        return true;
      }
      catch(error){
        console.error(error);
        this.stopAudio();//we can't await here because if we do, it will never return. if we fire it here, it will happen after the locker is unlocked
        this.notifySubject.next({messageid: NotificationMessages.errorGettingAudioStream});
        return false;
      }
      finally{
        Locker.unlock(this.audioPublishingLocker);
      }
    }
    return false;

  }

  async stopVideo(){
    try{
      //we want to be able to stop video while publishVideo is in flight, to allow the user stop the video instantly. 
      //publishVideo should check if video is still enabled by the time it finishes. 
      //if it isn't, it should close the producer it just created
      await Locker.lock(this.videoPublishLocker);

      if(this.localVideoTrack){
        if(this.videoProducer){
          this.closeProducer(this.videoProducer);
        }
        else{
          this.localVideoTrack.stop();
        }
      }
      //putting this here to ensure that the value is set so that controls work properly even if there was no producer or no track set
      this.videoAvailable = false;

      this.videoStoppedSubject.next();
    }
    finally{
      Locker.unlock(this.videoPublishLocker);
    }
    // this.videoTrack.stop();
    // this.videoAvailable = false;
  }

  async publishVideo(auto: boolean){
    if(!(this.meetinginfo.mode == this.util.meetingmode || this.localuserinfo.host || this.meParticipant.unlocked)){
      if(!auto){
        this.notifySubject.next({messageid: NotificationMessages.notyetenabledtospeak});
      }
      else{
        this.notifySubject.next({messageid: NotificationMessages.webcamdisabledbyhost});
      }
      return false;
    }

    

    if(this.settings.enableCamera){
      
      try{
        await Locker.lock(this.videoPublishLocker);

        if(this.localVideoTrack){
          try{
            this.localVideoTrack.stop();
          }catch(error){
            console.log(error);
          }
        }

        this.localVideoTrack = (await navigator.mediaDevices.getUserMedia({video: this.settings.getVideoConstraints()})).getVideoTracks()[0];
        //this.localVideoTrack = (await navigator.mediaDevices.getUserMedia({video: this.settings.getVideoConstraints()})).getVideoTracks()[0];

        this.localVideoTrack.addEventListener('ended', () => {
          console.log('video track ended');
        });

        this.selectedCamera = this.settings.selectedCamera;
        // this.videoTrack.addEventListener('ended', () => {
        //   this.videoAvailable = false;
        //   const producer = this.findProducerByTrack(this.videoTrack);
        //   if(producer){
        //     this.closeProducer(producer);
        //   }
        // });
        await this.publishToMediasoupRoom(this.localVideoTrack);
        this.videoAvailable = true;
        this.meParticipant.videoAvailable = true;

        this.videoPublishedSubject.next(this.localVideoTrack);

        return true;
      }
      catch(error){
        console.error(error);
        this.stopVideo();//we can't await here because if we do, it will never return. if we fire it here, it will happen after the locker is unlocked
        this.notifySubject.next({messageid: NotificationMessages.errorGettingVideoStream});
        return false;
      }
      finally{
        Locker.unlock(this.videoPublishLocker);
      }
    }
    else{
      return false;
    }
  }

  async stopScreenShare(){
    try{
      await Locker.lock(this.screenPublishingLocker);

      this.closeProducer(this.screenShareVideoProducer);

      if(this.screenShareAudioProducer){
        this.closeProducer(this.screenShareAudioProducer);
      }
    }
    finally{
      Locker.unlock(this.screenPublishingLocker);
    }
    
  }

  async publishScreenShare(){
    if(!(this.meetinginfo.mode == this.util.meetingmode || this.localuserinfo.host || this.meParticipant.unlocked)){
      return false;
    }

    try{
      await Locker.lock(this.screenPublishingLocker);

      if(this.screenShareVideoTrack){
        try{
          this.screenShareVideoTrack.stop();
        }catch(error){
          console.log(error);
        }
      }

      if(this.screenShareAudioTrack){
        try{
          this.screenShareAudioTrack.stop();
        }catch(error){
          console.log(error);
        }
      }

      const mediadevices: any = navigator.mediaDevices;

      const screenShareStream = await mediadevices.getDisplayMedia({ video: this.settings.getScreenShareVideoConstraints(), audio: true}); 
      this.screenShareVideoTrack = screenShareStream.getVideoTracks()[0];
      this.screenShareAudioTrack = screenShareStream.getAudioTracks()[0];

      const producer = await this.publishToMediasoupRoom(this.screenShareVideoTrack, true);

      if(producer){
        this.screenShareVideoProducerId = producer.id;
        
        const producers = [];
        producers.push(producer);

        if(this.screenShareAudioTrack){
          const screenShareAudioProducer = await this.publishToMediasoupRoom(this.screenShareAudioTrack, true);
          if(screenShareAudioProducer){
            this.screenShareAudioProducerId = screenShareAudioProducer.id;

            producers.push(screenShareAudioProducer);
          }
          //this.localScreenShareAudioElement.play();
        }

        this.screenShareAvailable = true;

        this.screenSharePublishedSubject.next(producers);

        

        return true;
      }
      else{
        this.screenShareVideoTrack.stop();
        if(this.screenShareAudioTrack){
          this.screenShareAudioTrack.stop();
        }
        return false;
      }
    }
    catch(error){
      console.error(error);
      debugger;
      this.stopScreenShare();//we can't await here. see publishAudio and publishVideo for explanation
      return false;
    }
    finally{
      Locker.unlock(this.screenPublishingLocker);
    }
  }

  // findProducerByTrack(track: MediaStreamTrack){
  //   for(let i = 0; i < this.localMediasoupProducers.length; i++){
  //     if(this.localMediasoupProducers[i].track.id == track.id){
  //       return this.localMediasoupProducers[i];
  //     }
  //   }
  //   return null;
  // }

  async closeProducerTransport(){
    //this.mediasoupProducerTransport.off('connectionstatechange');
    this.mediasoupProducerTransport.close();
    if(this.isMediasoupControlSocketConnected()){
      await this.sendMediasoupControlRequest('closeProducerTransport', { socketId: this.socket.id });
    }
    
  }

  async closeConsumerTransport(){
    //this.mediasoupConsumerTransport.off('connectionstatechange');
    this.mediasoupConsumerTransport.close();
    if(this.isMediasoupControlSocketConnected()){
      await this.sendMediasoupControlRequest('closeConsumerTransport', { socketId: this.socket.id });
    }
  }

  async disconnect() {
    console.log('disconnect called');

    this.ready = false;
   
    if (this.mediasoupProducerTransport && !this.mediasoupProducerTransport.closed) {
      await this.closeProducerTransport();
    }

    if (this.mediasoupConsumerTransport && !this.mediasoupConsumerTransport.closed) {
      await this.closeConsumerTransport();
    }

    this.mediasoupProducerTransport = null;
    this.mediasoupConsumerTransport = null;

    //this.participantsMap = new Map<string, Participant>();
    //this.participantsArray = [];
    //this.localMediasoupProducers = [];

    if(this.socket && this.socket.connected){
      console.log('socket.close called');
      //this.socket.close();
      this.closeSocket();
    }

    this.disconnectedSubject.next();
    //this.mediasoupConsumers = {};
  }

  closeSocket(){
    this.socket.off('disconnect');
    this.socket.close();
  }

  // get selectedDomain() : MeetingDomain{
  //   if(this.meetinginfo.domains.length > this.domainIndex && this.domainIndex >= 0){
  //     return this.meetinginfo.domains[this.domainIndex];
  //   }
  //   else{
  //     return null;
  //   }
  // }


  sendMediasoupControlRequest(type: string, data: any) {
    const socket = this.socket;
    return new Promise((resolve, reject) => {
      socket.emit(type, data, (err, response) => {
        if (!err) {
          // Success response, so pass the mediasoup response to the local Room.
          resolve(response);
        } else {
          console.error('error received as emit response: ', {type, data, err});
          reject(err);
        }
      });
    });
  }

  sendCustomMessage(messageId: string, data: any){
    return this.sendMediasoupControlRequest('customMessage', {messageId: messageId, message: data});
  }

  sendCustomPrivateMessage(messageId: string, remoteId: string, data: any){
    return this.sendMediasoupControlRequest('customPrivateMessage', {messageId: messageId, remoteId: remoteId, message: data});
  }

  addParticipant(socketid, username, name, host, photourl, data): Participant {
    const participant: Participant = new Participant();
    participant.username = username;
    participant.name = name;
    participant.host = host;
    participant.photourl = photourl;
    participant.socketid = socketid;
    

    if(data){
      participant.data = JSON.parse(data);
    }

    if(!this.participantsMap.has(socketid))
    {
      //this.participantsArray.push(participant);
      this.participantsMap.set(socketid, participant);
      this.participantsUsernameMap.set(participant.username, participant);
      
      this.participantAddedSubject.next(participant);
    }

    return participant;
  }

  async addParticipantProducer(socketId: string, producerId: string, kind: string, screenShare: boolean, privateProducer: boolean) {
    //if(this.test){ return; }//tbhis was used to test proctoring scenario where users are broadcasting but not consuming

    let participant = this.findParticipant(socketId);
    if (!participant) {
      
      // participant = new Participant();
      // participant.socketid = socketId;
      // //proctee.muted = true;
      // participant.username = '';
      // participant.name = '';
      
      //this.participants.set(socketId, participant);
      const { username, name, host, photourl, data } = await this.getMediasoupSocketIdUserDetails(socketId);
      
      participant = this.addParticipant(socketId, username, name, host, photourl, data);
      
      // participant.username = username;
      // participant.name = name;
      // participant.host = host;
      // participant.photourl = photourl;
    }
    
    if (kind === 'video') {
      if(screenShare){

        this.screenShareVideoParticipantProducerId = producerId;
        this.screenShareParticipantSocketId = socketId;
        this.screenShareParticipantUsername = participant.username;
        this.screenShareParticipantName = participant.name;
        this.participantScreenShareAvailable = true;
        this.screenShareVideoProducerAddedSubject.next({socketid: socketId, producerid: producerId, kind: kind, private: privateProducer});
      }
      else {
        // if(!participant.videoConsumer || participant.videoConsumer.producerId != producerId)
        // {
          participant.videoProducerId = producerId;

          this.remoteVideoProducerAddedSubject.next({socketid: socketId, producerid: producerId, kind: kind, private: privateProducer});
          // participant.videoConsumer = await this.createConsumer(participant.socketid, participant.videoProducerId, kind);
          // participant.videoAvailable = true;
        //}
        /*const participantVideoComponent = this.getParticipantVideoComponent(socketId);
        if(participantVideoComponent){
          await participantVideoComponent.setStream();
        }*/
      }
    } 
    else {
      if(screenShare){
        //debugger;
        this.screenShareAudioParticipantProducerId = producerId;

        this.screenShareAudioProducerAddedSubject.next({socketid: socketId, producerid: producerId, kind: kind, private: privateProducer});
      }
      else{
      // if(!participant.audioConsumer || participant.audioConsumer.producerId != producerId)
      // {
        participant.audioProducerId = producerId;

        this.remoteAudioProducerAddedSubject.next({socketid: socketId, producerid: producerId, kind: kind, private: privateProducer});
        // participant.audioConsumer = await this.createConsumer(participant.socketid, participant.audioProducerId, kind);
        // participant.audioAvailable = true;
      // }
      }
    }
    
    this.producerIdSocketIdMap.set(producerId, socketId);
    //this.ref.detectChanges();
    
  }

  getVideoConsumer(producerId: string){
    if(this.videoConsumersMap.has(producerId)){
      const consumer = this.videoConsumersMap.get(producerId);
      return consumer;
    }
    else{
      return null;
    }
  }

  getAudioConsumer(producerId: string){
    if(this.audioConsumersMap.has(producerId)){
      const consumer = this.audioConsumersMap.get(producerId);
      return consumer;
    }
    else{
      return null;
    }
  }

  async removeParticipant(socketId){
    const participant = this.participantsMap.get(socketId);
    if(participant){
      //const participantVideoComponent = this.getParticipantVideoComponent(socketId);
      const videoConsumer = this.getVideoConsumer(participant.videoProducerId);
      if(participant && videoConsumer && !videoConsumer.closed){
        //this.closeConsumer(participant.socketid, videoConsumer, false);
        this.removeParticipantProducer(socketId, videoConsumer.producerId, 'video');
      }

      const audioConsumer = this.getAudioConsumer(participant.audioProducerId);
      if(participant && audioConsumer && !audioConsumer.closed){
        //this.closeConsumer(participant.socketid, audioConsumer, false);
        this.removeParticipantProducer(socketId, audioConsumer.producerId, 'audio');
      }

      if(this.participantScreenShareAvailable && this.screenShareParticipantSocketId == socketId){
        if(this.screenShareVideoConsumer && !this.screenShareVideoConsumer.closed){
          //this.closeConsumer(participant.socketid, this.screenShareVideoConsumer, false);
          this.removeParticipantProducer(socketId, this.screenShareVideoConsumer.producerId, 'video');
        }
        if(this.screenShareAudioConsumer && !this.screenShareAudioConsumer.closed){
          //this.closeConsumer(participant.socketid, this.screenShareAudioConsumer, false);
          this.removeParticipantProducer(socketId, this.screenShareAudioConsumer.producerId, 'audio');
        }
      }
      //if (!participant.videoConsumer && !participant.audioConsumer) {

      this.participantBeingRemovedSubject.next(socketId);

      this.participantsMap.delete(socketId);

      this.participantRemovedSubject.next(socketId);
    }
      //we can't remove from the participants array because it will affect the indexes stored in participantsMap
      // for(let i = 0; i < this.participantsArray.length; i++){
      //   if(this.participantsArray[i].socketid == socketId){
      //     this.participantsArray.splice(i, 1);
      //     i--;
      //   }
      // }
      // this.setPages();
      // this.goToPage(this.participantsPageNo);
    //}
  }

  async removeParticipantProducer(socketId, producerId, kind) {
    
    if(socketId == this.socket.id){
      const producers = [this.audioProducer, this.videoProducer, this.screenShareVideoProducer, this.screenShareAudioProducer];
      for(let i = 0; i < producers.length; i++){
        try{
            if(producers[i] && producers[i].id == producerId){
              this.closeProducerLocally(producers[i]);
          }
        }
        catch(error){
          console.error(error);
        }
      }
    }
    else{
      
      if (this.participantsMap.has(socketId)) {
        //const participantindex = this.participantsMap.get(socketId);
        const participant = this.participantsMap.get(socketId);//[participantindex];
       if (participant.videoProducerId === producerId/* && participant.videoConsumer*/) {

          if(this.videoConsumersMap.has(participant.videoProducerId)){

            const consumer = this.videoConsumersMap.get(participant.videoProducerId);
            this.closeConsumer(socketId, consumer, false);

            this.videoConsumersMap.delete(participant.videoProducerId);
          }

          
          participant.videoProducerId = null;

          this.videoConsumerRemovedSubject.next(socketId);

          
          // participant.videoConsumer = null;
          // participant.videoAvailable = false;
        }
        else if (participant.audioProducerId === producerId/* && participant.audioConsumer*/) {

          if(this.audioConsumersMap.has(participant.audioProducerId)){
            const consumer = this.audioConsumersMap.get(participant.audioProducerId);
            this.closeConsumer(socketId, consumer, false);

            this.audioConsumersMap.delete(participant.audioProducerId);
          }
          // this.closeConsumer(socketId, participant.audioConsumer, false);
          participant.audioProducerId = null;
          
          this.audioConsumerRemovedSubject.next(socketId);
          // participant.audioConsumer = null;
          // participant.audioAvailable = false;
        }
        else if(this.screenShareVideoParticipantProducerId == producerId && this.screenShareVideoConsumer){
          this.closeConsumer(socketId, this.screenShareVideoConsumer, false);
          
          this.screenShareVideoParticipantProducerId = null;
          this.screenShareVideoConsumer = null
          this.screenShareParticipantSocketId = null;
          this.screenShareParticipantUsername = null;
          this.screenShareParticipantName = null
          this.participantScreenShareAvailable = false;

          if(this.screenShareAudioConsumer){
            this.closeConsumer(socketId, this.screenShareAudioConsumer, false);
            this.screenShareAudioParticipantProducerId = null;
            this.screenShareAudioConsumer = null;
          }

          this.screenShareVideoConsumerRemovedSubject.next(socketId);
          this.screenShareAudioConsumerRemovedSubject.next(socketId);
        }
        
      }
    }

    if(this.producerIdSocketIdMap.has(producerId)){
      this.producerIdSocketIdMap.delete(producerId);
    }

    this.participantProducerRemovedSubject.next({socketId, producerId, kind})
  }

  async loadMediasoupDevice(routerRtpCapabilities) {
    try {
      this.mediasoupDevice = new Device();
    } catch (error) {
      if (error.name === 'UnsupportedError') {
        console.error('browser not supported');
      }
    }
    await this.mediasoupDevice.load({ routerRtpCapabilities });
  }

  isMediasoupControlSocketConnected() {
    if (this.socket && this.socket.connected) {
      return true;
    }
    else {
      return false;
    }
  }

  async publishToMediasoupRoom(track: MediaStreamTrack, screenShare: boolean = false, recepientSocketId?: string, simulcast = true) {
    if(!track){
      return;
    }

    if(!this.createProducerTransportPromise){
      this.createProducerTransportPromise = this.createProducerTransport();
    }

    try{
      this.mediasoupProducerTransport = await this.createProducerTransportPromise;
    }
    catch(error){
      console.error('error creating producer transport');
      console.error(error);
      throw error;
    }
    
    
    const trackParams = { track: track, appData: { recepientSocketId, screenShare }, encodings: undefined, zeroRtpOnPause: true, disableTrackOnPause: false, stopTracks: false };
    if(track.kind == 'video' && simulcast){
      if(!screenShare){
        trackParams.encodings = [
          { maxBitrate: 125 * 1024/*, scaleResolutionDownBy: 2*/ },
          { maxBitrate: 350 * 1024 },
          { maxBitrate: 1000 * 1024 }
        ];
      }
      // else{
      //   trackParams.encodings = [
      //     // { maxBitrate: 1000 * 1024 },
      //     { maxBitrate: 2000 * 1024 },
      //     { maxBitrate: 4000 * 1024 }
      //   ];
      // }
      // trackParams.encodings = [
      //   // { maxBitrate: !screenShare ? 100000 : 500000, scaleResolutionDownBy: !screenShare ? 2 : 1.5},
      //   // { maxBitrate: !screenShare ? 300000 : 900000, scaleResolutionDownBy: !screenShare ? 1.5 : 1 },
      //   // { maxBitrate: !screenShare ? 900000 : 1800000, scaleResolutionDownBy: 1 }

      //   { maxBitrate: !screenShare ? 125 * 1024 : undefined, scaleResolutionDownBy: !screenShare ? 2 : 1.5},
      //   { scaleResolutionDownBy: !screenShare ? 1.5 : 1.25 },
      //   { scaleResolutionDownBy: 1 }
      // ];
    }

    

    const producer = await this.mediasoupProducerTransport.produce(trackParams);

    if(producer && producer.id)
    {
      // if(producer.kind == 'video' && !screenShare && !recepientSocketId){
      //   if(!this.producerRequested[producer.id]){
      //     producer.pause();
      //   }
      //   //tracks[0].enabled = false;
      // }

      if(producer.kind == 'audio'){
        //debugger;
        producer.observer.on('pause', () => {
          console.log('audio paused');
        });

        if(screenShare){
          this.screenShareAudioProducer = producer;
        }
        else{
          this.audioProducer = producer;
        }
      }
      else if(producer.kind == 'video'){
        if(screenShare){
          this.screenShareVideoProducer = producer;
        }
        else{
          this.videoProducer = producer;
        }
      }
      
      producer.track.addEventListener('ended', () => {
        this.closeProducer(producer);
      });
      
      //this.localMediasoupProducers.push(producer);
      console.log('Producer id: ' + producer.id);

      return producer;
    }
    else{
      return null;
    }
    
  }

  async subscribeToMediasoupRoom(room: string = null, participantData: any = null) {
    //let mediasoupConsumerTransport = this.mediasoupConsumerTransport;
    if (!this.isMediasoupControlSocketConnected()) {
      const socket = await this.connectMediasoupControlSocket(room, participantData);
      this.meParticipant.socketid = socket.id;
      if(!this.noopinterval){
        this.noopinterval = setInterval(() => {
          if(this.socket){
            this.sendMediasoupControlRequest('no-op', {});//to prevent timing out
          }
        }, 1000);
      }

      if(!socket){
        throw new Error('Couldn\'t find a server to connect to.');
      }

      this.socket = socket;
      const mythis = this;
      socket.on('connect', function (evt) {
        console.log('socket.io connected()');
      });
      

      socket.on('disconnect', async function (evt) {
        //debugger
        //if (!mythis.reconnecting) {
          console.log('socket.io disconnect:', evt);
          //publishVideo();
          //alert('Websocket disconnected');
          //document.location.reload();
          if(!mythis.leaving){
            console.log('not leaving yet...attempting to reconnect');
            try{
              mythis.meetinginfo = await mythis.meetingservice.getMeeting(mythis.meetingid).toPromise();//try to update the meeting so we know if the meeting has now ended...if we aren't able to update it, proceed as normal
            }
            catch(error){
              console.warn(error);
            }

            if(mythis.meetinginfo.ended){
              console.log('meeting has ended so will redirect');
              mythis.meetingEndedSubject.next(null);
            }
            else{
              //mythis.reconnecting2 = true;
              
              try{
                mythis.addedCurrentProducers = false;
                await mythis.disconnect();
                if(!mythis.leaving){
                  mythis.reconnectingSubject.next(null);
                  console.log('calling connect to meeting until successful from socket.on(\'disconnect\')');
                  await mythis.connectToMeetingUntilSuccessful(false, mythis.currentRoom, mythis.meParticipant.data);
                }
              }
              finally{
                mythis.hideReconnectingModalSubject.next(null);
              }
            }
          }
        //}
      });

      socket.io.on('error', function(err){
        console.error('socket.io manager ERROR:', err);
        // setTimeout(async function () {
        //   if (!mythis.leaving) {
        //     console.log('calling connect to meeting until successful from socket.on(\'error\')');
        //     await mythis.connectToMeetingUntilSuccessful();
        //   }
        //   //alert('Websocket error.');
        //   //document.location.reload();
        //   //publishVideo();
        // }, 1000);
      });
      
      socket.on('error', function (err) {
        //debugger
        console.error('socket ERROR:', err);
        setTimeout(async function () {
          if (!mythis.leaving) {
            console.log('calling connect to meeting until successful from socket.on(\'error\')');
            await mythis.connectToMeetingUntilSuccessful(false, mythis.currentRoom, mythis.meParticipant.data);
          }
          //alert('Websocket error.');
          //document.location.reload();
          //publishVideo();
        }, 1000);
      });

      socket.on('newPeer', async function(message){
        if(mythis.ready){
          if(message.socketid != socket.id){
            mythis.addParticipant(message.socketid, message.username, message.name, message.host, message.photourl, message.data);
            const currentProducersInfo = message.currentProducersInfo;
            for(let i = 0; i < currentProducersInfo.length; i++){
              const producerInfo: {producerId, kind, screenShare, private} = currentProducersInfo[i];
              await mythis.addParticipantProducer(message.socketid, producerInfo.producerId, producerInfo.kind, producerInfo.screenShare, producerInfo.private);
            }
          }
          else{
            mythis.currentRoom = message.room;
            //await mythis.removeAllParticipants();
            await mythis.startRoom();
          }
        }
      });

      // socket.on('roomChanged', async (message) => {
        
      //   //add all participants in your new room
      // });
      
      socket.on('newProducer', async function (message) {
        console.log('socket.io newProducer:', message);
        const remoteId = message.socketId;
        const prdId = message.producerId;
        const kind = message.kind;
        const screenShare = message.screenShare;
        const privateProducer = message.private;

        if(kind == 'video' && screenShare && mythis.screenShareAvailable){
          mythis.stopScreenShare();
        }
        //const {remoteId, prdId, kind, screenShare} = message;

        if(remoteId != socket.id){
          if(mythis.ready){
            await mythis.addParticipantProducer(remoteId, prdId, kind, screenShare, privateProducer);
          }
        }

      });

      socket.on('producerClosed', async function (message) {
        console.log('socket.io producerClosed:', message);
        //const localId = message.localId;
        const remoteId = message.remoteId;
        const kind = message.kind;
        //const consumerId = message.consumerId;
        const producerId = message.producerId;
        console.log('--try removeConsumer remoteId=%s, producerId=%s, track=%s', remoteId, producerId, kind);
        //mythis.removeMediasoupConsumer(remoteId, consumerId);
        //mythis.removeRemoteVideo(remoteId);
        //await mythis.removeProcteeProducer(remoteId, )
        await mythis.removeParticipantProducer(remoteId, producerId, kind);
      });

      socket.on('producerPaused', async (message) => {
        console.log('socket.io producerPaused:', message);
        if(socket.id == message.remoteId){
          let producerId = message.producerId;
          const producers = [this.audioProducer, this.videoProducer, this.screenShareVideoProducer, this.screenShareAudioProducer];
          for(let i = 0; i < producers.length; i++){
            if(producers[i] && producers[i].id == producerId){
              this.pauseProducerLocally(producers[i], message.manuallyPaused);
              // this.localMediasoupProducers[i].pause();
              // if(this.meParticipantAudioComponent.audioConsumer.id == this.localMediasoupProducers[i].id){
              //   // this.audioAvailable = false;
              //   // this.meParticipant.audioAvailable = false;
              //   this.setProducerTypeUnavailable()
              // }
            }
          }
        }
        else{
          const participant = this.findParticipant(message.remoteId);
          if(participant){
            const audioConsumer = this.getAudioConsumer(participant.audioProducerId);
            if(participant && (!audioConsumer || audioConsumer.producerId == message.producerId)){
              // participant.participantAudioComponent.audioConsumer.pause();
              participant.audioAvailable = false;
            }
          }
          //mythis.remoteProducerPausedSubject.next(message);
        }
      });

      socket.on('producerResumed', async (message) => {
        console.log('socket.io producerResumed:', message);
        if(socket.id == message.remoteId){
          let producerId = message.producerId;
          mythis.producerRequested[producerId] = true;
          const producers = [this.audioProducer, this.videoProducer, this.screenShareVideoProducer, this.screenShareAudioProducer];
          for(let i = 0; i < producers.length; i++){
            if(producers[i] && producers[i].id == producerId){
              mythis.resumeProducerLocally(producers[i]);
              //this.localMediasoupProducers[i].resume();
            }
          }
        }
        else{
          const participant = this.findParticipant(message.remoteId);
          if(participant){
            //debugger;
            const audioConsumer = this.getAudioConsumer(participant.audioProducerId);
            if(participant && audioConsumer && audioConsumer.producerId == message.producerId){
              //participant.participantAudioComponent.audioConsumer.resume();
              participant.audioAvailable = true;
            }
          }
          //mythis.remoteProducerResumedSubject.next(message);
        }
      });

      socket.on('peerDisconnected', async function(message){//this is called when a peer disconnects
        console.log('socket.io peerDisconnected', message);
        mythis.removeParticipant(message.remoteId);      
      });

      socket.on('peerLeft', async function(message){//this is called when a peer leaves the room
        console.log('peerLeft', message);
        if(message.remoteId == socket.id){//if i am the one leaving a room, remove all participants
          //remove all producers
          //no need to removeAllParticipants here because it will be done in 'newPeer'+
          //await mythis.removeAllParticipants();
        }
        else{
          await mythis.removeParticipant(message.remoteId);      
        }
      });

      socket.on('peerEnteredRoom', (message) => {
        //for hosts only
        
        const {room, socketid, username} = message;

        if(socket.id != socketid){
          this.peerEnteredRoom(room, username);
        }
      });

      socket.on('peerLeftRoom', (message) => {
        //for hosts only
        const {room, socketid, username} = message;
        this.peerLeftRoom(room, username);
      });

      
      socket.on('handRaised', function(message){
        const socketId = message.socketId;
        if(socketId == socket.id){
          mythis.handraised = true;
        }
        else{
          if(mythis.participantsMap.has(socketId)){
            const participant = mythis.participantsMap.get(socketId);
            participant.handRaised = true;
            mythis.participantHandRaisedSubject.next(participant);
          }
        }
      });

      socket.on('handDropped', function(message){
        mythis.handDroppedSubject.next(message);
      });

      socket.on('setLockStatus', function(message){
        //debugger;
        if(message.socketid == socket.id){
          mythis.meParticipant.unlocked = message.unlocked;
          if(mythis.meParticipant.unlocked){
            mythis.notifySubject.next({messageid: NotificationMessages.enabledtospeak});
          }
        }
        else{
          if(mythis.participantsMap.has(message.socketid)){
            mythis.participantsMap.get(message.socketid).unlocked = message.unlocked;
          }
        }
      });

      

      socket.on('takeAttendance', function(message){
        if(!mythis.localuserinfo.host && !mythis.recorder)
        {
          mythis.takeAttendanceSubject.next(null);
        }
      });

      socket.on('chatMessage', function(message){
        if(message.socketid != mythis.socket.id){
          const participant = mythis.getMessageParticipant(message);
          mythis.addChat({ senderusername: message.username, message: message.message, sendername: participant.name, senderphotourl: participant.photourl, sendersocketid: participant.socketid }, false);
        }
      });

      socket.on('customMessage', (message: { messageId: string, socketId: string, message: any, private: boolean }) => {
        this.customMessageSubject.next(message);
      });

      socket.on('meetingEnded', ({endingSocketId}) => {
        this.meetingEndedSubject.next(endingSocketId);
      })

      // --- get capabilities --
      const routerdata = await this.sendMediasoupControlRequest('getRouterRtpCapabilities', {});
      console.log('getRouterRtpCapabilities:', routerdata);
      await this.loadMediasoupDevice(routerdata);
    }

    if(this.localuserinfo.host){
      const roomSocketIds: any = await this.sendMediasoupControlRequest('getAllCurrentPeersByRoom', {});
      for(let room in roomSocketIds){
        for(let socketid in roomSocketIds[room]){
          this.peerEnteredRoom(room, roomSocketIds[room][socketid].username);
        }
      }
    }

    this.ready = true;
    this.sendMediasoupControlRequest('ready', {});
    await this.startRoom();
  }

  async restartConsumerTransportIce(){
    const iceParameters = await this.sendMediasoupControlRequest('restartConsumerTransportIce', {});
    await this.mediasoupConsumerTransport.restartIce({iceParameters});
  }

  async restartProducerTransportIce(){
    const iceParameters = await this.sendMediasoupControlRequest('restartProducerTransportIce', {});
    await this.mediasoupProducerTransport.restartIce({iceParameters});
  }

  async startRoom() {
    console.log('starting room');
    //this.participantsMap = new Map<string, Participant>();
    await this.removeAllParticipants();
    this.screenShareAvailable = false;
    this.screenShareVideoConsumer = null;
    this.screenShareVideoParticipantProducerId = null;
    this.screenShareParticipantSocketId = null;
    this.screenShareParticipantUsername = null;
    this.screenShareParticipantName = null;
    this.participantScreenShareAvailable = false;
    await this.consumeCurrentMediasoupProducers();
    // await this.startChat();
    console.log(`current room: ${this.currentRoom}`);
    this.enteredRoomSubject.next();
    this.notifySubject.next({messageid: NotificationMessages.enteredroom, data: {room: this.currentRoom}});
  }

  async createProducerTransport(){
    try{
      if (this.mediasoupProducerTransport) {
        try {
          console.log('closing old producer transport');
          this.mediasoupProducerTransport.close();
        }
        catch (error) {
          console.log(error);
        }
      }
      // --- get transport info ---
      console.log('--- createProducerTransport --');
      const params = await this.sendMediasoupControlRequest('createProducerTransport', {});
      console.log('transport params:', params);
      this.mediasoupProducerTransport = this.mediasoupDevice.createSendTransport(params);
      console.log('createSendTransport:', this.mediasoupProducerTransport);

      // --- join & start publish --
      this.mediasoupProducerTransport.on('connect', async ({ dtlsParameters }, callback, errback) => {
        console.log('--trasnport connect');
        this.sendMediasoupControlRequest('connectProducerTransport', { dtlsParameters: dtlsParameters })
          .then(callback)
          .catch(errback);
      });

      this.mediasoupProducerTransport.on('produce', async ({ kind, rtpParameters, appData }, callback, errback) => {
        //debugger;
        console.log('--trasnport produce');
        try {
          const recepientSocketId = appData.recepientSocketId;
          const screenShare = appData.screenShare;
          const { id, message }: any = await this.sendMediasoupControlRequest('produce', {
            transportId: this.mediasoupProducerTransport.id,
            kind,
            rtpParameters,
            appData,
            recepientSocketId,
            screenShare
          });
          if(message){
            this.notifySubject.next({messageid: NotificationMessages.errorproducing, data: message});
          }
          callback({ id });
          //console.log('--produce requested, then subscribe ---');

        } catch (err) {
          errback(err);
        }
      });

      this.mediasoupProducerTransport.on('connectionstatechange', async (state) => {
        //let stream;
        switch (state) {
          case 'connecting':
            console.log('publishing...');
            break;
          case 'connected':
            console.log('published');
            break;
          case 'disconnected':
            console.log('producer transport disconnected. restarting ice');
            if(!this.leaving && this.socket && this.socket.connected){
              this.restartProducerTransportIce();
            }
            //await this.closeProducerTransport();
            //if (started) {
            //  mediasoupProducerTransport.close();
            //  stream = await getVideoStream();
            //  publishToMediasoupRoom(stream);
            //}
            break;
          case 'failed':
            console.log('producer transport failed. restarting ice');
            if(!this.leaving && this.socket && this.socket.connected){
              this.restartProducerTransportIce();
            }
            //alert('Unable to stream. Please refresh the page to try again');
            break;
          case 'closed':
            console.log('consumer transport closed');
            break;
          default:
            console.log('unknown state: ' + state);
            break;
        }
      });

      this.mediasoupProducerTransport.observer.on('close', () => {
        this.createProducerTransportPromise = null;
      });

      return this.mediasoupProducerTransport;
    }
    catch(error){
      this.createProducerTransportPromise = null;//the attempt failed, so we have to remove the saved promise so that the next attempt creates a new promise
      throw error;
    }
  }

  async createConsumerTransport(){
    try{
      if (this.mediasoupConsumerTransport) {
        try {
          console.log('closing old consumer transport');
          this.mediasoupConsumerTransport.close();
        }
        catch (error) {
          console.log(error);
        }
      }
      const params = await this.sendMediasoupControlRequest('createConsumerTransport', {});
      console.log('transport params:', params);
      this.mediasoupConsumerTransport = this.mediasoupDevice.createRecvTransport(params);
      console.log('createConsumerTransport:', this.mediasoupConsumerTransport);

      // --- join & start publish --
      this.mediasoupConsumerTransport.on('connect', async ({ dtlsParameters }, callback, errback) => {
        console.log('--consumer trasnport connect');
        this.sendMediasoupControlRequest('connectConsumerTransport', { dtlsParameters: dtlsParameters })
          .then(callback)
          .catch(errback);
      });

      this.mediasoupConsumerTransport.on('connectionstatechange', async (state) => {
        //debugger
        switch (state) {
          case 'connecting':
            console.log('consumer transport connecting...');
            break;
          case 'connected':
            console.log('consumer transport connected');
            //consumeCurrentProducers(clientId);
            break;
          case 'disconnected':
            console.log('consumer transport disconnected. restarting ice');
            if(!this.leaving && this.socket && this.socket.connected){
              this.restartConsumerTransportIce();
            }
            //alert('Connection Lost');
            //await this.accessCodeEntered(this.accesscode);
            break;
          case 'failed':
            console.log('consumer transport failed. restarting ice');
            if(!this.leaving && this.socket && this.socket.connected){
              this.restartConsumerTransportIce();
            }
            //TODO: what happens if it fails
            break;
          case 'closed':
            console.log('consumer transport closed');
            break;
          default:
            console.log(`unexpected consumer transport state: ${state}`);
            break;
        }
      });

      this.mediasoupConsumerTransport.observer.on('close', () => {
        console.log('consumer transport closed');
        this.createConsumerTransportPromise = null;
      });
      
      return this.mediasoupConsumerTransport;
    }
    catch(error){
      this.createConsumerTransportPromise = null;//the attempt failed, so we have to remove the saved promise so that the next attempt creates a new promise
      throw error;
    }
  }

  async connectMediasoupControlSocket(room: string = null, participantData: any = null) {
    //debugger;
    if (this.socket) {
      this.closeSocket();
      this.socket = null;      
      this.clientId = null;
    }
    const mythis = this;
    
    const connect = function(domain: MeetingDomain): Promise<any> {
      return new Promise<any>((resolve, reject) => {
        let rejected = false;
        let resolved = false;
        const _resolve = function (socket){
          resolved = true;
          resolve(socket);
        }
        const _reject = function(data){
          rejected = true;
          reject(data);
        }
        let socket;
        try{
          socket = io.connect(`${environment.mediasoupScheme}://${domain.domain}:${domain.port}?`, {reconnection: false, transports: ['websocket'], query: { jwttoken: mythis.jwt, room: room ?? mythis.currentRoom, data: JSON.stringify(participantData) } });
        
          mythis.reconnecting = false;
          socket.on('welcome', function (message) {
            if (socket.id !== message.id) {
              console.warn('WARN: something wrong with clientID', socket.io, message.id);
            }
            //debugger;
            mythis.clientId = message.id;
            console.log('connected to server. clientId=' + mythis.clientId);
            mythis.currentRoom = message.room;
            mythis.setDefaultBreakoutRoomIfNotSet(mythis.meParticipant.username, mythis.currentRoom);
            //mythis.meetingChat.startMessaging();

            socket.off('error');
            socket.io.off('error');
            _resolve(socket);
          });

          socket.on('error', function (err) {
            //debugger
            console.error('socket.io ERROR:', err);
            setTimeout(async function () {
              // if (!mythis.leaving) {
              //   await mythis.connectToMeetingUntilSuccessful();
              // }
              //alert('Websocket error.');
              //document.location.reload();
              //publishVideo();
            }, 1000);
            _reject(err);
          });

          socket.io.on('error', (err) => {
            console.error(err);
            reject(err);
          });

          socket.on('failed', (err) => {
            //debugger;
            console.error(err);
            _reject(err);
          });

          socket.on('serverCapacityExceeded', () => {
            console.log('server capacity exceeded at ', domain);
            mythis.generateClientInstanceId();
            _reject('serverCapacityExceeded');
            //this.toastr.error('Maximum Participants Exceeded');
            //this.router.navigate([`/join/${this.meetingid}`], { queryParams: {auth: this.jwt}});
          });
          socket.on('serverStopping', () => {
            console.log(`server stopping at `, domain);
            mythis.generateClientInstanceId();
            _reject('serverStopping');
          });
          socket.on('connect_error', (err) => {
            //debugger;
            console.error(err);
            _reject(err);
          });
          socket.on('connect_failed', (err) => {
            //debugger;
            console.error(err);
            _reject(err);
          });
          socket.on('connect_timeout', (timeout) => {
            //debugger;
            console.error('Timeout: ' + timeout);
            _reject('timeout');
          });
        }
        catch(error){
          console.error(error);
          _reject(error);
        }

        //if we haven't been able to connect for 20 seconds, then we have to assume we're not able to connect
        // setTimeout(() => {
        //   if(!rejected && !rejected){
        //     _reject('timeout');
        //     if(socket){
        //       socket.off('welcome');
        //     }
        //   }
        // }, 20 * 1000);
        //if not resolved after a whole second, reject it



        // socket.on('disconnect', (err) => {
        //   debugger;
        //   reject(err);
        // })
        // socket.onAny(() => {
        //   debugger;
        // })
        // socket.on('*', (err) => {
        //   debugger;
        // })
      });
    };
    
    // let domain_i = 0;
    // let domains: MeetingDomain[] = [];//this.meetinginfo.domains;
    // //we want to only try to connect to those who's reported number of users is below the maximum number of users per server so we'll filter the others out
    // domains = this.getSubdomainsWithSpace();
    // domains = this.orderSubdomains(domains);
    //debugger;
    //for(; domain_i < domains.length; domain_i++)
    //{
    if(this.domain){
      try{
        const socket = await connect(this.domain);
        console.log('Connected to server at ', this.domain);
        return socket;
      }
      catch(error){
        console.log('Unable to connect to server at ', this.domain);
        console.log(error);
        throw new Error('Unable to connect to meeting');
      }
      //let socket = mythis.socket;
    }
    //if we get here, then it means we couldn't find a server to connect to
    return null;
  }

  // private getSubdomainsWithSpace() {
  //   //remove all the subdomains that are full
  //   let domains: MeetingDomain[] = [];
  //   domains = this.meetinginfo.domains.reduce((ret, domain) => {
  //     if (domain.userCount < this.meetinginfo.maxParticipantsPerServer && !domain.dead) {
  //       ret.push(domain);
  //     }
  //     return ret;
  //   }, domains);
  //   return domains;
  // }

  // private orderSubdomains(domains: MeetingDomain[]){
  //   //we want to order the domains so that connections can happen as quicly as possible
  //   //to do this, we'll order the subdomains so that the subdomains with the fewest participants will be at the beginning of the array
  //   //however, those with 0 participants will be at the end
  //   //that way, we won't waste servers, and those with 0 participants can eventually be reclaimed by the server fleet monitor

  //   //order in domains in incresing order of userCount
  //   domains.sort((a, b) => {
  //     if(a.userCount < b.userCount){
  //       return -1;
  //     }
  //     else if(a.userCount > b.userCount){
  //       return 1;
  //     }
  //     else{
  //       return 0;
  //     }
  //   });

  //   //set the length of the domains we want to work on to all the domains
  //   let length = domains.length;
  //   //iterate over the domains
  //   for(let i = 0; i < length; i++){
  //     //if this domain has a userCount of 0
  //     if(domains[i].userCount == 0){
  //       //remove that domain and push it to the end of the array
  //       const removedDomains = domains.splice(i, 1);
  //       domains.push(removedDomains[0]);

  //       //shift the indexer backwards because we have moved the current index to the end
  //       i--;

  //       //redice the length of the domains because we don't want to work on those domains that are now at the end of the array
  //       length--;
  //     }
  //   }
  //   //take all the domains where userCount == 0 and put them at the end of the array
  //   return domains;
  // }

  async removeAllParticipants() {
    const promises: Promise<void>[] = [];
    for (const [socketId, participant] of this.participantsMap) {
      promises.push(this.removeParticipant(socketId));
    }
    await Promise.all(promises);
    this.allParticipantsRemovedSubject.next();
  }

  findParticipantRoom(username: string){
    for(let [room, participantsocketids] of this.roomParticipantsMap){
      if(participantsocketids.has(username)){
        return room;
      }
    }
    return null;
  }

  peerEnteredRoom(room: string, username: string) {
    if (!this.roomParticipantsMap.has(room)) {
      this.roomParticipantsMap.set(room, new Set<string>());
    }
    if(!this.roomParticipantsMap.get(room).has(username)){
      this.roomParticipantsMap.get(room).add(username);
    }

    this.setDefaultBreakoutRoomIfNotSet(username, room);

    this.peerEnteredRoomSubject.next({room, username});
  }

  private setDefaultBreakoutRoomIfNotSet(username: string, room: string) {
    let specifiedbreakoutroom: string;
    for (let [room, usernames] of this.breakoutRooms) { //if the user is not specified to breakout into any room, set his breakout room to the room he just entered
      if (usernames.has(username)) {
        specifiedbreakoutroom = room;
      }
    }

    if (!specifiedbreakoutroom) {
      if (!this.breakoutRooms.has(room)) {
        this.breakoutRooms.set(room, new Set<string>());
      }
      this.breakoutRooms.get(room).add(username);
    }
  }

  peerLeftRoom(room: string, username: string) {
    if (this.roomParticipantsMap.has(room) && this.roomParticipantsMap.get(room).has(username)) {
      this.roomParticipantsMap.get(room).delete(username);
    }
  }

  

  async consumeCurrentMediasoupProducers() {
    console.log('current client id: ', this.socket.id);
    console.log('-- try consumeAll() --');
    const remoteInfo: any = await this.sendMediasoupControlRequest('getCurrentProducers', { localId: this.socket.id })
      .catch(err => {
        console.error('getCurrentProducers ERROR:', err);
        return;
      });
    //console.log('remoteInfo.producerIds:', remoteInfo.producerIds);
    //console.log('remoteInfo.remoteVideoIds:', remoteInfo.remoteVideoIds);
    //console.log('remoteInfo.remoteAudioIds:', remoteInfo.remoteAudioIds);

    
    this.addCurrentProducers(remoteInfo.remoteVideoIds, remoteInfo.remoteAudioIds, remoteInfo.screenShareVideoProducerId, remoteInfo.screenShareAudioProducerId, remoteInfo.ids);
    this.addedCurrentProducers = true;
//    this.reconnecting2 = false;
  }

  addCurrentProducers(remoteVideoIds, remotAudioIds, screenShareVideoProducerId, screenShareAudioProducerId, mediasoupSocketIds) {
    //console.log('remote VideoIds: ', remoteVideoIds);
    console.log('----- consumeAll() -----');

    //this.mediasoupSocketIds = ids;

    for(const socketid in mediasoupSocketIds){
      if(socketid != this.socket.id){
        this.addParticipant(socketid, mediasoupSocketIds[socketid].username, mediasoupSocketIds[socketid].name, mediasoupSocketIds[socketid].host, mediasoupSocketIds[socketid].photourl, mediasoupSocketIds[socketid].data);
      }
    }

    //this.goToPage(1);

    remoteVideoIds.forEach(remoteVideo => {
      //this.consumeAddMediasoupTrack(transport, remoteVideo.socketId, remoteVideo.producerId, 'video');
      this.addParticipantProducer(remoteVideo.socketId, remoteVideo.producerId, 'video', screenShareVideoProducerId == remoteVideo.producerId, remoteVideo.private).catch((error) => {
        console.error(error);
      });//no point starting a screen share producer paused
    });
    remotAudioIds.forEach(remoteVideo => {
      //this.consumeAddMediasoupTrack(transport, remoteVideo.socketId, remoteVideo.producerId, 'audio');
      this.addParticipantProducer(remoteVideo.socketId, remoteVideo.producerId, 'audio', screenShareAudioProducerId == remoteVideo.producerId, remoteVideo.private).catch((error) => {
        console.error(error);
      });
    });
  }

  findParticipant(socketid): Participant {
    if (this.participantsMap.has(socketid)) {
      return this.participantsMap.get(socketid);
      //return this.participantsArray[index];
    }
    else {
      return null;
    }
  }

  async createConsumer(socketid: string, producerId: string, kind: string, paused?: boolean) {
    if(!this.createConsumerTransportPromise){
      console.log('waiting for new transport');
      this.createConsumerTransportPromise = this.createConsumerTransport();
    }

    try{
      this.mediasoupConsumerTransport = await this.createConsumerTransportPromise;
    }
    catch(error){
      console.error('error creating consumer transport');
      console.error(error);
      throw error;
    }
    console.log(`creating '${kind}' consumer for '${socketid}', '${producerId}'`);
    const { rtpCapabilities } = this.mediasoupDevice;
    const data: any = await this.sendMediasoupControlRequest('consumeAdd', { rtpCapabilities: rtpCapabilities, remoteId: socketid, prdId: producerId, kind: kind, paused: paused === undefined ? true : paused });
    
    const {
      //producerIdLocal,
      id,
      kindLocal,
      rtpParameters,
    }: any = data;

    if (producerId && (producerId !== data.producerId)) {
      console.warn('producerID NOT MATCH');
    }

    let codecOptions = {};
    const consumer = await this.mediasoupConsumerTransport.consume({
      id,
      producerId,
      kind,
      rtpParameters,
      codecOptions,
    });
    if(paused){
      consumer.pause();
    }
    console.log(`${kind} consumer ${consumer.id} created`);

    if(kind == 'video'){
      this.videoConsumersMap.set(producerId, consumer);
    }
    else{
      this.audioConsumersMap.set(producerId, consumer);
    }
    
    return {consumer, data};
  }

  async closeConsumer(socketId: string, consumer: any, awaitServerPause?: boolean) {
    if (consumer) {
      if (consumer.closed) {
      }
      const promise = this.sendMediasoupControlRequest('closeConsumer', { remoteId: socketId, kind: consumer.kind, consumerId: consumer.id, producerId: consumer.producerId });
      if (awaitServerPause) {
        await promise;
      }
      consumer.close();
      console.log('consumer ', {id: consumer.id, kind: consumer.kind}, ' for socket ', socketId, ' closed');
    }
  }

  async pauseConsumer(socketid: string, consumer: any, awaitServerPause?: boolean) {
    if (consumer) {
      if (consumer.closed) {
        return;
      }
      else{
        console.log(`pausing consumer `, { remoteId: socketid, kind: consumer.kind, consumerId: consumer.id, producerId: consumer.producerId });
        const promise = this.sendMediasoupControlRequest('pauseConsumer', { remoteId: socketid, kind: consumer.kind, consumerId: consumer.id });
        if (awaitServerPause) {
          await promise;
        }
        consumer.pause();
      }
    }
  }

  async pauseConsumers(consumersinfo: any[], awaitServerPause?: boolean){
    const data: any[] = [];
    consumersinfo.forEach(element => {
      data.push({remoteId: element.socketId, kind: element.consumer.kind, consumerId: element.consumer.id});
    });
    const promise = this.sendMediasoupControlRequest('pauseConsumers', data);
    if(awaitServerPause){
      await promise;
    }
    consumersinfo.forEach(element => {
      element.consumer.pause();
    });
  }

  async resumeConsumer(socketid: string, consumer: any, awaitServerPause?: boolean) {
    if (consumer) {
      if (consumer.closed) {
        return;
      }
      else {
        console.log(`resuming consumer `, { remoteId: socketid, kind: consumer.kind, consumerId: consumer.id, producerId: consumer.producerId });
        const promise = this.sendMediasoupControlRequest('resumeConsumer', { remoteId: socketid, kind: consumer.kind, consumerId: consumer.id });
        if (awaitServerPause) {
          await promise;
        }
        consumer.resume();
      }
    }
  }

  async resumeConsumers(consumersinfo: any[], awaitServerPause?: boolean){
    const data: any[] = [];
    consumersinfo.forEach(element => {
      data.push({remoteId: element.socketId, kind: element.consumer.kind, consumerId: element.consumer.id});
    });
    const promise = this.sendMediasoupControlRequest('resumeConsumers', data);
    if(awaitServerPause){
      await promise;
    }
    consumersinfo.forEach(element => {
      element.consumer.resume();
    });
  }

  async pauseParticipantProducer(socketid: string, producerid: string, kind: string){
    await this.sendMediasoupControlRequest('pauseProducer', { remoteId: socketid, producerId: producerid, kind: kind });
  }

  async resumeParticipantProducer(socketid: string, producerid: string, kind: string){
    await this.sendMediasoupControlRequest('resumeProducer', { remoteId: socketid, producerId: producerid, kind: kind });
  }

  async closeParticipantProducer(socketid: string, producerid: string, kind: string){
    await this.sendMediasoupControlRequest('closeProducer', { remoteId: socketid, producerId: producerid, kind: kind });
  }

  async getMediasoupSocketIdUserDetails(mediasoupSocketId) {
    // let ret;
    // if (!this.mediasoupSocketIds[mediasoupSocketId]) {
    //   const obj: any = await this.sendMediasoupControlRequest('getUserDetails', { id: mediasoupSocketId });

    //   this.mediasoupSocketIds[mediasoupSocketId] = { username: obj.username, name: obj.name, host: obj.host, photourl: obj.photourl };
    // }

    // const obj: any = await this.sendMediasoupControlRequest('getUserDetails', { id: mediasoupSocketId });

    const ret: any = await this.sendMediasoupControlRequest('getUserDetails', { id: mediasoupSocketId });

    //ret = this.mediasoupSocketIds[mediasoupSocketId];

    console.log(`socket id ${mediasoupSocketId} => ${ret}`);

    return ret;
  }

  closeProducer(producer: any) {
    if(this.isMediasoupControlSocketConnected()){
      this.sendMediasoupControlRequest('closeProducer', { producerId: producer.id });//i don't think there's any need to await this
    }
    this.closeProducerLocally(producer);
  }

  closeProducerLocally(producer: any){
    producer.close();
    producer.track.stop();
    // const index = this.localMediasoupProducers.indexOf(producer);
    // if(index != -1){
    //   this.localMediasoupProducers.splice(index, 1);
    // }
    // if (this.localMediasoupProducers.length == 0) {
    //   //no need to do this...its a bug in chrome 87 which should be fixed in 88.
    //   //this.closeProducerTransport();//no need to await this because its more important that the local transport is closed //it appears we have to close the producer transport because in some cases, when we close the mic, and then the camera, and then try to enable the camera afterwards, we get an error
    // }
    this.setProducerTypeUnavailable(producer);
  }

  private setProducerTypeUnavailable(producer: any) {
    if (this.localVideoTrack && producer.track.id == this.localVideoTrack.id) {
      this.videoAvailable = false;
      this.meParticipant.videoAvailable = false;
    }
    else if (this.localAudioTrack && producer.track.id == this.localAudioTrack.id) {
      this.audioAvailable = false;
      this.meParticipant.audioAvailable = false;
    }
    else if (this.screenShareVideoTrack && producer.track.id == this.screenShareVideoTrack.id) {
      this.screenShareAvailable = false;
    }
    this.producerTypeUnavailableSubject.next(producer);
  }

  async pauseProducer(producer: any, wait: boolean){
    const promise = this.sendMediasoupControlRequest('pauseProducer', { producerId: producer.id, kind: producer.kind });
    this.pauseProducerLocally(producer, true);
    if(wait){
      await promise;
    }
  }

  pauseProducerLocally(producer: any, manuallyPaused: boolean){
    producer.pause();
    if(manuallyPaused){
      this.setProducerTypeUnavailable(producer);
    }
  }

  resumeProducerLocally(producer: any){
    //this if is a failsafe to ensure that if a user has disabled audio, his audio producer should never be resumed...same for video
    if((producer.kind == 'audio' && this.settings.enableMicrophone) || (producer.kind == 'video' && this.settings.enableCamera) || producer.id == this.screenShareVideoProducerId || producer.id == this.screenShareAudioProducerId){
      producer.resume();
    }
    else{
      console.error(`Attempt to ${producer.kind} producer when user has disabled it`);
    }
  }

  async resumeProducer(producer: any){
    await this.sendMediasoupControlRequest('resumeProducer', { producerId: producer.id, kind: producer.kind });
    this.resumeProducerLocally(producer);
    
    //this.resumeProducerLocally(producer);
  }

  // async startChat(){
  //   this.chatmessages = [];
  //   const mythis = this;
  //   this.socket.on('chatMessage', function(message){
  //     if(message.socketid != mythis.socket.id){
  //       const participant = mythis.getMessageParticipant(message);
  //       mythis.addChat({ senderusername: message.username, message: message.message, sendername: participant.name, senderphotourl: participant.photourl }, false);
  //     }
  //   });
  //   //await this.getCurrentMessages();
  // }

  getMessageParticipant(message){
    let participant = new Participant();
      if(message.socketid == this.meParticipant.socketid){
        participant = this.meParticipant;
      }
      else if(this.participantsMap.has(message.socketid)){
        //const index = this.meeting.participantsMap.get(message.socketid);
        participant = this.participantsMap.get(message.socketid);
      }
      else{
        debugger;
      }
      return participant;
  }

  addChat(message: ChatMessage, bulk: boolean){
    this.chatmessages.push(message);
    this.chatAddedSubject.next({message, bulk});
  }

  async setPreferredConsumerSpatialLayer(remoteId, consumerId, preferredLayer){
    await this.sendMediasoupControlRequest('setConsumerPreferredSpatialLayer', { remoteId: remoteId, consumerId: consumerId, preferredLayer: preferredLayer });
  }

  async setConsumerPriority(remoteId, consumerId, priority){
    await this.sendMediasoupControlRequest('setConsumerPriority', { remoteId: remoteId, consumerId: consumerId, priority: priority });//no need to await this
  }

  // async recordMeeting(){
  //   if(this.localuserinfo.host){
  //     await this.meetingservice.recordMeeting(this.meetingid, this.jwt).toPromise();
  //     this.recording = true;
      
  //   }
  // }

  async leaveMeeting(ended: boolean = false){
    this.leaving = true;
    await this.disconnect();
    try{
      this.localVideoTrack?.stop();
    }
    catch{}
    try{
      this.localAudioTrack?.stop();
    }
    catch{}
    try{
      this.screenShareVideoTrack?.stop();
    }
    catch{}
  }

  

  getParticipantByUsername(username): Participant{
    if(username == this.localuserinfo.username){
      return this.meParticipant;
    }
    else{
      let participant = this.participantsUsernameMap.get(username);
      if(!participant){
        participant = new Participant();
        participant.name = username;
        participant.username = username;
      }
      return participant;
    }
  }

  async moveParticipantsToRoom(room: string, usernames: string[], fromRoom: string = null){
    await this.sendMediasoupControlRequest('moveParticipantsToRoom', {room, usernames: usernames, fromRoom});
  }

  async breakIntoRooms(){
    const promises: Promise<unknown>[] = [];
    for(const [room, usernames] of this.breakoutRooms){
      //for(const username of usernames){
        const usernamesarray = [...usernames];
        promises.push(this.moveParticipantsToRoom(room, usernamesarray));
      //}
    }
    await Promise.all(promises);
    this.notifySubject.next({messageid: NotificationMessages.brokenout});
  }

  async raiseHand(){
    await this.sendMediasoupControlRequest('raiseHand', {});
  }

  async dropHand(remoteId = undefined){
    await this.sendMediasoupControlRequest('dropHand', {remoteId});
  }

  async pauseAllOtherAudioProducers(){
    await this.sendMediasoupControlRequest('pauseAllOtherAudioProducers', {});
  }

  async markAttendanceTaken(){
    await this.sendMediasoupControlRequest('attendanceTaken', {username: this.localuserinfo.username});
  }

  async setParticipantLock(participant: Participant){
    return await this.sendMediasoupControlRequest('setParticipantLock', {unlocked: !participant.unlocked, socketid: participant.socketid});
  }

  async sendChatMessage(messagetext: string){
    await this.sendMediasoupControlRequest('chatMessage', { message: messagetext })
  }
}

export enum NotificationMessages{
  microphonedisabledbyhost = 'microphonedisabledbyhost',
  notyetenabledtospeak = 'notyetenabledtospeak',
  webcamdisabledbyhost = 'webcamdisabledbyhost',
  enabledtospeak = 'enabledtospeak',
  enteredroom = 'enteredroom',
  brokenout = 'brokenout',
  errorproducing = 'errorproducing',
  errorGettingVideoStream = 'errorGettingVideoStream',
  errorGettingAudioStream = 'errorGettingAudioStream'
}

export class Locker{
  //We need to make sure that audio/video/screenshare enabling and disabling never run in parallel
  //so i've implemented this locker
  private static promiseMap: Map<object, {promise: Promise<void>, resolver: any}> = new Map<object, {promise: Promise<void>, resolver: any}>()
  static async lock(lock: object){
    if(lock === null || lock === undefined){
      throw new Error('Lock object cannot be null or undefined');
    }

    //wait for any existing promise
    //if the promise is null, await will return immediately
    const existingPromise: Promise<void> = this.promiseMap.has(lock) ? this.promiseMap.get(lock).promise : null;
    await existingPromise;

    //create a new promise that everyone else will wait on
    //unlocking will have to resolve that promise
    
    const newPromiseMapItem = {promise: null, resolver: null}
    const newPromise: Promise<void> = new Promise((resolve, reject) => {
      //promise callbacks are always called synchronously so we know the resolver will be set before we publish this locker item to the map
      newPromiseMapItem.resolver = resolve;  
    });
    newPromiseMapItem.promise = newPromise;
  
    this.promiseMap.set(lock, newPromiseMapItem);
  }

  static unlock(lock: object){
    if(lock === null || lock === undefined){
      throw new Error('Lock object cannot be null or undefined');
    }

    if(!this.promiseMap.has(lock)){
      console.error('No lock has been aquired on object ', lock);
      throw new Error(`No lock has been aquired on specified object: ${lock}`);
    }

    const resolver = this.promiseMap.get(lock).resolver;
    resolver();
  }
}
