import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CbtserviceService {
  private previousUrl: string = undefined;
  private queryparam:any;
  private currentUrl: string = undefined;
  private getMediaStreamPromise: Promise<MediaStream>;

  constructor(private router: Router, private toastr: ToastrService, private http: HttpClient) {
    this.currentUrl = this.router.url;
    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        console.log(event.url);
        this.previousUrl = this.currentUrl;
        this.currentUrl = event.url;
      }
    });
  }

  public getPreviousUrl() {
    return  this.previousUrl;
  } 

  showHttpError(error: HttpErrorResponse){
    if(error.status === 0){
      this.toastr.warning("Unable to connect to server", "Connection Error", { timeOut : 5000 });      
    }
    else if(error.status === 400){   
      console.log(error);
      this.toastr.error(error.error && error.error.message ? error.error.message : 'Unknown server side error', "Error", { timeOut : 8000 });
    }
    else{
      if(error && error.statusText){
        this.toastr.error(error.statusText, "Error", { timeOut : 8000 });
      }
      if(error && error.error){
        this.toastr.error(error.error, "Error", { timeOut : 8000 });
      }
      if(error && error.error && error.error.error){
        this.toastr.error(error.error.error, "Error", { timeOut : 8000 });
      }
    }
  }

  async tryGet(url: string, options: any, maxAttempts = environment.maxRequestRetries): Promise<any>{
    let attempts = 0;
    while(true){
      attempts++;
      try{
        return await this.http.get(url, options).toPromise();
      }
      catch(error){
        if(attempts < maxAttempts && (error.status === 0 || (error.error && error.error.message && error.error.message.toUpperCase().includes(environment.seamlessScalingError)))){
          //return await this.getuserexams(candidateno);
          //probably gateway timeout (when status === 0) or seamless scaling error. retry
        }
        else{
          //this.cbtservice.showHttpError(error);
          //this.isloading = false;
          throw error;
        }
      }
    }
  }

  async tryPost(url: string, data: any, options: any, maxAttempts = environment.maxRequestRetries): Promise<any>{
    let attempts = 0;
    while(true){
      attempts++;
      try{
        return await this.http.post(url, data, options).toPromise();
      }
      catch(error){
        if(attempts < maxAttempts && (error.status === 0 || (error.error && error.error.message && error.error.message.toUpperCase().includes(environment.seamlessScalingError)))){
          //return await this.submitexam(scheduleid, elapsed);
          //probably gateway timeout (when status === 0) or seamless scaling error. retry
        }
        else{
          //this.cbtservice.showHttpError(error);
          //this.isloading = false;
          throw error;
        }
      }
    }
  }

  async getVideoStream(){
    if(this.getMediaStreamPromise === undefined){
      this.getMediaStreamPromise = navigator.mediaDevices.getUserMedia({
        video: {
          facingMode: 'user'
        }
      });
    }

    const stream = await this.getMediaStreamPromise;
    return stream;
  }
}
