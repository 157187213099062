import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { } from '../model'
import { CbtserviceService } from '../services/cbtservice.service';
import { ExamService } from '../services/exam.service';
import { QuestionserviceService } from '../services/questionservice.service';
@Component({
  selector: 'app-questionpagefooter',
  templateUrl: './questionpagefooter.component.html',
  styleUrls: ['./questionpagefooter.component.css']
})
export class QuestionpagefooterComponent implements OnInit {
  page = 1;
  @Input() noofquestions: number = 0;
  numbers: any;
  questions: any;
  @Input() examid: number
  @Input() scheduleid: number
  @Input() qno: number;
  @Input() schedulequestionid: any;

  @Output() qnoclicked: EventEmitter<number> = new EventEmitter<number>();
  constructor(public questionservice: QuestionserviceService, public toastr: ToastrService,
    public examservice: ExamService, public cbtservice: CbtserviceService) {
    // this.noofquestions= this.exam.noofquestions *10

  }
  ngChange() {

  }
  disableradios() {
    var buttons = document.getElementsByName(this.schedulequestionid);
    buttons.forEach(this.radiodiabbletoggle)
    var butons = document.getElementsByClassName(this.schedulequestionid);
    for (var i = 0; i < butons.length; i++) {
      this.radiodiabbletoggle(butons[i]);
    }
  }
  radiodiabbletoggle(button) {
    button.disabled = true;
  }

  async ngOnInit() {
    this.questions = await this.examservice.getexamquestionlist(this.scheduleid).catch(err => { this.cbtservice.showHttpError(err); });
    // var count = await this.questionservice.getquestioncount(this.scheduleid).toPromise().catch(err => this.toastr.error(err));
    // this.noofquestions= count;
    this.numbers = Array(this.noofquestions).fill(0).map((x, i) => i + 1);
    this.questions.forEach(element => {
    });
  }

  noclicked(qno: number) {
    this.disableradios();
    this.qnoclicked.emit(qno);
  }

  answerQuestion(questionNo: number) {
    this.questions.forEach(element => {
      if (element.questionno == questionNo) {
        element.answered = true;
      }
      return element;
    });
  }
  unanswerQuestion(questionNo: number) {
    this.questions.forEach(element => {
      if (element.questionno == questionNo) {
        element.answered = false;
      }
      return element;
    });
  }
  flagquestion(data: any) {
    this.questions.forEach(element => {
      if (element.questionno == data.qno) {
        element.flagged = data.flagged;
      }
      return element;
    });
  }
}
