import { Component, OnInit, Input, ViewChild, ElementRef, AfterViewInit, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { Question, Exam, OptionDetails } from '../model'
import { FormGroup, FormControl, FormBuilder } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import Quill from 'quill'
import { QuestionserviceService } from '../services/questionservice.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { JsonPipe } from '@angular/common';
import { ExamService } from '../services/exam.service';
import { ProctoringService } from '../services/proctoring.service';
import { environment } from 'src/environments/environment';
import { CbtserviceService } from '../services/cbtservice.service';

@Component({
  selector: 'app-question',
  templateUrl: './question.component.html',
  styleUrls: ['./question.component.css']
})
export class QuestionComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() question: any
  @Input() url: any
  @Input() adminpaused: boolean;
  @Input() proctorpaused: boolean;
  @Input() timeleft: number = undefined;
  @Input() status: any;
  //  {
  //   "questionhtml": '<p>Voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident</p><p>Magnam dolores commodi suscipit. Necessitatibus eius consequatur ex aliquid fuga eumquidem.Sit sint consectetur velit. Quisquam quos quisquam cupiditate. Et nemo qui impedit suscipit alias ea.</p><img src=/"assets/img/about.jpg" class="img-fluid" alt=""><a href="https://www.youtube.com/watch?v=jDDaplaOz7Q" class="" data-vbtype="video"    data-autoplay="true"></a>',
  //   "questionid": 11,
  //   "questionno": 1,
  // }

  @Input() essayexam: boolean
  options: string[] = [];
  @Input() isloadingdata: boolean = false;
  thisurl = location.origin + '/';

  @Output() quesanswered: EventEmitter<number> = new EventEmitter<number>();
  @Output() quesunanswered: EventEmitter<number> = new EventEmitter<number>();
  @Output() quesflagged: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('questionhtmlsection') questionhtmlsection: ElementRef;
  flagged: boolean = false;

  constructor(public questionservice: QuestionserviceService, public examservice: ExamService, public toastr: ToastrService, public router: Router, private cbtservice: CbtserviceService) { }
  ngOnChanges(changes: SimpleChanges): void {
    //console.log(changes.prop);
    //debugger;
    this.setQuestion();
  }
  ngAfterViewInit(): void {
    this.setQuestion();

  }

  setQuestion() {
    if (this.questionhtmlsection) {
      if (this.question) {
        this.questionhtmlsection.nativeElement.innerHTML = this.question.examQuestion.questionhtml;
        this.flagged = this.question.examQuestionSchedule.flagged;
      }
      else {
        this.questionhtmlsection.nativeElement.innerHTML = '';
      }
    }
  }
  updatedanswer(answer: any) {
    this.question.essayAnswer = answer;
  }
  ngOnInit(): void {
    this.options = [];
  }
  async OnRadioSelected(radiovalue: any) {
    this.isloadingdata = true
    // console.log(radiovalue);
    this.options.push(radiovalue);
    this.getoptions();

    var data = {
      "schedulequestionid": this.question.examQuestionSchedule.schedulequestionid,
      "scheduleid": this.question.examQuestionSchedule.scheduleid,
      "options": this.options,
      "essayanswer": "",
      "essay": false
    }
    console.log('Check ' + this.question.examQuestionSchedule.questionno);
    if (this.options && this.options.length > 0) {

      var saved: any = await this.questionservice.markanswer(data, this.question.examination.liteMode).catch(err => this.cbtservice.showHttpError(err)).finally(() => { this.enableradios(); this.isloadingdata = false });
      debugger
      if (!saved) {
        this.options = this.options.filter(x => x != radiovalue.optionid.toString());
        radiovalue.selected = false;
        let opt: any = document.getElementById(radiovalue.optionid.toString());
        opt.checked = false;
        return
      }
      if (saved.logout == true) {
        this.toastr.error(saved.msg);
        // window.location.href = `${this.thisurl}logout`;
        this.router.navigate(["/logout"]);
      }
      
      //emit
      this.quesanswered.emit(this.question.examQuestionSchedule.questionno);

    }
  }

  getoptions(scheduleid = this.question.examQuestionSchedule.schedulequestionid) {
    this.options = [];
    var ele = document.getElementsByClassName(scheduleid);
    for (var i = 0; i < ele.length; i++) {
      this.getchecked(ele[i]);
    }
  }
  getchecked(element) {
    //debugger;
    if (element.checked) {
      this.options.push(element.value.toString())
    }
  }
  enableradios() {
    var buttons = document.getElementsByName(this.question.examQuestionSchedule.schedulequestionid);
    buttons.forEach(this.radioenable)
    var butons = document.getElementsByClassName(this.question.examQuestionSchedule.schedulequestionid);
    for (var i = 0; i < butons.length; i++) {
      this.radioenable(butons[i]);
    }
  }
  radioenable(button) {
    button.disabled = false;


  }

  async timeelapsed(elapsed: boolean) {
    if (elapsed) {
      //we get the answers here so that we get the answers as at the time of elapsed, not on the x-th iteration
      const answers = this.question.examination.liteMode ? this.questionservice.getAllQuestionsAnswer() : undefined;
      do {
        try {
          const response: any = await this.examservice.getexamstatus(this.question.examQuestionSchedule.scheduleid, elapsed).catch(err => this.cbtservice.showHttpError(err)).finally(() => this.isloadingdata = false);
          // console.log(response.statusmsg)

          if (response && response.statusmsg && response.statusmsg != "") {

            await this.examservice.submitexam(this.question.examQuestionSchedule.scheduleid, this.question.examination.liteMode, elapsed, answers).catch(err => this.cbtservice.showHttpError(err));
            this.router.navigate(["/exam/submit"], { queryParams: { scheduleid: this.question.examQuestionSchedule.scheduleid } });
            // window.location.href = `${this.thisurl}exam/submit?scheduleid=${this.question.examQuestionSchedule.scheduleid}`;

          }
          else if (response && response.statusmsg == "")
            self.location.reload();

          break;
        }
        catch (error) {
          console.error(error);
        }
      }
      while (this.question.examination.liteMode);//if we are in lite mode, we HAVE to make sure this succeeds
    }

  }

  async oncheckchanged(checkvalue: OptionDetails) {
    this.isloadingdata = true
    if (checkvalue.selected)
      this.options.push(checkvalue.optionid.toString());
    else
      this.options = this.options.filter(x => x != checkvalue.optionid.toString());

    this.getoptions();

    var data = {
      "schedulequestionid": this.question.examQuestionSchedule.schedulequestionid,
      "scheduleid": this.question.examQuestionSchedule.scheduleid,
      "options": this.options,
      "essayanswer": "",
      "essay": false
    }
    if (this.options && this.options.length >= 0) {

      var saved: any = await this.questionservice.markanswer(data, this.question.examination.liteMode).catch(err => this.cbtservice.showHttpError(err)).finally(() => { this.enableradios(); this.isloadingdata = false });
      debugger
      if (!saved) {
        this.options = this.options.filter(x => x != checkvalue.optionid.toString());
        checkvalue.selected = false;
        let opt: any = document.getElementById(checkvalue.optionid.toString());
        opt.checked = false;
        this.quesunanswered.emit(this.question.examQuestionSchedule.questionno);

      return;
      }
      if (saved.logout == true) {
        this.toastr.error(saved.msg);
        // window.location.href = `${this.thisurl}logout`;
        this.router.navigate(["/logout"]);
      }
    
      if (this.options.length == 0) {
        this.quesunanswered.emit(this.question.examQuestionSchedule.questionno);
      }
      else {
        this.quesanswered.emit(this.question.examQuestionSchedule.questionno);
      }
    }
  }

  async gotoquestion(qno: number) {
    //debugger;
    let answereditor = document.getElementsByClassName('ql-editor');
    var essayanswer: any = ''
    if (answereditor && answereditor.length > 0) {
      essayanswer = answereditor[0].innerHTML;
    }
    this.isloadingdata = true
    this.getoptions();
    this.questionservice.previousquestiondata = null;
    this.questionservice.nextquestiondata = null;

    var data = {
      "schedulequestionid": this.question.examQuestionSchedule.schedulequestionid,
      "scheduleid": this.question.examQuestionSchedule.scheduleid,
      "options": this.options,
      "essayanswer": essayanswer,
      "essay": this.essayexam
    }
    // console.log(JSON.stringify(data));
    debugger
    if (this.options && this.options.length > 0) {

      // var saved: any = await this.questionservice.markanswer(data,this.questionservice.hasQuestionDataInStorage()).toPromise().catch(err => this.toastr.error(err)).finally(() => { this.enableradios(); this.isloadingdata = false });
      // if (saved.saved == true) this.router.navigate(["/exam/question"], { queryParams: { scheduleid: data.scheduleid, questionno: qno } });
      // if (saved.logout == true) {
      //   this.toastr.error(saved.msg);
      //   this.router.navigate(["/logout"]);
      // }
      this.questionservice.previousquestiondata = data;
    }
    // else{
    var s = data.scheduleid.toString();
    var n = qno.toString();
    var res = await this.questionservice.getquestionandoptions(s, n, data, this.question.examination.liteMode).catch(err => { this.cbtservice.showHttpError(err); }).finally(() => this.isloadingdata = false);
    if (res) {
      this.questionservice.nextquestiondata = res;
      this.router.navigate(["/exam/question"], { queryParams: { scheduleid: this.question.examQuestionSchedule.scheduleid, questionno: qno } });
    }
  }

  flagclicked(flagged: boolean) {
    this.flagged = flagged;
    let qno = this.question.examQuestionSchedule.questionno;
    this.quesflagged.emit({ qno, flagged });
  }


  // async gotoprevquestion(qno: number) {
  //   this.getoptions();

  //   this.isloadingdata = true
  //   var data = {
  //     "schedulequestionid": this.question.examQuestionSchedule.schedulequestionid,
  //     "scheduleid": this.question.examQuestionSchedule.scheduleid,
  //     "options": this.options,
  //     "essayanswer":"",
  //     "essay":false
  //   }
  //   console.log(JSON.stringify(data));
  //   if (this.options && this.options.length > 0) {

  //     var saved: any = await this.questionservice.markanswer(data).toPromise().catch(err => this.toastr.error(err)).finally(() => { this.enableradios(); this.isloadingdata = false });
  //     if (saved.saved == true) this.router.navigate(["/exam/question"], { queryParams: { scheduleid: data.scheduleid, questionno: qno } });
  //     if (saved.logout == true) {
  //       this.toastr.error(saved.msg);
  //       this.router.navigate(["/logout"]);
  //     }
  //   }
  //   else{
  //     this.router.navigate(["/exam/question"], { queryParams: { scheduleid: data.scheduleid, questionno: qno } });
  //   }
  // }

  savequestion(qno: number) {
    this.isloadingdata = true

  }
}
