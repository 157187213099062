<!-- <div *ngIf="isloading" class="p-5" style="display: flex; justify-content: center;align-items: center; text-align: center; height: 80vh; width: 80vw;"> -->
<!-- <div class="lds-circle"><div></div></div> -->
<!-- <div class="lds-ripple"><div></div><div></div></div> -->
<!-- <app-loading-spinner></app-loading-spinner> -->

<div *ngIf="!hasloaded" class="overlay" [ngClass]="{'show': isloading}"></div>
<div *ngIf="!hasloaded" class="spanner" [ngClass]="{'show': isloading}">

  <div class="spin-wrap">
    <div class="spin-wrap-content">
      <div class="spinner-container">
        <div class="lds-ripple">
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- </div> -->
<div class="card" id="exam_start_card">
  <!-- Card header -->
  <div class="card-header">
    <!-- Title -->
    <h5 class="h3 mb-0">Examination List</h5>
  </div>
  <!-- Card body -->
  <div class="card-body">
    <!-- <div class="card col-md-3 border d-none d-none d-md-block">
        <a href="#" class="m-5">
            <img src="../../assets/img/theme/team-1.jpg"
                class="rounded-circle img-center img-fluid shadow shadow-lg--hover" style="width: 140px;">
        </a>
    </div> -->
    <!-- List group -->
    <div class="col-md-12">
      <!-- Card header -->
      <div class="card-title">
        <!-- remove border-0 -->
        <h3 class="mb-0">Scheduled Examinations</h3>
      </div>

      <!-- Light table -->
      <div *ngIf="exams && exams.length>0" class="card-body">
        <div class="row">
          <div class="col-sm-12 exam-type-sec">
            <div class="my-2">
              <div class="classmaterial" *ngFor="let exam of exams">
                <app-userexamination [exam]="exam" (destroyInterval)="destroyInterval($event)" (resumeInterval)="resumeInterval($event)"></app-userexamination>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="row" > -->
        <!-- <div class="col-md-12">
                    <div  > -->
        <!-- </div> -->
        <!-- <tr>
                        <th scope="row">
                            <div class="media align-items-center">
                                <a href="#" class="avatar rounded-circle mr-3">
                                    <img alt="Image placeholder" src="../../assets/img/theme/angular.jpg">
                                </a>
                                <div class="media-body">
                                    <span class="name mb-0 text-sm">Angular Now UI Kit PRO</span>
                                </div>
                            </div>
                        </th>
                        <td class="budget">
                            $1800 USD
                        </td>
                        <td>
                            <span class="badge badge-dot mr-4">
                                <i class="bg-success"></i>
                                <span class="status">completed</span>
                            </span>
                        </td>
                        <td>
                            <div class="avatar-group">
                                <a href="#" class="avatar avatar-sm rounded-circle" data-toggle="tooltip"
                                    data-original-title="Ryan Tompson">
                                    <img alt="Image placeholder" src="../../assets/img/theme/team-1.jpg">
                                </a>
                                <a href="#" class="avatar avatar-sm rounded-circle" data-toggle="tooltip"
                                    data-original-title="Romina Hadid">
                                    <img alt="Image placeholder" src="../../assets/img/theme/team-2.jpg">
                                </a>
                                <a href="#" class="avatar avatar-sm rounded-circle" data-toggle="tooltip"
                                    data-original-title="Alexander Smith">
                                    <img alt="Image placeholder" src="../../assets/img/theme/team-3.jpg">
                                </a>
                                <a href="#" class="avatar avatar-sm rounded-circle" data-toggle="tooltip"
                                    data-original-title="Jessica Doe">
                                    <img alt="Image placeholder" src="../../assets/img/theme/team-4.jpg">
                                </a>
                            </div>
                        </td>
                        <td>
                            <div class="d-flex align-items-center">
                                <span class="completion mr-2">100%</span>
                                <div>
                                    <div class="progress">
                                        <div class="progress-bar bg-success" role="progressbar"
                                            aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"
                                            style="width: 100%;"></div>
                                    </div>
                                </div>
                            </div>
                        </td>
                        <td class="text-right">
                            <div class="dropdown">
                                <a class="btn btn-sm btn-icon-only text-light" href="#" role="button"
                                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <i class="fas fa-ellipsis-v"></i>
                                </a>
                                <div class="dropdown-menu dropdown-menu-right dropdown-menu-arrow">
                                    <a class="dropdown-item" href="#">Action</a>
                                    <a class="dropdown-item" href="#">Another action</a>
                                    <a class="dropdown-item" href="#">Something else here</a>
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <th scope="row">
                            <div class="media align-items-center">
                                <a href="#" class="avatar rounded-circle mr-3">
                                    <img alt="Image placeholder" src="../../assets/img/theme/sketch.jpg">
                                </a>
                                <div class="media-body">
                                    <span class="name mb-0 text-sm">Black Dashboard</span>
                                </div>
                            </div>
                        </th>
                        <td class="budget">
                            $3150 USD
                        </td>
                        <td>
                            <span class="badge badge-dot mr-4">
                                <i class="bg-danger"></i>
                                <span class="status">delayed</span>
                            </span>
                        </td>
                        <td>
                            <div class="avatar-group">
                                <a href="#" class="avatar avatar-sm rounded-circle" data-toggle="tooltip"
                                    data-original-title="Ryan Tompson">
                                    <img alt="Image placeholder" src="../../assets/img/theme/team-1.jpg">
                                </a>
                                <a href="#" class="avatar avatar-sm rounded-circle" data-toggle="tooltip"
                                    data-original-title="Romina Hadid">
                                    <img alt="Image placeholder" src="../../assets/img/theme/team-2.jpg">
                                </a>
                                <a href="#" class="avatar avatar-sm rounded-circle" data-toggle="tooltip"
                                    data-original-title="Alexander Smith">
                                    <img alt="Image placeholder" src="../../assets/img/theme/team-3.jpg">
                                </a>
                                <a href="#" class="avatar avatar-sm rounded-circle" data-toggle="tooltip"
                                    data-original-title="Jessica Doe">
                                    <img alt="Image placeholder" src="../../assets/img/theme/team-4.jpg">
                                </a>
                            </div>
                        </td>
                        <td>
                            <div class="d-flex align-items-center">
                                <span class="completion mr-2">72%</span>
                                <div>
                                    <div class="progress">
                                        <div class="progress-bar bg-danger" role="progressbar"
                                            aria-valuenow="72" aria-valuemin="0" aria-valuemax="100"
                                            style="width: 72%;"></div>
                                    </div>
                                </div>
                            </div>
                        </td>
                        <td class="text-right">
                            <div class="dropdown">
                                <a class="btn btn-sm btn-icon-only text-light" href="#" role="button"
                                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <i class="fas fa-ellipsis-v"></i>
                                </a>
                                <div class="dropdown-menu dropdown-menu-right dropdown-menu-arrow">
                                    <a class="dropdown-item" href="#">Action</a>
                                    <a class="dropdown-item" href="#">Another action</a>
                                    <a class="dropdown-item" href="#">Something else here</a>
                                </div>
                            </div>
                        </td>
                    </tr> -->
        <!-- </div> -->
        <!-- </div> -->
        <!-- Card footer -->
        <!-- <div class="card-footer py-4">
            <nav aria-label="...">
                <ul class="pagination justify-content-end mb-0">
                    <li class="page-item disabled">
                        <a class="page-link" href="#" tabindex="-1">
                            <i class="fas fa-angle-left"></i>
                            <span class="sr-only">Previous</span>
                        </a>
                    </li>
                    <li class="page-item active">
                        <a class="page-link" href="#">1</a>
                    </li>
                    <li class="page-item">
                        <a class="page-link" href="#">2 <span class="sr-only">(current)</span></a>
                    </li>
                    <li class="page-item"><a class="page-link" href="#">3</a></li>
                    <li class="page-item">
                        <a class="page-link" href="#">
                            <i class="fas fa-angle-right"></i>
                            <span class="sr-only">Next</span>
                        </a>
                    </li>
                </ul>
            </nav>
        </div> -->
      </div>
      <div *ngIf="exams && exams.length==0">
        No exams found
      </div>
    </div>
  </div>
</div>
