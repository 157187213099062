<div class="overlay" [ngClass]="{'show': isloading}"></div>
<div class="spanner" [ngClass]="{'show': isloading}">
    <!-- <div class="loader"></div> -->
    <div class="spin-wrap">
        <div class="spin-wrap-content">
            <div class="spinner-container">
                <div class="lds-ripple">
                    <div></div>
                    <div></div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- <div *ngIf="isloading" class="p-5"
    style="display: flex; justify-content: center;align-items: center; text-align: center; height: 80vh; width: 80vw;">
    <app-loading-spinner></app-loading-spinner>
</div> -->
<div class="card">
    <!-- Card header -->
    <div class="card-header submit-exam">
        <!-- Title -->
        <h5 class="mb-0">SUBMIT EXAMINATION</h5>
    </div>
    <!-- Card image -->
    <!-- List group -->
    <!-- Card body -->
    <div class="card-body">
        <div *ngIf="!msg && !isloading" class="home-text" style="width: 90%; text-align: center;">
            <h2 class="mb-5">Are you sure you want to submit? This will end the Exam.</h2>


            <button class="btn btn-primary  mr-5" (click)="noclicked()" [disabled]="this.submitting">
                <h2 style="color: white !important;">NO</h2>
            </button>

            <button class="btn btn-danger" (click)="yesclicked()" [disabled]="this.submitting">
                <h2 style="color: white !important;">{{this.submitting ? "Submitting..." : "YES"}}</h2>
            </button>
        </div>
        <app-examfeedback #feedmodal></app-examfeedback>
        <div *ngIf="msg" class="h2 mb-5" style="width: 90% ; text-align: center;" id="exammessage" [innerHTML]="msg">
        </div>
        <div *ngIf="postmsg" class="h2 mb-5" style="width: 90% ; text-align: center;" id="exammessage" [innerHTML]="postmsg">
        </div>
        <div *ngIf="score && msg" class="h1 mb-5" style="width: 90% ; text-align: center;">
            Your Score is {{score}}
        </div>
        <div *ngIf="msg" style="width: 90%; text-align: center;">
            <!-- <span id="scorespan" visible="false"> -->
            <!-- <br /> -->
            <!-- <b>Your Score is
                    <label id="scoreliteral"></label>/<label id="possiblescoreliteral"></label></b></span> -->

            <!-- <%if (!string.IsNullOrWhiteSpace(destinationurl))
                {
                    if (!string.IsNullOrWhiteSpace(destinationurl))
                    {%>
            <a class="regular-button" href="<%= destinationurl%>">Go Back To <%= destinationname%></a>
            <%}
                else
                { %>
            <a class="regular-button" href="<%= destinationurl%>">Go Back</a>
            <%}
                }
                else
                { %> -->
            <a style="cursor:pointer;" class="regular-button start_new_exm" (click)="newexam()">Start a new Exam</a>
        </div>
    </div>
</div>