import { query } from '@angular/animations';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { RegisterCandidate } from '../model';
import { Exam, ExamDetails } from '../models/Exam';
import { CbtserviceService } from './cbtservice.service';
import { QuestionserviceService } from './questionservice.service';
//import { throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ExamService {





  readonly rootURL = environment.CBTAPIURL;
  req_headers: HttpHeaders;

  constructor(private cbtservice: CbtserviceService, public questionservice: QuestionserviceService, ) {
    this.req_headers = new HttpHeaders({ "content-type": "application/json", "accept": "application/json", "skip_error_handler_interceptor": "true" });//all the services here should skip the interceptor
  }


  async getuserexams(candidateno: any): Promise<any> {
    // let attempts = 0;
    // while(true){
    //   attempts++;
    //   try{
    //     return await this.http.get(this.rootURL + `Exam/getuserexams?examno=${candidateno}`, {headers: this.req_headers}).toPromise();
    //   }
    //   catch(error){
    //     if(attempts < environment.maxRequestRetries && (error.status === 0 || (error.error && error.error.message && error.error.message.toUpperCase().includes(environment.seamlessScalingError)))){
    //       //return await this.getuserexams(candidateno);
    //       //probably gateway timeout (when status === 0) or seamless scaling error. retry
    //     }
    //     else{
    //       //this.cbtservice.showHttpError(error);
    //       //this.isloading = false;
    //       throw error;
    //     }
    //   }
    // }
    return await this.cbtservice.tryGet(this.rootURL + `Exam/getuserexams?examno=${candidateno}`, { headers: this.req_headers });
  }


  async getInterviewInfo(username, name, accessCode) : Promise<any> {
 
    return await this.cbtservice.tryGet(this.rootURL + `Exam/getinterviewinfo?accesscode=${encodeURI(accessCode)}&username=${encodeURI(username)}&name=${encodeURI(name)}&host=false`, { headers: this.req_headers });
  }


  async getexamwithscheduleid(scheduleid: any): Promise<any> {
    // let attempts = 0;
    // while(true){
    //   attempts++;
    //   try{
    //     return await this.http.get(this.rootURL + `Exam/getexamwithscheduleid?scheduleid=${scheduleid}`, {headers: this.req_headers}).toPromise();
    //   }
    //   catch(error){
    //     if(attempts < environment.maxRequestRetries && (error.status === 0 || (error.error && error.error.message && error.error.message.toUpperCase().includes(environment.seamlessScalingError)))){
    //       //return await this.getexamwithscheduleid(scheduleid);
    //       //probably gateway timeout (when status === 0) or seamless scaling error. retry
    //     }
    //     else{
    //       //this.cbtservice.showHttpError(error);
    //       //this.isloading = false;
    //       throw error;
    //     }
    //   }
    // }
    return await this.cbtservice.tryGet(this.rootURL + `Exam/getexamwithscheduleid?scheduleid=${scheduleid}`, { headers: this.req_headers });
  }

  async aiPreventExamStart(scheduleid: number): Promise<any> {
    return await this.cbtservice.tryGet(this.rootURL + `Exam/aipreventexamstart?scheduleid=${scheduleid}`, { headers: this.req_headers });
  }

  async getlastansweredqno(scheduleid: any, essay: boolean): Promise<any> {
    // let attempts = 0;
    // while(true){
    //   attempts++;
    //   try{
    //     return await this.http.get(this.rootURL + `Exam/lastqno?scheduleid=${scheduleid}&essay=${essay}`, {headers: this.req_headers}).toPromise();
    //   }
    //   catch(error){
    //     if(attempts < environment.maxRequestRetries && (error.status === 0 || (error.error && error.error.message && error.error.message.toUpperCase().includes(environment.seamlessScalingError)))){
    //       //return await this.getlastansweredqno(scheduleid, essay);
    //       //probably gateway timeout (when status === 0) or seamless scaling error. retry
    //     }
    //     else{
    //       //this.cbtservice.showHttpError(error);
    //       //this.isloading = false;
    //       throw error;
    //     }
    //   }
    // }
    return await this.cbtservice.tryGet(this.rootURL + `Exam/lastqno?scheduleid=${scheduleid}&essay=${essay}`, { headers: this.req_headers });
  }

  async submitexam(scheduleid: any, liteMode: any, elapsed: boolean = false, answers: any[] = undefined): Promise<any> {
    // let attempts = 0;
    // while(true){
    //   attempts++;
    //   try{
    //     return await this.http.post(this.rootURL + `Exam/submitexam?scheduleid=${scheduleid}&elapsed=${elapsed}`,{scheduleid,elapsed}, {headers: this.req_headers}).toPromise();
    //   }
    //   catch(error){
    //     if(attempts < environment.maxRequestRetries && (error.status === 0 || (error.error && error.error.message && error.error.message.toUpperCase().includes(environment.seamlessScalingError)))){
    //       //return await this.submitexam(scheduleid, elapsed);
    //       //probably gateway timeout (when status === 0) or seamless scaling error. retry
    //     }
    //     else{
    //       //this.cbtservice.showHttpError(error);
    //       //this.isloading = false;
    //       throw error;
    //     }
    //   }
    // }
    if(liteMode){
      if(!answers){
        answers = this.questionservice.getAllQuestionsAnswer();
      }
      let data = {
        "answerdetails":answers,
        "scheduleId":scheduleid,
        "elapsed":elapsed,
        "liteMode":liteMode
      }

      return await this.cbtservice.tryPost(this.rootURL + `question/saveallquestionsanwser?scheduleid=${scheduleid}&elapsed=${elapsed}`, data, { headers: this.req_headers  });
    }

    return await this.cbtservice.tryPost(this.rootURL + `Exam/submitexam?scheduleid=${scheduleid}&elapsed=${elapsed}`, { scheduleid, elapsed }, { headers: this.req_headers });
  }
  async getexamquestionlist(scheduleid: any): Promise<any> {
    // let attempts = 0;
    // while(true){
    //   attempts++;
    //   try{
    //     return await this.http.get(this.rootURL + `Exam/getquestionlist?scheduleid=${scheduleid}`, {headers: this.req_headers}).toPromise();
    //   }
    //   catch(error){
    //     if(attempts < environment.maxRequestRetries && (error.status === 0 || (error.error && error.error.message && error.error.message.toUpperCase().includes(environment.seamlessScalingError)))){
    //       //return await this.getexamquestionlist(scheduleid);
    //       //probably gateway timeout (when status === 0) or seamless scaling error. retry
    //     }
    //     else{
    //       //this.cbtservice.showHttpError(error);
    //       //this.isloading = false;
    //       throw error;
    //     }
    //   }
    // }
    return await this.cbtservice.tryGet(this.rootURL + `Exam/getquestionlist?scheduleid=${scheduleid}`, { headers: this.req_headers });
  }

  async getexamstatus(scheduleid: any, elapsed: boolean): Promise<any> {
    // let attempts = 0;
    // while(true){
    //   attempts++;
    //   try{
    //     return await this.http.get(this.rootURL + `Exam/getexamstatus?scheduleid=${scheduleid}&elapsed=${elapsed}`, {headers: this.req_headers}).toPromise();
    //   }
    //   catch(error){
    //     if(attempts < environment.maxRequestRetries && (error.status === 0 || (error.error && error.error.message && error.error.message.toUpperCase().includes(environment.seamlessScalingError)))){
    //       //return await this.getexamstatus(scheduleid, elapsed);
    //       //probably gateway timeout (when status === 0) or seamless scaling error. retry
    //     }
    //     else{
    //       //this.cbtservice.showHttpError(error);
    //       //this.isloading = false;
    //       throw error;
    //     }
    //   }
    // }
    return await this.cbtservice.tryGet(this.rootURL + `Exam/getexamstatus?scheduleid=${scheduleid}&elapsed=${elapsed}`, { headers: this.req_headers });
  }

  async setfocuslosscounter(scheduleid: any): Promise<any> {
    // let attempts = 0;
    // while(true){
    //   attempts++;
    //   try{
    //     return await this.http.get(this.rootURL + `Exam/setcounter?scheduleid=${scheduleid}`, {headers: this.req_headers}).toPromise();
    //   }
    //   catch(error){
    //     if(attempts < environment.maxRequestRetries && (error.status === 0 || (error.error && error.error.message && error.error.message.toUpperCase().includes(environment.seamlessScalingError)))){
    //       //return await this.setfocuslosscounter(scheduleid);
    //       //probably gateway timeout (when status === 0) or seamless scaling error. retry
    //     }
    //     else{
    //       //this.cbtservice.showHttpError(error);
    //       //this.isloading = false;
    //       throw error;
    //     }
    //   }
    // }
    return await this.cbtservice.tryGet(this.rootURL + `Exam/setcounter?scheduleid=${scheduleid}`, { headers: this.req_headers });
  }

  async performfocuslossaction(scheduleid: any): Promise<any> {
    // let attempts = 0;
    // while(true){
    //   attempts++;
    //   try{
    //     return await this.http.get(this.rootURL + `Exam/performfocuslossaction?scheduleid=${scheduleid}`, {headers: this.req_headers}).toPromise();
    //   }
    //   catch(error){
    //     if(attempts < environment.maxRequestRetries && (error.status === 0 || (error.error && error.error.message && error.error.message.toUpperCase().includes(environment.seamlessScalingError)))){
    //       //return await this.performfocuslossaction(scheduleid);
    //       //probably gateway timeout (when status === 0) or seamless scaling error. retry
    //     }
    //     else{
    //       //this.cbtservice.showHttpError(error);
    //       //this.isloading = false;
    //       throw error;
    //     }
    //   }
    // }
    return await this.cbtservice.tryGet(this.rootURL + `Exam/performfocuslossaction?scheduleid=${scheduleid}`, { headers: this.req_headers });
  }


  async getInstructions(id: number): Promise<any> {
    return await this.cbtservice.tryGet(this.rootURL + `Exam/getinstructions?examid=${id}`, { headers: this.req_headers });
  }

  async submitfeedback(data: any): Promise<any> {

    return await this.cbtservice.tryPost(this.rootURL + `Exam/submitexamfeedback?data=${data}`, data, { headers: this.req_headers });
  }

  async getfeedbackquestions(): Promise<any> {

    return await this.cbtservice.tryGet(this.rootURL + `Exam/getexamfeedbackquestions`, { headers: this.req_headers });
  }

  // async gettimeleft(scheduleid: any): Promise<any> {
  //   return await this.cbtservice.tryGet(this.rootURL + `Exam/gettimeleftscheduleid?scheduleid=${scheduleid}`, { headers: this.req_headers });
  // }

  // async getexampausestatus(scheduleid: number): Promise<any> {
  //   return await this.cbtservice.tryGet(this.rootURL + `Exam/getexampausestatus?scheduleid=${scheduleid}`, { headers: this.req_headers });
  // }

  async getexampausestatusandtimeleftschedule(scheduleid: number,liteMode:any): Promise<any> {
    if(liteMode){
      return {
        "tleft":"",
        "status": "In Progress",
        "adminpaused": false,
        "proctorpaused": false,
        "reason": ""
    }
    }
    return await this.cbtservice.tryGet(this.rootURL + `Exam/getexampausestatusandtimeleftschedule?scheduleid=${scheduleid}`, { headers: this.req_headers });
  }

  async validateExamID(examid: string) {
    return await this.cbtservice.tryGet(this.rootURL + `Exam/validateexam/${examid}`, { headers: this.req_headers });
  }

  async registerExamCandidate(data: any) {
    // registernewexamcandidate

    this.req_headers = new HttpHeaders({
      'Content-Disposition': 'multipart/form-data',
      "skip_error_handler_interceptor": "true"
    });//all the services here should skip the interceptor

    return await this.cbtservice.tryPost(this.rootURL + `Exam/registernewexamcandidate`, data, { headers: this.req_headers });
  }

  async sendVerifyEmail(email: string, examid:number) {
    return await this.cbtservice.tryGet(this.rootURL + `Users/sendverificationemail?email=${email}&examId=${examid}`, { headers: this.req_headers });
  }
  async verifyEmail(token: string) {
    return await this.cbtservice.tryGet(this.rootURL + `Users/verifyemail?token=${token}`, { headers: this.req_headers });
  }
  async getuserscheduleDates(examid: number): Promise<any> {
    return await this.cbtservice.tryGet(this.rootURL + `Exam/getuserscheduledates?examid=${examid}`, { headers: this.req_headers });
  }

  async schedulecandidate(data: any): Promise<any> {
    return await this.cbtservice.tryPost(this.rootURL + `Exam/schedulecandidate`, data, { headers: this.req_headers });
  }

  async UploadPassport(data: any) {
    var reqheaders = new HttpHeaders({
      'Content-Disposition': 'multipart/form-data',
      "skip_error_handler_interceptor": "true"
    });//all the services here should skip the interceptor

    return await this.cbtservice.tryPost(this.rootURL + `Exam/uploadpassport`, data, { headers: reqheaders });
  }

  async CheckPassportCanUpload(examno: string) {
    return await this.cbtservice.tryGet(this.rootURL + `exam/canuploadpassport?ExamNo=${encodeURI(examno)}`, { headers: this.req_headers });
  }

  async getuserscheduleTimes(examid: number, selecteddate: any): Promise<any> {
    return await this.cbtservice.tryGet(this.rootURL + `Exam/getuserscheduletimes?examid=${examid}&selecteddate=${selecteddate}`, { headers: this.req_headers });
  }

  async getcandidatepassporturl(company: string, username, imageversion, imagetype) {
    let url = `${environment.s3passportbucketurl}${company.toLowerCase()}/passports/${username}_${imageversion}.${imagetype}`;
    //check that passport exists
    // await this.http.get(url, { headers: this.req_headers }).toPromise().catch((err) => {
    //debugger;
    //   //if (err.status === 400) { return "/assets/images/defaultuserimage.jpeg"; }
    //   //removed this because the xhr request to get the passport photo fails as a result of cors anyway. so we won't really be able to tell if the photo exists or not
    //   //this means (obviously) that whenever we delete photos, we have to remember to set the image version of the candidate
    // });
    return url;
  }
  
  async getSEBInstructionsURL(scheduleid){
    //sebs:cbtapi.webtest.ng/Exam/downloadsebconfig?&company=workforce
    let url = `${this.rootURL}Exam/getschedulesebinstructionsurl?scheduleid=${scheduleid}`;
    let data = await this.cbtservice.tryPost(url, {}, { headers: this.req_headers });
    return data.url_data;
  }

  async confirmPassportHasFace(imgData:File){
    const imgDataURL = await this.imageToBase64String(imgData);
    const ret = await this.cbtservice.tryPost(`${environment.aiServerUrl}checkface`, { imgDataURL }, { headers: this.req_headers });
    return ret;
  }

  private imageToBase64String(data:File): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(data);
      reader.onloadend = () => {
        if (reader.readyState === FileReader.DONE) {
          if (reader.error) {
            console.error("Error reading file:", reader.error);
            reject(reader.error);
          } else {
            resolve(reader.result as string);
          }
        }
      };
    });
  }
}
