import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CbtserviceService } from './cbtservice.service';
//import { throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class QuestionserviceService {

  previousquestiondata: any
  nextquestiondata: any
  readonly rootURL = environment.CBTAPIURL;
  req_headers: HttpHeaders;

  constructor(private http: HttpClient, private cbtservice: CbtserviceService) {
    this.req_headers = new HttpHeaders({ "content-type": "application/json", "accept": "application/json", "skip_error_handler_interceptor": "true" });//all the services here should skip the interceptor
  }

  async generatequestions(scheduleid: any): Promise<any> {
    // let attempts = 0;
    // while(true){
    //   attempts++;
    //   try{
    //     return await this.http.post(this.rootURL + `question/generatequestions?scheduleid=${scheduleid}`, {}, {headers: this.req_headers}).toPromise();
    //   }
    //   catch(error){
    //     if(attempts < environment.maxRequestRetries && (error.status === 0 || (error.error && error.error.message && error.error.message.toUpperCase().includes(environment.seamlessScalingError)))){
    //       //return await this.generatequestions(scheduleid);
    //       //probably gateway timeout (when status === 0) or seamless scaling error. retry
    //     }
    //     else{
    //       //this.cbtservice.showHttpError(error);
    //       //this.isloading = false;
    //       throw error;
    //     }
    //   }
    // }
    return await this.cbtservice.tryPost(this.rootURL + `question/generatequestions?scheduleid=${scheduleid}`, {}, { headers: this.req_headers });
  }

  async startexam(scheduleid: any): Promise<any> {
    // let attempts = 0;
    // while(true){
    //   attempts++;
    //   try{
    //     return await this.http.post(this.rootURL + `question/startexam?scheduleid=${scheduleid}`, {}, {headers: this.req_headers}).toPromise();
    //   }
    //   catch(error){
    //     if(attempts < environment.maxRequestRetries && (error.status === 0 || (error.error && error.error.message && error.error.message.toUpperCase().includes(environment.seamlessScalingError)))){
    //       //return await this.startexam(scheduleid);
    //       //probably gateway timeout (when status === 0) or seamless scaling error. retry
    //     }
    //     else{
    //       //this.cbtservice.showHttpError(error);
    //       //this.isloading = false;
    //       throw error;
    //     }
    //   }
    // }
    return await this.cbtservice.tryPost(this.rootURL + `question/startexam?scheduleid=${scheduleid}`, {}, { headers: this.req_headers });
  }

  async getquestionandoptions(scheduleid: any, questionno: any, tobesaved: any, liteMode: any): Promise<any> {
    // let data = {
    //   "scheduleid": scheduleid,
    //   "questionno": questionno,
    //   "tobesaved": tobesaved
    // }
    // if(liteMode){
    return this.getQuestionNoFromStorage(questionno);
    // }
    // return await this.cbtservice.tryPost(this.rootURL + `question/getquestionandoptions`, data, { headers: this.req_headers });
  }

  async markanswer(data: any, liteMode: any): Promise<any> {
    // let attempts = 0;
    // while(true){
    //   attempts++;
    //   try{
    //     return await this.http.post(this.rootURL + 'question/saveanswer', data, { headers: this.req_headers }).toPromise();
    //   }
    //   catch(error){
    //     if(attempts < environment.maxRequestRetries && (error.status === 0 || (error.error && error.error.message && error.error.message.toUpperCase().includes(environment.seamlessScalingError)))){
    //       //return await this.markanswer(data);
    //       //probably gateway timeout (when status === 0) or seamless scaling error. retry
    //     }
    //     else{
    //       //this.cbtservice.showHttpError(error);
    //       //this.isloading = false;
    //       throw error;
    //     }
    //   }
    // }
    var ret = liteMode ? { "logout": false, "saved": true, "msg": "Success" }
      : await this.cbtservice.tryPost(this.rootURL + 'question/saveanswer', data, { headers: this.req_headers }).catch(err => {
        this.cbtservice.showHttpError(err);
        return;
      });

    if (this.hasQuestionDataInStorage() && ret) {
      this.saveQuestionOption(data);
    }
    return ret;
  }
  // getquestioncount(scheduleid: any):Observable<any> {
  //debugger;
  //   return this.http.get(this.rootURL + `question/getquestioncount?scheduleid=${scheduleid}`);
  // }

  async flagquestion(scheduleid: any, questionno: number, flagged: boolean, candidateno: any) {
    return await this.cbtservice.tryPost(this.rootURL + `question/flagquestionforreview`, { scheduleid, candidateno, questionno, flagged }, { headers: this.req_headers });
  }


  async getallquestionandoptions(scheduleid: any, liteMode: any): Promise<any> {

    return await this.cbtservice.tryPost(this.rootURL + `question/getallquestionsandoptions?scheduleId=${scheduleid}&liteMode=${liteMode}`, {}, { headers: this.req_headers });
  }

  saveQuestionsToStorage(data: any): void {
    localStorage.setItem(environment.examQuestionsStore, JSON.stringify(data));
  }

  getQuestionNoFromStorage(questionNo: any) {
    const storedData = localStorage.getItem(environment.examQuestionsStore);
    let questionlist: any = storedData ? JSON.parse(storedData) : null;
    if (questionlist) {
      const selectedQuestion = questionlist.questions.find(item => item.examQuestionSchedule.questionno == questionNo);
      selectedQuestion.timeleft = this.getTimeleftForLiteMode(questionlist.timeleft);
      selectedQuestion.focuslosscount = questionlist.focuslosscount;
      selectedQuestion.examination = questionlist.examination;
      selectedQuestion.exampaused = questionlist.exampaused;
      selectedQuestion.isnotlatestlogin = questionlist.isnotlatestlogin;
      console.log('Retrieved specific array:', selectedQuestion);

      return selectedQuestion;
    }
    return null;
  }

  clearQuestionStorage(): void {
    localStorage.clear();
  }

  hasQuestionDataInStorage(): boolean {
    let storedData = localStorage.getItem(environment.examQuestionsStore);
    storedData = storedData ? storedData : null;
    return !!storedData;
  }

  saveQuestionOption(data: any) {
    localStorage.setItem(`answerquestion_${data.schedulequestionid}`, JSON.stringify(data));
    this.updateQuestionOption(data.schedulequestionid, data.options)
  }

  updateQuestionOption(schedulequestionid: any, options: string[]): void {
    // Retrieve the current data
    const storedData = localStorage.getItem(environment.examQuestionsStore);
    const currentList: any = storedData ? JSON.parse(storedData) : [];
    let updateOccurred = false;

    // Find and update the option of the specified question
    let updatedList: any = currentList;
    updatedList.questions = currentList.questions.map(item => {

      if (item.examQuestionSchedule.schedulequestionid == schedulequestionid && !updateOccurred) {
        // Find and update the specified option to selected
        const updatedOptions = item.examQuestionOptions.map(option => {
          if (options.includes(option.optionid.toString())) {
            option.selected = true;
            updateOccurred = true;
          } else {
            option.selected = false;
          }
          return option;
        });

        // Update only the options of the specified question
        return { ...item, examQuestionOptions: updatedOptions };
      }
      return item;
    });
    // Save the updated list back to local storage
    localStorage.setItem(environment.examQuestionsStore, JSON.stringify(updatedList));
  }

  saveTimeForLiteMode() {
    localStorage.setItem(`timeAdded`, new Date().toUTCString());
  }

  getTimeleftForLiteMode(timeleft) {
    const timeAdded = new Date(localStorage.getItem("timeAdded")).getTime() / 1000;
    const currentTime = new Date(new Date().toUTCString()).getTime() / 1000;
    timeleft = timeleft - (currentTime - timeAdded);
    return timeleft;
  }

  getAllQuestionsAnswer(): any[] {
    const storedData: any[] = [];

    // Iterate over all keys in local storage
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);

      // Check if the key matches the pattern "answerquestion_"
      if (key && key.startsWith('answerquestion_')) {
        // Retrieve the data for the matching key
        const data = localStorage.getItem(key);

        if (data) {
          // Parse the data and add it to the result array
          storedData.push(JSON.parse(data));
        }
      }
    }

    return storedData;
  }

}
